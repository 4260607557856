import React, { Component, Fragment } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
// ------------Auth Module--------
import LoginPageComponent from 'Screens/AuthModule/LoginComponent/LoginPage';
import ForgotPasswordPageComponent from 'Screens/AuthModule/ForgotComponent/ForgotPage';
import RegisterPageComponent from 'Screens/AuthModule/RegisterComponent/RegisterPage';
import VerificationPage from 'Screens/AuthModule/VerificationComponent/VerificationPage';
// ------------Auth Module--------

// ------------Adademic Module--------
import AcademicDashboardPage from 'Screens/AcademicModule/AcademicDashboardComponent/AcademicDashboard';
// ------------Adademic Module--------

// ------------Roster Module--------
import RosterDashboard from 'Screens/RosterModule/RosterDashboardComponent/RosterDashboard';
import StudentProfile from 'Screens/RosterModule/StudentProfileComponent/StudentProfile';
import RosterEnrolled from 'Screens/RosterModule/RosterEnrolledComponent/RosterEnrolled';
import RosterShare from 'Screens/RosterModule/RosterShareComponent/RosterShare';
import StudentShareIndex from 'Screens/RosterModule/StudentShareComponent/StudentShareIndex';
// ------------Roster Module--------

// ------------Student Note Module--------
import StudentNoteDashboard from 'Screens/NoteModule/StudentNoteModule/StudentNoteDashboardComponent/StudentNoteDashboard';
import StudentNoteAdd from 'Screens/NoteModule/StudentNoteModule/StudentNoteAddComponent/StudentNoteAdd';
// ------------Student Note Module--------

// ------------Class Note Module--------
import ClassNoteIndex from 'Screens/NoteModule/ClassNoteModule/ClassNoteIndex';
import ClassNoteAddGroupIndex from 'Screens/NoteModule/ClassNoteModule/ClassNoteAddGroupComponent/ClassNoteAddGroupIndex';
import ClassNoteAddIndex from 'Screens/NoteModule/ClassNoteModule/ClassNoteAddComponent/ClassNoteAddIndex';
// ------------Class Note Module--------

// ------------Note SelectClass Component--------
import NoteSelectClass from 'Screens/NoteModule/NoteSelectClassComponent/NoteSelectClass';
// ------------Note SelectClass Component--------

// ------------Student GradeBook Module--------
import StudentGradeBookIndex from 'Screens/GradeBookModule/StudentGradeBookModule/StudentGradebookIndex';
// ------------Student GradeBook Module--------

// ------------Class GradeBook Module--------
import ClassGradeBookIndex from 'Screens/GradeBookModule/ClassGradeBookModule/ClassGradeBookIndex';
// ------------Class GradeBook Module--------

// ------------Formative Assessment Module--------

import FormativeAssessmentIndex from 'Screens/FormativeAssessmentModule/FormativeAssessmentIndex';
import AddAssessmentIndex from 'Screens/FormativeAssessmentModule/AddAssessmentModule/AddAssessmentIndex';
// ------------Formative Assessment Module--------

// ------------Profile Module--------
import UserProfilePage from 'Screens/ProfileModule/UserProfileComponent/UserProfile';
// ------------Profile Module--------

// ------------Class Module--------
import ClassDashboard from 'Screens/ClassModule/ClassDashboardComponent/ClassDashboard';
import ViewSingleClass from 'Screens/ClassModule/ClassDashboardComponent/ViewSingleClass';
import ClassRoster from 'Screens/ClassModule/ClassRosterComponent/ClassRoster';
import ClassTag from 'Screens/ClassModule/ClassTagComponent/ClassTag';
import ClassRosterSelect from 'Screens/ClassModule/ClassRosterSelectComponent/ClassRosterSelect';
import ClassCoreStandardsIndex from 'Screens/ClassModule/ClassCoreStandardsComponent/ClassCoreStandardsIndex';
import ClassShareIndex from 'Screens/ClassModule/ClassShareComponent/ClassShareIndex';
import ClassAttendanceIndex from 'Screens/ClassModule/ClassAttendanceComponent/ClassAttendanceIndex';
import ClassRubricsIndex from 'Screens/ClassModule/ClassRubricsComponent/ClassRubricsIndex';
import AddNewRubrics from 'Screens/ClassModule/ClassRubricsComponent/AddNewRubrics';
import ViewRubric from 'Screens/ClassModule/ClassRubricsComponent/ViewOnlyRubric';
import ClassLessonPlanIndex from 'Screens/ClassModule/ClassLessonPlanComponent/ClassLessonPlanIndex';
import AddNewLessonPlan from 'Screens/ClassModule/ClassLessonPlanComponent/AddNewLessonPlan';
import AttendanceDashboard from "Screens/AttendanceModule/AttendanceDashboardComponent/AttendanceDashboard"
// ------------Class Module--------

// ------------Data Usage Module--------
import DataUsageIndex from 'Screens/DataUsageModule/DataUsageIndex';
// ------------Data Usage Module--------

// ------------Upgrade Plan Module--------
import UpgradePlanIndex from 'Screens/UpgradePlanModule/UpgradePlanIndex';
// ------------Upgrade Plan Module--------

// ------------Subscriptions Plan Module--------
import SubscriptionsIndex from 'Screens/SubscriptionsModule/SubscriptionsIndex';
// ------------Subscriptions Plan Module--------

// ------------Settings Module--------
import SettingsIndex from 'Screens/SettingsModule/SettingsIndex';
// ------------Settings Module--------


// ------------About Module--------
import AboutIndex from 'Screens/AboutModule/AboutIndex';
// ------------About Module--------

// ------------Terms Module--------
import TermsIndex from 'Screens/TermsModule/TermsIndex';
// ------------Terms Module--------

// ------------Terms Module--------
import FAQIndex from 'Screens/FAQModule/FAQIndex';
// ------------Terms Module--------


// ------------Contact Us Module--------
import ContactUsIndex from 'Screens/ContactUsModule/ContactUsIndex';
// ------------Contact Us Module--------

// ------------Blog Module--------
import BlogIndex from 'Screens/BlogModule/BlogIndex';
import BlogDetails from 'Screens/BlogModule/BlogDetailsComponent/BlogDetails';
// ------------Blog Module--------


// ------------Paypal Module--------
import PayPalIndex from 'Screens/PayPalModule/PayPalIndex';
// ------------Paypal Module--------

import * as routes from "./RoutesURL";
import AppContainer from 'AppContainer/AppContainer';
import '.././index.css'
import ClassNoteView from 'Screens/NoteModule/ClassNoteModule/ClassNoteViewComponent/ClassNoteView';
import AllStandardDashboard from 'Screens/AllstandardModule/AllStandardDashboard';
import RegisterSubscription from 'Screens/AuthModule/RegisterSubscription/RegisterSubscription';
import RegisterPaypal from 'Screens/AuthModule/RegisterSubscription/RegisterPaypal';
import AppLogout from './AppLogout';


class Routing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logIn: false
        }

    }
    UNSAFE_componentWillMount() {
        let loginUser = sessionStorage.getItem('IsLogin')

        if (loginUser) {
            this.setState({
                logIn: loginUser
            })
        }
    }

    render() {
        let loginUser = JSON.parse(sessionStorage?.getItem('IsLogin'));
        let keepMeSinged = JSON.parse(sessionStorage?.getItem('keepMeSinged'));
        let FirstTimeLogin = JSON.parse(sessionStorage?.getItem('FirstTimeLogin'));
        let userHasFreePlan = JSON.parse(sessionStorage?.getItem('userHasFreePlan')) || '1'
        console.log(149, userHasFreePlan)

        if(userHasFreePlan == '0') {
        return (
            <>
            <BrowserRouter>
            <Switch>
            <Route path={routes.HOME} render={(pathParams) => {
                        console.log("loginUser:", loginUser);
                        console.log("FirstTimeLogin:", FirstTimeLogin);
                        console.log("userHasFreePlan:", userHasFreePlan);
                        
                        return (
                            <AppLogout>
                                <AppContainer {...pathParams}>
                                    <Switch>
                                    <Route exact path={routes.UPGRADEPLAN} component={UpgradePlanIndex} />
                                      {/* ------------Settings Module-------- */}
                                      <Route exact path={routes.SETTINGS} component={SettingsIndex} {...pathParams} />
                                    {/* ------------Settings Module-------- */}
                                    <Route exact path={routes.USERPROFILE} component={UserProfilePage} {...pathParams} />
                                      {/* ------------PayPalIndex Module-------- */}
                                      <Route exact path={routes.PAYPAL} component={PayPalIndex} {...pathParams} />
                                    {/* ------------PayPalIndex Module-------- */}
                                    <Redirect path="*" to={routes.UPGRADEPLAN} />
                                    </Switch>
                                </AppContainer>
                            </AppLogout>
                        );
                    }} />

                    {FirstTimeLogin === 0 && <Redirect to={routes.UPGRADEPLAN} />}

                                            {/* ------------Auth Module-------- */}
                                            {!loginUser && <Route path={routes.LOGIN} component={(pathParams) => {
                            return <AppContainer {...pathParams}>
                                <Switch>
                                    <Route exact path={routes.LOGIN} component={LoginPageComponent} />
                                    <Route exact path={routes.REGISTER} component={RegisterPageComponent} />
                                    <Route exact path={routes.FORGOT} component={ForgotPasswordPageComponent} />
                                    <Route exact path={routes.VERIFICATION} component={VerificationPage} />
                                    <Route exact path={routes.USERSUBSCRIPTION} component={RegisterSubscription}/>
                                    <Route exact path={routes.USERPAYPAL} component={RegisterPaypal}/>
                                    <Redirect path="*" to={routes.LOGIN} />
                                </Switch>
                            </AppContainer>
                        }} />}


                        {!loginUser && <Redirect path="*" to={routes.LOGIN} />}
                        {/* ------------Auth Module-------- */}

                            </Switch>
                            </BrowserRouter>
            </>
        )
    } else {
        return (
            <BrowserRouter>
                <Fragment>
                    <Switch>

                    {/* {loginUser && FirstTimeLogin === 0 && userHasFreePlan == '0' && <Redirect to={routes.UPGRADEPLAN} />} */}

                    {/* {loginUser && FirstTimeLogin === 0 && userHasFreePlan == '0' &&  
                    <Route path={routes.HOME} render={(pathParams) => {
                        console.log("loginUser:", loginUser);
                        console.log("FirstTimeLogin:", FirstTimeLogin);
                        console.log("userHasFreePlan:", userHasFreePlan);
                        
                        return (
                            <AppLogout>
                                <AppContainer {...pathParams}>
                                    <Switch>
                                    <Route exact path={routes.UPGRADEPLAN} component={UpgradePlanIndex} />
                                    </Switch>
                                </AppContainer>
                            </AppLogout>
                        );
                    }} />
                    

                                    } */}

{loginUser && FirstTimeLogin === 0 && 
                        <Route path={routes.HOME} component={(pathParams) => {
                            return <AppLogout> <AppContainer {...pathParams}>
                                <Switch>
                                    {/* ------------Adademic Module-------- */}
                                    <Route exact path={routes.ACADEMICDASHBOARD} component={AcademicDashboardPage} {...pathParams} />
                                    <Route exact path={routes.USERPROFILE} component={UserProfilePage} {...pathParams} />
                                    {/* ------------Adademic Module-------- */}

                                    {/* ------------Roster Module-------- */}
                                    <Route exact path={routes.ROSTER} component={RosterDashboard} {...pathParams} />
                                    <Route exact path={routes.STUDENTPROFILE} component={StudentProfile} {...pathParams} />
                                    <Route exact path={routes.ROSTERENRILLED} component={RosterEnrolled} {...pathParams} />
                                    <Route exact path={routes.ROSTERSHARE} component={RosterShare} {...pathParams} />
                                    {/* ------------Roster Module-------- */}

                                    {/* ------------Student Note Module-------- */}
                                    <Route exact path={routes.STUDENTNOTE} component={StudentNoteDashboard} {...pathParams} />
                                    <Route exact path={routes.STUDENTADDNOTE} component={StudentNoteAdd} {...pathParams} />
                                    {/* ------------Student Note Module-------- */}

                                    {/* ------------Class Note Module-------- */}
                                    <Route exact path={routes.CLASSNOTE} component={ClassNoteIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSADDNOTE} component={ClassNoteAddIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSADDGROUPNOTE} component={ClassNoteAddGroupIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSGROUPNOTEVIEW} component={ClassNoteView} {...pathParams} />
                                    {/* ------------Class Note Module-------- */}

                                    {/* ------------Note SelectClass Component-------- */}
                                    <Route exact path={routes.NOTESELECTCLASS} component={NoteSelectClass} {...pathParams} />

                                    {/* ------------Note SelectClass Component-------- */}

                                    {/* ------------Student GradeBook Module-------- */}
                                    <Route exact path={routes.STUDENTGRADEBOOK} component={StudentGradeBookIndex} {...pathParams} />
                                    {/* ------------Student GradeBook Module-------- */}

                                    {/* ------------Class GradeBook Module-------- */}
                                    <Route exact path={routes.CLASSGRADEBOOK} component={ClassGradeBookIndex} {...pathParams} />
                                    {/* ------------Class GradeBook Module-------- */}

                                    {/* ------------Formative Assessment Module-------- */}
                                    <Route exact path={routes.FORMATIVEASSESSMENT} component={FormativeAssessmentIndex} {...pathParams} />
                                    <Route exact path={routes.ADDASSESSMENT} component={AddAssessmentIndex} {...pathParams} />
                                    {/* ------------Formative Assessment Module-------- */}

                                    {/* ------------Class Module-------- */}
                                    <Route exact path={routes.CLASS} component={ClassDashboard} {...pathParams} />
                                    <Route exact path={routes.SINGLECLASS} component={ViewSingleClass} {...pathParams} />
                                    <Route exact path={routes.CLASSROSTER} component={ClassRoster} {...pathParams} />
                                    <Route exact path={routes.CLASSTAG} component={ClassTag} {...pathParams} />
                                    <Route exact path={routes.CLASSCORESTANDARDS} component={ClassCoreStandardsIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSSHARE} component={ClassShareIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSATTENDANCE} component={ClassAttendanceIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSRUBRICS} component={ClassRubricsIndex} {...pathParams} />
                                    <Route exact path={routes.ADDNEWRUBRICS} component={AddNewRubrics} {...pathParams} />
                                    <Route exact path={routes.VIEWRUBRIC} render={(props) => <ViewRubric {...pathParams} />} />
                                    <Route exact path={routes.CLASSLESSONPLAN} component={ClassLessonPlanIndex} {...pathParams} />
                                    <Route exact path={routes.ADDNEWLESSONPLAN} component={AddNewLessonPlan} {...pathParams} />
                                    <Route exact path={routes.STUDENTSHARE} component={StudentShareIndex} {...pathParams} />
                                    <Route exact path={routes.CLASSROSTERSELECT} component={ClassRosterSelect} {...pathParams} />
                                    <Route exact path={routes.ATTENDANCE} component={AttendanceDashboard} {...pathParams} />
                                    <Route exact path={routes.AllSTANDARDS} component={AllStandardDashboard} {...pathParams} />
                                    {/* ------------Class Module-------- */}


                                    {/* ------------Data Usage Module-------- */}
                                    <Route exact path={routes.DATAUSAGE} component={DataUsageIndex} {...pathParams} />
                                    {/* ------------Data Usage Module-------- */}

                                    {/* ------------Upgrade Plan Module-------- */}
                                    <Route exact path={routes.UPGRADEPLAN} component={UpgradePlanIndex} {...pathParams} />
                                    {/* ------------Upgrade Plan Module-------- */}

                                    {/* ------------Subscriptions Plan Module-------- */}
                                    <Route exact path={routes.SUBSCRIPTIONS} component={SubscriptionsIndex} {...pathParams} />
                                    {/* ------------Subscriptions Plan Module-------- */}

                                    {/* ------------Settings Module-------- */}
                                    <Route exact path={routes.SETTINGS} component={SettingsIndex} {...pathParams} />
                                    {/* ------------Settings Module-------- */}

                                    {/* ------------About Module-------- */}
                                    <Route exact path={routes.ABOUT} component={AboutIndex} {...pathParams} />
                                    {/* ------------About Module-------- */}

                                    {/* ------------Terms Module-------- */}
                                    <Route exact path={routes.TERMS} component={TermsIndex} {...pathParams} />
                                    {/* ------------Terms Module-------- */}

                                    {/* ------------FAQ Module-------- */}
                                    <Route exact path={routes.FAQ} component={FAQIndex} {...pathParams} />
                                    {/* ------------FAQ Module-------- */}

                                    {/* ------------ContactUs Module-------- */}
                                    <Route exact path={routes.CONTACTUS} component={ContactUsIndex} {...pathParams} />
                                    {/* ------------ContactUs Module-------- */}


                                    {/* ------------Blog Module-------- */}
                                    <Route exact path={routes.BLOG} component={BlogIndex} {...pathParams} />
                                    {/* ------------Blog Module-------- */}

                                    {/* ------------Blog Module-------- */}
                                    <Route exact path={routes.BLOGDETAILS} component={BlogDetails} {...pathParams} />
                                    {/* ------------Blog Module-------- */}

                                    {/* ------------PayPalIndex Module-------- */}
                                    <Route exact path={routes.PAYPAL} component={PayPalIndex} {...pathParams} />
                                    {/* ------------PayPalIndex Module-------- */}



                                    <Redirect path="*" to={routes.ACADEMICDASHBOARD} />
                                </Switch>
                            </AppContainer>
                            </AppLogout>
                        }} />}

                    


                        {/* {FirstTimeLogin === 0 && <Redirect to={routes.ACADEMICDASHBOARD} />} */}
                        {/* {loginUser && FirstTimeLogin === 0 && userHasFreePlan != '0' && <Redirect to={routes.ACADEMICDASHBOARD} />} */}
                         {/* Redirect logged-in users to academic dashboard */}
                         {/* {loginUser && FirstTimeLogin === 0 && userHasFreePlan == '0' && <Redirect to={routes.UPGRADEPLAN} />} */}


                        {/* ------------Auth Module-------- */}
                        {!loginUser && <Route path={routes.LOGIN} component={(pathParams) => {
                            return <AppContainer {...pathParams}>
                                <Switch>
                                    <Route exact path={routes.LOGIN} component={LoginPageComponent} />
                                    <Route exact path={routes.REGISTER} component={RegisterPageComponent} />
                                    <Route exact path={routes.FORGOT} component={ForgotPasswordPageComponent} />
                                    <Route exact path={routes.VERIFICATION} component={VerificationPage} />
                                    <Route exact path={routes.USERSUBSCRIPTION} component={RegisterSubscription}/>
                                    <Route exact path={routes.USERPAYPAL} component={RegisterPaypal}/>
                                    <Redirect path="*" to={routes.LOGIN} />
                                </Switch>
                            </AppContainer>
                        }} />}


                        {!loginUser && <Redirect path="*" to={routes.LOGIN} />}
                        {/* ------------Auth Module-------- */}

                    </Switch>
                </Fragment>
            </BrowserRouter>

        )
    }

    }
}
export default React.memo(Routing);