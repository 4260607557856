import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import * as routes from "Router/RoutesURL";
import Premium from "Assets/DataUsageModule/Premium.png";
import Personal from "Assets/DataUsageModule/Personal.png";
import Professional from "Assets/DataUsageModule/Professional.png";
import "../DataUsageDashboardComponent/DataUsageDashboard.scss";

const DataUsagePlan = (props) => {
  const { history, user_space_response } = props;
  console.log(15, props);
  let planContent = user_space_response?.cc_subscription_content?.split("\\n");
  return (
    <Row data-testid="dtu" className="w-100 ml-0 h-100">
      <Col md={3} lg={3} className="leftUpgradeBg h-100">
        {/* <p className="planHeading">Current Plan:</p> */}
        <div className="d-flex flex-wrap flex-column align-items-center justify-content-center h-100">
          <p className="planHeading">Current Plan</p>
          <img
            src={user_space_response?.plan_logo}
            alt=""
            width={90}
            className="img-fluid"
          />

          <div className="mTop30">
            <p
              className={`planType ${
                user_space_response?.subscription_title == "PREMIUM"
                  ? "premiumPara"
                  : user_space_response?.subscription_title == "PERSONAL"
                  ? "personalPlanPara"
                  : "professionalPara"
              } `}
            >
              {user_space_response?.subscription_title}
            </p>
            <p
              className="planPrice font-weight-bold text-center"
              style={{
                color:
                  user_space_response?.subscription_title == "PREMIUM"
                    ? "#E73860"
                    : user_space_response?.subscription_title == "PERSONAL"
                    ? "#F18F1A"
                    : "#1AA6B4",
              }}
            >
              {user_space_response?.subscription_price}
            </p>
          </div>
        </div>
      </Col>
      <Col
        md={9}
        lg={9}
        className="benefitsDiv"
        style={{ position: "relative", zIndex: 3 }}
      >
        <Row className="h-100">
          <Col md={8} lg={8} className="d-flex align-items-center flex-wrap">
            {/* <p className="planHeading">Benefits:</p> */}
            <div className="planDetailsPoint">
              <p className="mb-3 text-uppercase benefitsText">Benefits</p>
              <p className="dataPlans mb-1">
                {user_space_response?.subscription_size}
              </p>
              {planContent?.map((planContentItem, idx) => {
                return (
                  <p className="dataPlans mb-1" key={idx}>
                    {planContentItem}
                  </p>
                );
              })}
            </div>
          </Col>
          <Col
            md={4}
            lg={4}
            data-testid="updatebtnmock"
            className="upgardePlanCol d-flex align-items-end justify-content-center"
            style={{ height: "85%" }}
            onClick={() => {
              if (
                JSON.parse(sessionStorage.getItem("UserData"))?.user_type ==
                "school_teacher"
              ) {
                return;
              } else {
                history?.push(routes.UPGRADEPLAN, {
                  BackPage: "DataUsage",
                  Item: user_space_response,
                });
              }
            }}
          >
            <Button
              className={
                JSON.parse(sessionStorage.getItem("UserData"))?.user_type ==
                "school_teacher"
                  ? "upgradePlanButton d-none"
                  : "upgradePlanButton"
              }
              disabled={
                JSON.parse(sessionStorage.getItem("UserData"))?.user_type ==
                "school_teacher"
              }
            >
              Upgrade Plan
            </Button>
          </Col>
        </Row>
      </Col>
      <img
        src={user_space_response?.plan_logo_bg}
        className="img-fluid"
        alt="bg"
        style={{
          position: "absolute",
          right: "-2px",
          bottom: "-3px",
          width: "75%",
        }}
      />
    </Row>
  );
};
DataUsagePlan.propTypes = {
  user_space_response: PropTypes.object,
};

export default DataUsagePlan;
