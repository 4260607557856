import { call, takeLatest, put } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import userPayPalPaymentReducer from 'Redux/Reducers/PayPalPaymentReducer/payPalPaymentReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { SET_PAYMENT_DATA_REQUEST, ADD_USER_SUBSCRIPTION_REQUEST } from 'Actions/constants';
import { SetPaymentStatus_API, SetPaymentInfo_API } from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import * as routes from "Router/RoutesURL";

export function* userPayPalPaymentModuleSaga() {
    yield takeLatest(SET_PAYMENT_DATA_REQUEST, setPaymentDataRequest);
    yield takeLatest(ADD_USER_SUBSCRIPTION_REQUEST, addUserSubscriptionRequest);


}

// ---------- Set Payment Data -----
function* setPaymentDataRequest(action) {
    yield put(UserLoadingReducer.loading(true));
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = SetPaymentStatus_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "agreement_id": action.data.agreement_id,
        "plan_id": action.data.plan_id,
        "transaction_type": action.data.transaction_type,
        "platform" : "web"
    }
    
    try {
        let response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        yield put(UserLoadingReducer.loadingSuccess(false));
        if (MainDecode.status === 200) {
            // const data = {
            //     "receipt_data": action.data.receipt_data,
            //     "type": action.data.type,
            //     "subscription_json": action.data.subscription_json
            // }
            // yield put(userPayPalPaymentReducer.addUserSubscriptionRequest(data));
            yield put(userPayPalPaymentReducer.setPaymentDataSuccess(MainDecode));
            createNotification('success', 'Payment successful.');
          

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userPayPalPaymentReducer.setPaymentDataFailure(MainDecode));
        }
    } catch (error) {
        yield put(userPayPalPaymentReducer.setPaymentDataFailure());
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Set Payment Data ------


// ---------- Set Payment Data -----
function* addUserSubscriptionRequest(action) {
    yield put(UserLoadingReducer.loading(true));
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = SetPaymentInfo_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "receipt_data": action.data.receipt_data,
        "type": action.data.type,
        "subscription_json": action.data.subscription_json
    })
    try {
        let response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        const MainDecode = JSON.parse(atob(response));
        yield put(UserLoadingReducer.loadingSuccess(false));
        if (MainDecode.status === 'Success') {
            yield put(userPayPalPaymentReducer.addUserSubscriptionSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userPayPalPaymentReducer.addUserSubscriptionFailure(MainDecode));
        }
    } catch (error) {
        yield put(userPayPalPaymentReducer.addUserSubscriptionFailure());
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Set Payment Data ------