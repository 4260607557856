import React from 'react';
import { connect } from 'react-redux';
import PayPalDashboard from './PayPalDashboardComponent/PayPalDashboard';
import { setPaymentDataRequest, addPaymentStatusSuccess } from 'Actions/PayPalPaymentAction/payPalPaymentAction';
import { changeUserSubscriptionRequest } from 'Actions/SubscriptionPlanAction/subscriptionPlanAction';

const PayPalIndex = (props) => {
    return (
        <PayPalDashboard {...props} />
    )
}
const mapStateToProps = state => (console.log('state',state),{
    state: state,
    paymentStatus: state.PayPalPayment.paymentStatus,
    loader: state.PayPalPayment.loader
});
const mapDispatchToProps = dispatch => {
    return {
        setPaymentDataRequest: (data) => dispatch(setPaymentDataRequest(data)),
        addPaymentStatusSuccess: (data) => dispatch(addPaymentStatusSuccess(data)),
        changeUserSubscriptionRequest: (data) => dispatch(changeUserSubscriptionRequest(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    PayPalIndex
);
