import React from 'react';
import Premium from 'Assets/SubscriptionsModule/Premium.png';
import Personal from 'Assets/SubscriptionsModule/Personal.png';
import Button from 'react-bootstrap/Button';
import * as routes from "Router/RoutesURL";
import Professional from 'Assets/SubscriptionsModule/Professional.png';
import '../SubscriptionsDashboardComponent/SubscriptionsDashboard.scss'

const SubscriptionsPlans = (props) => {
    const { item, history } = props;
    const choosePlan = (item) => {
        history.push(routes.PAYPAL, { item: item })

    }
    return (
        <div className="planDiv">
            <div >
                {item?.cc_subscription_title === 'Personal' && <img src={Personal} alt='' width={145} height={145} />}
                {item?.cc_subscription_title === 'Premium' && <img src={Premium} alt='' width={145} height={145} />}
                {item?.cc_subscription_title === 'Professional' && <img src={Professional} alt='' width={145} height={145} />}

            </div>
            <div >
                <p className='planType'>{item?.cc_subscription_title}</p>
                <p className='planPrice'> {item?.cc_subscription_price === 0 ? 'Free' : `$${item?.cc_subscription_price}/M`}</p>
            </div>
            {!(item?.cc_subscription_title === 'Personal') && <div>
                <Button className='upgradePlanButton' onClick={() => { choosePlan(item) }}>Choose Plan</Button>
            </div>}
            <div className='planDetailsPoint'>
                <p className='mBot5 dataPlans'>一{item?.cc_subscription_description} </p>
                <p className='mBot5 dataPlans'>一 Multimedia capabilities (audio, images, videos)</p>
                <p className='mBot5 dataPlans'>一 Customisable note-taking</p>
                <p className='mBot5 dataPlans'>一 Collaborative teaching via student and class sharing</p>
                <p className='mBot5 dataPlans'>一 Integrated and multifaceted gradebook</p>
            </div>



        </div>

    )
}

export default SubscriptionsPlans