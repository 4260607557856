import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Button } from 'reactstrap'
import { saveAs } from 'file-saver';
import { createNotification } from 'Config/notificationtoast'
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import envelope from 'Assets/AttendanceModule/envelope.svg'
import download from 'Assets/AttendanceModule/download.svg'
import send from 'Assets/AttendanceModule/send.svg'
import Pdf from './Pdf'
import CsvView from './CsvView'
import './PreviewAttReport.scss'

const PreviewAttReport = (props) => {
    const {
        sendEmailReport,
        showPreviewType,
        fileUrl,
        showCsvUrl,
        setShowPreviewType,
        setFileUrl,
        setShowCsvUrl,
        setShowPreview,
        recordFound,
        setRecordFound,
        setUpoadFile,
        history,
        upoadFile
    } = props;

    const [fromEmail, setFromEmail] = useState("");
    const [subject, setSubject] = useState("Attendance report");
    const [message, setMessage] = useState("");
    const [showEmailComp, setShowEmailComp] = useState(false);
    const [shareFile, setShareFile] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [upoadCsvFile, setUpoadCsvFile] = useState();
    const [invelidMessagePass, setInvelidMessagePass] = useState("");
    const emailBtn = ["viewAttPreDiv", recordFound === "No record found" ? "opacityBtn" : ""]
    const downloadBtn = ["downloadPreDiv", recordFound === "No record found" ? "opacityBtn" : ""]
    const reportMailDiv = ["reportMailDiv", showEmailComp ? "showFlex" : ""];

    useEffect(() => {
        if (showCsvUrl) {
            singlePDFDownload(showCsvUrl)
        }
    }, [showCsvUrl])

    function blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    const singlePDFDownload = (URl) => {
        const options = {
            method: 'GET',
            baseURL: URl,
        };
        axios(options).then(async responses => {
            if (responses.data.message === "No record found") {
                setRecordFound(responses.data.message);
                createNotification('error', responses.data.message);
            } else {
                const options = {
                    method: 'GET',
                    baseURL: URl,
                    responseType: 'blob'
                };
                axios(options).then(async responses => {
                    const file = new Blob(
                        [responses.data],
                        { type: 'application/csv' });
                    const myFile = blobToFile(file, "attendance.csv");
                    setUpoadCsvFile(myFile);
                })
            }
        })
            .catch(err => {
                if (err && err.message) {
                    createNotification('error', err.message);
                }
            });
    }

    const reportDownload = () => {
        if (showPreviewType === "pdf") {
            const fileName = `attendance ${new Date()}.pdf`
            saveAs(fileUrl, fileName);

        } else if (showPreviewType === "csv") {
            const fileName = `attendance ${new Date()}.csv`
            saveAs(showCsvUrl, fileName);
        }
    }

    const BackTOHome = () => {
        // history.goBack();
        setShowPreviewType("");
        setFileUrl("");
        setShowCsvUrl("");
        setShowPreview(false);
        setRecordFound("");
        setUpoadCsvFile("");
        setUpoadFile("");
    }

    const sendEmail = () => {


        if (fileUrl) {
            if (fromEmail === "") {
                setInvelidMessagePass("Please enter the email Id*")
                return false;
            }
            if (subject === "") {
                setInvelidMessagePass("Please enter the Subject*")
                return false;
            }
            if (message === "") {
                setInvelidMessagePass("Please enter the Message*")
                return false;
            }
            const apiData = {
                toEmailId: fromEmail,
                subject: subject,
                messageBody: message,
                shareFile: upoadFile
            }
            sendEmailReport(apiData);
            setShowEmailComp(false);
            setFromEmail("");
            setSubject("Attendance report");
            setMessage("");
            setInvelidMessagePass("");
        } else {
            if (fromEmail === "") {
                setInvelidMessagePass("Please enter the email Id*")
                return false;
            }
            if (subject === "") {
                setInvelidMessagePass("Please enter the Subject*")
                return false;
            }
            if (message === "") {
                setInvelidMessagePass("Please enter the Message*")
                return false;
            }
            const apiData = {
                toEmailId: fromEmail,
                subject: subject,
                messageBody: message,
                shareFile: upoadCsvFile
            }
            sendEmailReport(apiData);
            setShowEmailComp(false);
            setFromEmail("");
            setSubject("Attendance report");
            setMessage("");
            setInvelidMessagePass("");
        }
    }

    const ViewEmailComposer = () => {
        setShowEmailComp(true);
    }

    const HideEmailComposer = () => {
        setShowEmailComp(false);
        setFromEmail("");
        setSubject("Attendance report");
        setMessage("");
        setInvelidMessagePass("");
    }

    return (
        <div className={"PreviewAttContainer"}>
            <div className='ClassAttendanceMainDiv'>
                <div className='classAttendaceDetailsMain'>
                    {/* <div className='attClassBackDiv' >
                       <img className='calIcon' src={back} alt='' width='60' height='40'onClick={BackTOHome}/>
                    </div> */}
                     <div className='classAttendaceDetailsMain'>
              <div className='backBttn' style={{ width: '40px', height: '40px', borderRadius: '0.5rem', alignItems: 'center', display: 'flex', marginLeft: '10px', justifyContent: 'center' }} 
              // onClick={goToHome}
              onClick={BackTOHome}
              >
                <i className='attClassBackIcon material-icons' style={{ color: '#ffffff', fontSize: '30px', marginBottom: '3px' }} >chevron_left </i>
              </div>
            </div>
                    <div className='PreviewAttClassDetailTextDiv'>
                        {<div className='displayFlexClassRoster'>
                            <div className='headingForEdited'>
                                <p className='attClassName'>Attendance Report</p>
                            </div>
                            <p className='attClassPreGrades'> - {showPreviewType?.toUpperCase()}</p>
                        </div>}
                    </div>
                </div>
                <div className='buttonMarginTop'>
                    <Button className={emailBtn.join(' ')}
                        onClick={recordFound === "No record found" ? "" : ViewEmailComposer}
                    >
                        <img className='viewAttIcons' src={envelope} alt='' />
                        <span className='viewAttText'> Email </span>
                    </Button>

                    <Button className={downloadBtn.join(' ')}
                        onClick={recordFound === "No record found" ? "" : reportDownload}
                    >
                        <img className='viewAttIcons' src={download} alt='' />
                        <span className='viewAttText'> Download </span>
                    </Button>
                </div>
            </div>
            {!fileUrl && showPreviewType === "pdf" && !recordFound === "No record found" && (
                <div>
                    <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>
            )}
            <div className={reportMailDiv.join(' ')}>
                <div className="reportPreView">
                    {showPreviewType === "pdf" && fileUrl && <Pdf fileUrl={fileUrl} />}
                    {showPreviewType === "csv" && showCsvUrl && <CsvView showCsvUrl={showCsvUrl} />}
                </div>
                {recordFound === "No record found" ? (
                    <div>
                        <p className='noneStudents'>No record found</p>
                    </div>
                ) : (
                    ''
                )}
                {showEmailComp && <div className='emailComposer'>
                    <div className="titleDiv"><p className="composeTitle">Email Composer</p></div>
                    <form method="post" action="#" className="emailForm">

                        <div className="field">
                            <input type="text" className='mailDiv' name="email" placeholder="To" id="email" value={fromEmail} onChange={e => setFromEmail(e.target.value)} />
                            {invelidMessagePass === "Please enter the email Id*" ? <p className="errormessageRegisters">{invelidMessagePass}</p> : ""}
                        </div>
                        <div className="field">
                            <textarea name="message" className="subjectText" id="message" rows="4" value={subject} onChange={e => setSubject(e.target.value)}
                                placeholder="Subject"></textarea>
                            {invelidMessagePass === "Please enter the Subject*" ? <p className="errormessageRegisters">{invelidMessagePass}</p> : ""}
                        </div>
                        <div className="field">
                            <textarea name="message" className="messageText" id="message" rows="4" value={message} onChange={e => setMessage(e.target.value)}
                                placeholder="Enter Message.."></textarea>
                            {invelidMessagePass === "Please enter the Message*" ? <p className="errormessageRegisters">{invelidMessagePass}</p> : ""}
                        </div>
                    </form>
                    <div className="actions">
                        <Button className='cancelBtn'
                            onClick={HideEmailComposer}
                        >
                            <span className='cancelText'> Cancel </span>
                        </Button>
                        <Button className='emailBtn'
                            onClick={sendEmail}
                        >
                            <img className='viewAttIcons' src={send} alt='' />
                            <span className='viewAttText'> Send </span>
                        </Button>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default PreviewAttReport;