import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteUser, updateUser } from 'Actions/ProfileAction/profileAction';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Button } from 'reactstrap';
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import * as routes from "Router/RoutesURL";
import { storage } from "../../../firebase/firebase"
import firebase, { auth } from 'firebase';
import Load from './../../../Assets/ProfileModule/PepperyMedium.gif';
import { createNotification } from 'Config/notificationtoast';
import { getRandomName } from "Utils/Helper";
import { getProfileImage } from "Config/commonFirebaseImage";
import UpgradePopUp from '../../upgradePopUp/upgradePopUp';
import { FirebseStroageFolder } from 'Config/Constant';
import ReactAvatar from './ReactAvatar';
import camera from 'Assets/RosterModule/camera.png'
import Modal from 'react-bootstrap/Modal';
import './UserProfile.scss';
import back from 'Assets/CommonComponent/back.png'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UserProfile =(props)=> {
    const data = JSON.parse(sessionStorage.getItem('UserData'));
    const [ FirstName, setFirstName] = useState(data?.cc_user_first_name);
    const [ LastName, setLastName] = useState(data?.cc_user_last_name);
    const [ Email, setEmail] = useState(data?.cc_user_email_id);
    const [ Userprofile, setUserprofile] = useState("");
    const [ selectedUserprofile, setselectedUserprofile] = useState('');
    const [ userProfileName, setUserProfileName] = useState("");
    const [ SchoolName, setSchoolName] = useState(data?.cc_user_school_name);
    const [ PhoneNo, setPhoneNo] = useState(data?.cc_user_phone_number);
    const [ OldPassword, setOldPassword] = useState('');
    const [ NewPassword, setNewPassword] = useState('');
    const [ ConfirmPassword, setConfirmPassword] = useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
    const [ showLoaderUpload, setshowLoaderUpload] = useState();
    const [ isSubmit, setIsSubmit] = useState();
    const [ isSubmitPassword, setIsSubmitPassword] = useState();
    const [ showPopUp, setshowPopUp] = useState();
    const [ InvelidMessagePass, setInvelidMessagePass] = useState('');
    const [ open, setopen] = useState();
    const [ userAuthenticated, setUserAuthenticated] = useState(true);
    const [ disabledPasswordField, setDisablePasswordField] = useState(true)
    const [ validatePasswor, setValidaPassword] = useState(false)
    const [deleteUserModal, setDeleteUserModal] = useState(false)
    const disabledDiv = ['inputInfoField', open ? 'disableDiv' : '']
    const passwordField = ['inputpasswordField', open ? 'disableDiv' : '']
    const newPasswordField = ['inputpasswordField', !userAuthenticated? 'disableUpdateDiv' : '']
    const updateBtn = ['updateBtn', !userAuthenticated? 'disableUpdateDiv' : '']
    const changeButtonText = ['changeButtonText', open ? 'disableDiv' : '']
    const NoStudentImageMainDiv = ['NoStudentImageMainDiv', open ? 'disableDiv' : '']
    const {updateUser, UserInfo , history, deleteUser} = props;
const [UpdateBtnVaL, setUpdateBtnVaL] = useState(false)
    useEffect(() => {
        if(UserInfo.action=== "UPDATE_USER_SUCCESS"){
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setIsSubmit(false);
            setIsSubmitPassword(false);
        }
    },[UserInfo])

    useEffect(() => {
        if( OldPassword.length < 1){
            setUserAuthenticated(true)
        }
    },[userAuthenticated])

    useEffect(() => {
        const getUserProfile = async () => {
            let value = ''
            if (data?.cc_user_pic && data?.cc_user_pic.includes("https") === true) {
                setUserprofile(data?.cc_user_pic);
                setUserProfileName(data?.cc_user_pic);
            } else {
                if (data?.cc_user_pic === '' || data?.cc_user_pic === null || data?.cc_user_pic === "null") {
                    setUserprofile("");
                    setUserProfileName("");
                }
                else {
                    value = await getProfileImage(data?.cc_user_pic);
                    setUserprofile(value);
                    setUserProfileName(data.cc_user_pic);
                }
            }
        }

        getUserProfile()
            .catch(console.error);
    }, [data?.cc_user_pic])

    // ---------Input Handle change-------
    const handleChange = event => {
        setIsSubmit(false);
        setIsSubmitPassword(false);
        setInvelidMessagePass('');
        if (event.target.name === "FirstName") {
            setFirstName(event.target.value);
        }else if(event.target.name === "LastName"){
            setLastName(event.target.value);
        }else if(event.target.name === "SchoolName"){
            setSchoolName(event.target.value);
        }else if(event.target.name === "Email"){
            setEmail(event.target.value);
        }else if(event.target.name === "Phone No."){
            setPhoneNo(event.target.value);
        }else if(event.target.name === "OldPassword"){
            setOldPassword(event.target.value);
        }else if(event.target.name === "NewPassword"){
            setNewPassword(event.target.value);
        }else if(event.target.name === "ConfirmPassword"){
            setConfirmPassword(event.target.value);
        }
    };
    // ---------Input Handle change-------

    const urlToFile=(url, filename, mimeType)=> {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType });
            })
        );
    }

    const handleCroppedUrl = (value) => {
        urlToFile(value, 'a.png')
            .then((file) => {
                const fileImg = new File([file], 'untitled', { type: file.type })
                setopen(false);
                setshowLoaderUpload(true);
                // ---------Image Type-------
                const ImageType = fileImg.type
                // ---------Image Type-------

                // ---------DummyName-------
                const GetRendomName = getRandomName()
                const DummyName = GetRendomName + '.' + ImageType;
                // ---------DummyName-------

                // ---------FolderName-------
                const FinfFolderNameArray = Email.split("@")
                const FolderName = FinfFolderNameArray[0]
                // ---------FolderName-------

                const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/profile/${DummyName}`).put(fileImg)
                uploadTask.on('state_changed',
                    (snapShot) => {

                    }, (err) => {
                        setshowLoaderUpload(false);
                        createNotification('success', 'The image was not uploaded. Please try again.');
                    }, () => {
                        storage.ref(`${FirebseStroageFolder}/${FolderName}/profile/`).child(DummyName).getDownloadURL()
                            .then(fireBaseUrl => {
                                setshowLoaderUpload(false);
                                createNotification('success', 'Great! The Image Upload was successful!');
                                setUserprofile(fireBaseUrl);
                                setUserProfileName(`${FirebseStroageFolder}/${FolderName}/profile/${DummyName}`);
                                setshowLoaderUpload(false)
                            })
                    })
            });
    }

    const handleModalClose = (value) => {
        setopen(false);
        setshowLoaderUpload(false);
    }

    // ---------Image Upload on Firebase-------
    const handleChangeFile = (e) => {
        const imageAsFile = e[0];
        if (imageAsFile && imageAsFile.name && imageAsFile.size) {
            setselectedUserprofile(imageAsFile);
            setshowLoaderUpload(false);
            setopen(true);
        } else {
            setopen(false);
        }
    }
    // ---------Image Upload on Firebase-------
    const handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
      };

    const handleClosePopUp = (e) => {
        setshowPopUp(false);
    }

    const update = () => {
        const alphabetRegex = /^^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        setIsSubmit(true);
        if (!FirstName || !LastName) {
            return false;
        }
        if(FirstName) {
            if(!alphabetRegex.test(FirstName)) {
                setFirstNameErrorMessage('*Should be alphabet only');
                return false;
            }
        }
        if(LastName) {
            if(!alphabetRegex.test(LastName)) {
                setLastNameErrorMessage('*Should be alphabet only');
                return false;
            }
        }
        let data = {
            "user_first_name": FirstName,
            "user_last_name": LastName,
            "user_pic": userProfileName,
            "user_old_password": '',
            "user_password": ''
        }

        if (OldPassword || NewPassword || ConfirmPassword) {
            setIsSubmitPassword(true);
            if (!NewPassword || !ConfirmPassword) {
                return false;
            }
            if (NewPassword.length < 6) {
                setInvelidMessagePass('Password can not be less then 6 character*')
                return false;
            }
            const validpass1 = /[0-9]/;
            if (!validpass1.test(NewPassword)) {
                setInvelidMessagePass('Password Must contain at least one Number*')
                return false;
            }
            const validpass2 = /[a-z]/;
            if (!validpass2.test(NewPassword)) {
                setInvelidMessagePass('Password Must contain at least one lowercase letter*')
                return false;
            }
            const validpass3 = /[A-Z]/;
            if (!validpass3.test(NewPassword)) {
                setInvelidMessagePass('Password Must contain at least one uppercase letter*')
                return false;
            }

            const validpass4 = /^[A-Za-z0-9 ]+$/;
            if (validpass4.test(NewPassword)) {
                setInvelidMessagePass('Password Must contain at least one Special letter*')
                return false;
            }
            const match = NewPassword === ConfirmPassword
            if (!match) {
                setInvelidMessagePass('Password did not matched*')
                return false;
            }
            data.user_old_password = OldPassword;
            data.user_password = NewPassword;
            updateUser(data);

        } else {
            updateUser(data);
            setFirstNameErrorMessage('')
            setLastNameErrorMessage('')
        }
    }

 
    const isAuthenticated=()=>{
        setUserAuthenticated(false);
        setValidaPassword(false)
        createNotification('error', "Old password is Incorrect, please enter correct password")
    }
    
    const confirmUserByCurrentPasswordFromFirebase = (OldPassword) => {
        setValidaPassword(true)
        const user = auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email, OldPassword
        );
        user.reauthenticateWithCredential(credential).then(
                setUserAuthenticated(true),
                setDisablePasswordField(false),
                setValidaPassword(false)
            ).catch(
                (e) => isAuthenticated()
            );
    };

    const BackToDash = () => {
       
        if(sessionStorage?.getItem("userHasFreePlan") != "0") {
            history.replace("/home/academic")
          } else {
            history?.push(routes?.UPGRADEPLAN);
          }
    }

        return (
            <>
            <div style={{paddingLeft: "116px",  paddingRight: "116px" }} className='classNotekMainContainer-CND'>
       <Card className="header-CND">
                <Card.Body>
                    <Row className="header-title-row-CND header-title-row-user-profile">
                        <Col xs="12" md="5" className="header-title-CND">
                            <div className="border-right-CND">
                                <img className='back-image-CND' src={back} alt='' width='60' height='40' onClick={() => { BackToDash() }} />
                            </div>
                            <span className="year-CNDD" >Profile
                                {/* <i className="classForIcon-CND material-icons">chevron_right</i>
                                <b id='Note-CND'>Note</b> */}
                            </span>
                        </Col>

                        <Col xs="12" md="7" className="m-auto dropdown-column-CND d-flex justify-content-end">
                            <Row>
                            {/* <div className='header-dropdown-main-container-CND'> */}
                            {/* <Col xs="5" className="text-right addNoteBtn"> */}
                            <Col md = "6">
                    <Button
                     data-testid = "btnDelete"
                     value={deleteUserModal}
                        variant="primary" className="delete-note"
                         onClick={() => {
                            // deleteUser()
                            setDeleteUserModal(true)
                        }}
                        >
                        {/* <img src={addNoteIcon} alt='' className='add-note-img' /> */}
                        Delete Account
                    </Button>
                    </Col>
                            <Col md = "6">
                     <Button
                     data-testid = "btnUpdate"
                     value={UpdateBtnVaL}
                        variant="primary" className="add-note" onClick={() => {
                            update()
                        }}>
                        {/* <img src={addNoteIcon} alt='' className='add-note-img' /> */}
                        Update
                    </Button>
                    </Col>

                    </Row>
                     {/* </Col> */}
                            {/* </div> */}
                        </Col>
                    </Row>
                </Card.Body>
            </Card >
        {/* return ( */}
            <div className='profileMainContainer'>
                {/* --------------User INfo------------ */}
                <div className='userProfileDetailsMain'>
                    {/* --------------User Profile------------ */}
                    {userProfileName ? <div style={{ backgroundImage: `url(${Userprofile})` }} className='ProfileImageDiv'>
                        <div className='userDivProfile'>
                            <div>
                                <img src={Userprofile} alt='' className='userprofileImage' />
                            </div>
                            <Button className='changeButton'>
                                {showLoaderUpload ? <img
                                    src={Load}
                                    alt=""
                                    className="loaderIconCssUserSelected"
                                /> :
                                    <label htmlFor="file" className={changeButtonText.join(' ')}>Change</label>}
                            </Button>
                            <input className="custom-file-input" onChange={(e) => handleChangeFile(e.target.files)} onClick={handleClick} type="file" id="file" name="file" accept="image/*" />
                        </div>
                    </div>
                        :
                        <div className='NoProfileImageDiv'>
                            <div className={NoStudentImageMainDiv.join(' ')}>
                                {showLoaderUpload ? <img src={Load} alt='' className='NoImageLoader' /> :
                                    <label htmlFor="file"> <img src={camera} alt='' className='NoImageCameraIcon' /></label>}
                            </div>
                            <input className="custom-file-input" onChange={(e) =>handleChangeFile(e.target.files)} onClick={handleClick} type="file" id="file" name="file" accept="image/*" />
                        </div>}
                    {/* --------------User Profile------------ */}

                    {/* --------------User Details------------ */}
                    <div className='InfoDiv'>
                        <h5 className='editProfileText'>Edit Profile</h5>
                        <div className='inputpasswordFieldMainDiv'>
                            <div className={disabledDiv.join(' ')} >
                            <h5 className='i'></h5>
                                <CommonTextField
                                    dataTestId = "FirstNameFld"
                                    margin="normal"
                                    variant="outlined"
                                    type='FirstName'
                                    value={FirstName}
                                    onChange={handleChange}
                                    name="FirstName"
                                    className="InfoInputType"
                                    placeholder="Enter first name" />
                                {(!FirstName && isSubmit) && <p className="errormessageRegister">Please enter first name</p>}
                                {firstNameErrorMessage.length > 0 && <p className="errormessageRegister">{firstNameErrorMessage}</p>}
                            </div>
                            <div className={disabledDiv.join(' ')} >
                                <h5 className='e'></h5>
                                <CommonTextField
                                    dataTestId = "LastNameFld"
                                    margin="normal"
                                    variant="outlined"
                                    type='LastName'
                                    value={LastName}
                                    onChange={handleChange}
                                    name="LastName"
                                    className="InfoInputType"
                                    placeholder="Enter last name" />
                                {(!LastName && isSubmit) && <p className="errormessageRegister">Please enter last name</p>}
                                {lastNameErrorMessage.length > 0 && <p className="errormessageRegister">{lastNameErrorMessage}</p>}
                            </div>
                        </div>
                        <div className='inputpasswordFieldMainDiv'>
                            <div className={disabledDiv.join(' ')} >
                                {/* <h5 className='inputLableInfo'>School</h5> */}
                                <CommonTextField
                                    dataTestId = "SchoolFld"
                                    margin="normal"
                                    disabled={true}
                                    variant="outlined"
                                    type='SchoolName'
                                    value={SchoolName}
                                    onChange={handleChange}
                                    name="SchoolName"
                                    className="InfoInputType"
                                    placeholder="school name" />
                            </div>
                            <div className={disabledDiv.join(' ')} >
                                {/* <h5 className='inputLableInfo'>Email</h5> */}
                                <CommonTextField
                                    dataTestId = "EmailFld"
                                    margin="normal"
                                    disabled={true}
                                    variant="outlined"
                                    type='Email'
                                    value={Email}
                                    onChange={handleChange}
                                    name="Email"
                                    className="InfoInputType"
                                    placeholder="Email" />
                            </div>
                            {/* <div className={disabledDiv.join(' ')} >
                                <h5 className='e'></h5>
                                <CommonTextField
                                   style = {{marginTop: "8px !important"}}
                                    margin="normal"
                                    disabled={true}
                                    variant="outlined"
                                    type='Phone No.'
                                    value={PhoneNo !== "null" ? PhoneNo : ""}
                                    onChange={handleChange}
                                    name="Phone No."
                                    className="InfoInputType phoneno"
                                    placeholder="Phone No." />
                            </div> */}
                        </div>
                    </div>
                    {/* --------------User Details------------ */}
                </div>
                {/* --------------User INfo------------ */}

                {/* --------------Chnage Password------------ */}
                <div className='paddingDiv'>
                    <div className='changePassMainDiv'>
                        <h5 className='editProfileText'>Change Password</h5>
                        <div className='inputpasswordFieldMainDiv'>
                            <div className={passwordField.join(' ')} >
                                <h5 className='e'></h5>
                                <TextField
                                   data-testid = "OldPwdFld"
                                    margin="normal"
                                    variant="outlined"
                                    type='Password'
                                    value={OldPassword}
                                    onChange={handleChange}
                                    name="OldPassword"
                                    className="InfoInputType"
                                    placeholder="Old password*"
                                    onBlur={OldPassword.length > 0 ? (event) => {
                                            confirmUserByCurrentPasswordFromFirebase(
                                                event.target.value
                                            );
                                           
                                      } : ""}/>
                                {(!OldPassword && isSubmitPassword) && <p className="errormessageRegister">Please enter old password</p>}
                            </div>
                            <div className={newPasswordField.join(' ')} >
                                <h5 className='e'></h5>
                                <CommonTextField
                                dataTestId = "NewPwd"
                                    margin="normal"
                                    variant="outlined"
                                    type='Password'
                                    value={NewPassword}
                                    onChange={handleChange}
                                    name="NewPassword"
                                    className="InfoInputType"
                                    placeholder="New password*" 
                                    disabled={disabledPasswordField || validatePasswor}
                                    />
                                {(!NewPassword && isSubmitPassword) && <p className="errormessageRegister">Please enter new password</p>}
                                {InvelidMessagePass && <p className="errormessageRegister">{InvelidMessagePass}</p>}
                            </div>
                            {/* <div className={newPasswordField.join(' ')} >
                                <h5 className='inputLableInfo'>Confirm Password*</h5> */}
                                <div className={newPasswordField.join(' ')} >
                            <h5 className='e'></h5>

                                <CommonTextField
                                dataTestId = "CnfmPwd"
                                    margin="normal"
                                    variant="outlined"
                                    type='Password'
                                    value={ConfirmPassword}
                                    onChange={handleChange}
                                    name="ConfirmPassword"
                                    className="InfoInputType ConfirmPswd"
                                    placeholder="Confirm password*" 
                                    disabled={disabledPasswordField || validatePasswor}
                                    />
                                {(!ConfirmPassword && isSubmitPassword) && <p className="errormessageRegister">Please enter confirm password</p>}
                                {InvelidMessagePass && <p className="errormessageRegister">{InvelidMessagePass}</p>}


                            </div>
                        </div>

                        <div>
                            <p className='noteTextForgot'>*Please create a password with a combination of letters, numbers, and special characters, at least six characters long.</p>
                        </div>
                    </div>
                </div>
                {/* --------------Chnage Password------------ */}
                {/****************Delete User Modal ****************/}

                <Modal centered className="delete-modal-warning br-8 delete-modal-userProfile" show={deleteUserModal}>
                    <Modal.Header>
                        <Modal.Title>Delete Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="confirm-text mb-3 mt-2 sure-text">Are you sure?</p>
                            <p className="confirm-text permanent-text">This will delete all data permanently?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn" onClick={() => {
                           setDeleteUserModal(false)
                        }}>
                            Cancel
                        </Button>
                        <Button variant="default" className="delete-btn BR-8" onClick={() => {deleteUser(); setDeleteUserModal(false)}}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* --------------Update Button------------ */}
                {/* <div className={updateBtn.join(' ')} >
                    <Button onClick={() => {
                        update()
                    }} color="primary" size="lg" block>Update Account</Button>
                </div> */}
                {/* --------------Update Button------------ */}
                <Modal show={open} className='resizeModal'>
                    <Modal.Body>
                        <ReactAvatar Userprofile={selectedUserprofile} open={open} handleCroppedUrl={handleCroppedUrl} handleModalClose={handleModalClose} />
                    </Modal.Body>
                </Modal>
                {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
            </div >
            </div>
            </>
        )
}
const mapStateToProps = state => ({
    state: state,
    UserInfo: state.profile,
});
const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(updateUser(data)),
        deleteUser: (data) => dispatch(deleteUser(data))
    };
};
UserProfile.propTypes = {
    updateUser: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(
    UserProfile
);