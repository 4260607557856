import { call, takeLatest, put } from 'redux-saga/effects';
import notificationReducer from 'Redux/Reducers/NotificationReducer/notificationReducer';
import { createNotification } from 'Config/notificationtoast';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { getImage } from "Config/commonFirebaseImage";
import { decryptData, encryptData } from 'Utils/Helper';
import * as routes from "Router/RoutesURL";

import {
    GET_NOTIFICATION_REQUEST,
    REJECT_SHARED_CLASS_REQUEST,
    REJECT_SHARED_STUDENT_REQUEST,
    DELETE_NOTIFICATION_REQUEST,
    NOTIFICATION_MARK_AS_READ_REQUEST,
    GET_NOTIFICATION_COUNT_REQUEST,
    GET_USER_PLAN_SPACE_DETAILS

} from 'Actions/constants';

import {
    GetMyNotificationList_API,
    ApprovedRejectSharedClass_API,
    ApprovedRejectSharedStudent_API,
    DeleteNotification_API,
    NotificationsMarkAsRead_API,
    NotificationsCount_API,
    GetUserSpaceRequest_API,
    ApprovedRejectSharedClassBySchoolTeacher_API,
    ApprovedRejectSharedStudentBySchoolTeacher_API
} from 'Config/Api';

import { chroniclecloud_code } from 'Config/Constant';
import { logOut } from 'Utils/Helper';
import request from 'Config/request';


export function* notificationSaga() {
    yield takeLatest(GET_NOTIFICATION_REQUEST, getNotificationRequest);
    yield takeLatest(REJECT_SHARED_CLASS_REQUEST, rejectSharedClassRequest);
    yield takeLatest(GET_USER_PLAN_SPACE_DETAILS, getUserPlanSpaceDetails);
    yield takeLatest(REJECT_SHARED_STUDENT_REQUEST, rejectSharedStudentRequest);
    yield takeLatest(DELETE_NOTIFICATION_REQUEST, deleteNotificationRequest);
    yield takeLatest(NOTIFICATION_MARK_AS_READ_REQUEST, notificationMarkAsReadRequest);
    yield takeLatest(GET_NOTIFICATION_COUNT_REQUEST, getNotificationCountRequest);
}

// ---------- Get Notification-----
function* getNotificationRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // yield put(UserLoadingReducer.loading(false));
    // const URL = `${GetMyNotificationList_API}?user_id=${data.cc_user_id}&page=${action.data.page_no}&limit=${10}&platform=web`;
    const URL = `${GetMyNotificationList_API}?user_id=${data?.cc_user_id}&page=${action?.data?.page_no}&limit=${10}&platform=web`; // only for temp. platform not added
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "device_udid": sessionStorage.getItem('UDID'),
        "user_id": data?.cc_user_id,
        "page_no": action?.data?.page_no,
        "platform": "web" // not added in API
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            for (let notifications of MainDecode?.data?.data) {
                if (notifications.cc_user_pic) {
                    if (!(notifications.cc_user_pic.includes("https") === true)) {
                        notifications.cc_user_pic = yield getImage(notifications.cc_user_pic);
                    }
                }
            }
            yield put(notificationReducer.getNotificationSuccess(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(notificationReducer.getNotificationFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.getNotificationFailure(false));

    }
}
// ---------- Get Notification-----

// ---------- Reject Shared Class -----
function* rejectSharedClassRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = data?.user_type == "school_teacher" ? ApprovedRejectSharedClassBySchoolTeacher_API : ApprovedRejectSharedClass_API;
    const BODY = {
        "notification_id": action.data.notification_id,
        "status": action.data.status,
        "platform": "web"
    }
    
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.data.message);
            yield put(notificationReducer.rejectSharedClassSuccess(MainDecode));
        } else {
            // if (MainDecode.data.message ? MainDecode.data.message : MainDecode.message === "Your session has been expired. Please login again") {
            //     // logOut('error', MainDecode.data.message ? MainDecode.data.message : MainDecode.message)
            // }
            if (MainDecode.data.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.data.message)
            }
            createNotification('error', MainDecode.data.message ? MainDecode.data.message : MainDecode.message);
            yield put(notificationReducer.rejectSharedClassFailure(MainDecode));
            // setTimeout(() => {
                
            //     if (MainDecode.data.message == "The Class could not be saved as it exceeds your plan limit. Please upgrade the plan.") {
            //         window.location.href = routes.DATAUSAGE;
            //     }
            // }, 2000);

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.rejectSharedClassFailure(false));

    }
}
// ---------- Reject Shared Class -----

//------------Get User Space-----------
function* getUserPlanSpaceDetails(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetUserSpaceRequest_API}?user_id=${data?.cc_user_id}&platform=web`;
    
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.data.message);
            yield put(notificationReducer.getUserSpaceRequestSuccess(MainDecode));
        } else {
            if (MainDecode.data.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.data.message)
            }
            createNotification('error', MainDecode.data.message);
            yield put(notificationReducer.getUserSpaceRequestFailure(MainDecode));
            setTimeout(() => {
                
                if (MainDecode.data.message == "The Class could not be saved as it exceeds your plan limit. Please upgrade the plan.") {
                    window.location.href = routes.DATAUSAGE;
                }
            }, 2000);

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.getUserSpaceRequestFailure(false));

    }
}


// ---------- Reject Shared Student -----
function* rejectSharedStudentRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = ApprovedRejectSharedStudent_API;
    const URL = data?.user_type == "school_teacher" ? ApprovedRejectSharedStudentBySchoolTeacher_API : ApprovedRejectSharedStudent_API;
    const BODY = {
        "notification_id": action.data.notification_id,
        "status": action.data.status,
        "platform": "web",
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.data.message ? MainDecode.data.message : MainDecode.message);
            yield put(notificationReducer.rejectSharedStudentSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.data.message ? MainDecode.data.message : MainDecode.message)
            }
            createNotification('error', MainDecode.data.message ? MainDecode.data.message : MainDecode.message);
            yield put(notificationReducer.rejectSharedStudentFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.rejectSharedStudentFailure(false));

    }
}
// ---------- Reject Shared Student -----


// ---------- Delete Notification -----
function* deleteNotificationRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteNotification_API;
    // const URL = `${DeleteNotification_API}?user_id=${data.cc_user_id}&notification_id=${action.data.notification_id}&action=${action.data.action}&platform=${"web"}`;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "device_udid": sessionStorage.getItem('UDID'),
        "notification_id": action.data.notification_id,
        "user_id": data.cc_user_id,
        "action": action.data.action,
        "platform": "web",
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            yield put(notificationReducer.deleteNotificationSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(notificationReducer.deleteNotificationFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.deleteNotificationFailure(false));

    }
}
// ---------- Delete Notification -----


// ---------- Notification Mark As Read Request -----
function* notificationMarkAsReadRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = NotificationsMarkAsRead_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "type ": action.data.type,
        "platform": "web",

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            yield put(notificationReducer.notificationMarkAsReadSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(notificationReducer.notificationMarkAsReadFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.notificationMarkAsReadFailure(false));

    }
}
// ---------- Notification Mark As Read -----



// ---------- Notification Count Request -----
function* getNotificationCountRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = NotificationsCount_API;
    const URL = `${NotificationsCount_API}?user_id=${data.cc_user_id}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID')
    })
    let response;
    try {

        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            yield put(notificationReducer.getNotificationCountSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(notificationReducer.getNotificationCountFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(notificationReducer.getNotificationCountFailure(false));

    }
}
// ---------- Notification Count Request-----