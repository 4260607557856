import { call, takeLatest, put } from "redux-saga/effects";
import UserCoreStandardReducer from "Redux/Reducers/CoreStandardReducer/coreStandardReducer";
import UserClassStandardReducer from "Redux/Reducers/ClassReducer/classStandardsReducer";
import { createNotification } from "Config/notificationtoast";
import {
  DELETE_CUSTOM_STANDARDS,
  GET_CORE_STANDARD_LIST_REQUEST,
  IMPORT_CUSTOM_STANDARDS,
} from "Actions/constants";
import {
  GetClassCoreStandard_List_API,
  deleteCustomStandard_API,
  importCustomStandard_API,
} from "Config/Api";
import { chroniclecloud_code } from "Config/Constant";
import request from "Config/request";
import { decryptData, encryptData, logOut } from "Utils/Helper";

export function* userCoreStandardModuleSaga() {
  yield takeLatest(GET_CORE_STANDARD_LIST_REQUEST, getCoreStandardListRequest);
  yield takeLatest(IMPORT_CUSTOM_STANDARDS, importCustomStandards);
  yield takeLatest(DELETE_CUSTOM_STANDARDS, deleteCustomStandards);
}

// ---------- Get Class Note-----
function* getCoreStandardListRequest(action) {
  const data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = GetClassCoreStandard_List_API;
  const BODY = JSON.stringify({
    chroniclecloud_code: chroniclecloud_code,
    user_id: data.cc_user_id,
    device_udid: sessionStorage.getItem("UDID"),
    class_id: action.data.classId,
    academic_year_id: action.data.academicYearId,
  });

  let response;
  try {
    response = yield call(request, URL, {
      method: "POST",
      body: { data: btoa(BODY) },
    });
    const MainDecode = JSON.parse(atob(response));
    if (MainDecode.status === "Success") {
      yield put(
        UserCoreStandardReducer.getCoreStandardListSuccess(
          MainDecode,
          action.data.pageNo
        )
      );
    } else {
      createNotification("error", MainDecode.message);
      yield put(UserCoreStandardReducer.getCoreStandardListFailure(MainDecode));
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserCoreStandardReducer.getCoreStandardListFailure(false));
  }
}
// ---------- Get Student Note-----

function* importCustomStandards(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  // yield put(UserLoadingReducer.loading(true));
  const URL = importCustomStandard_API;
  let BODY = {}
  if(action?.data?.updated_type) {
    BODY = {
      user_id: data.cc_user_id,
      core_standard_data: action?.data?.core_standard_data,
      updated_type: action?.data?.updated_type,
      platform: "web",
    };
  } else {
    BODY = {
      user_id: data.cc_user_id,
      core_standard_data: action?.data?.core_standard_data,
      updated_type: 'append',
      platform: "web",
    };
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: "POST",
      body: { data: encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {
      createNotification("success", MainDecode.message);
      yield put(
        UserCoreStandardReducer.importCustomStandardsSuccess(MainDecode)
      );
      let obj = {
        main_id: '',
        group_id: '',
        grade_id: '',
        standard_id: '',
        fromImport: true,
      }
      yield put(UserClassStandardReducer.getCoreStandard(obj));
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(
        UserCoreStandardReducer.importCustomStandardsError(MainDecode.message)
      );
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserCoreStandardReducer.importCustomStandardsError(error));
  }
}

function* deleteCustomStandards(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  // yield put(UserLoadingReducer.loading(true));
  const URL = deleteCustomStandard_API;
  const BODY = {
    user_id: data.cc_user_id,
    main: action?.data?.main_id,
    platform: "web",
  };

  let response;
  // try {
  //   response = yield call(request, URL, {
  //     method: "PUT",
  //     body: { data: encryptData(BODY) },
  //   });
  //   const MainDecode = decryptData(response.res);
  //   console.log(MainDecode);
  //   if (MainDecode.status === 200) {
  //   } else {
  //     if (
  //       MainDecode.message ===
  //       "Your session has been expired. Please login again"
  //     ) {
  //       logOut("error", MainDecode.message);
  //     }
  //     createNotification("error", MainDecode.message);
  //   }
  // } catch (error) {
  //   createNotification("warning", "Something went wrong");
  // }
}
