import { call, takeLatest, put } from "redux-saga/effects";
import UserClassNoteReducer from "Redux/Reducers/NoteReducer/classNoteReducer";
import { createNotification } from "Config/notificationtoast";
import {
  CLASS_GROUP_NOTE_ARTIFACT,
  CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT,
  CLASS_GROUP_NOTE_DELETE,
  GET_CLASS_GROUP_NOTES_DATA,
  GET_CLASS_NOTE_REQUEST,
} from "Actions/constants";
import {
  GetRosterWithoutClass_API,
  SetClassgroupNoteArtifact_API,
  SetMediaAttachmentArtifact_API,
  classGroupNoteDelete_API,
  getClassGroupNotesData_API,
} from "Config/Api";
import { chroniclecloud_code } from "Config/Constant";
import request from "Config/request";
import { decryptData, encryptData, logOut } from "Utils/Helper";
import { getImage, getRosterImage } from "Config/commonFirebaseImage";

export function* userClassNoteModuleSaga() {
  yield takeLatest(GET_CLASS_NOTE_REQUEST, getClassNoteRequest);
  yield takeLatest(GET_CLASS_GROUP_NOTES_DATA, getClassGroupNotesData);
  yield takeLatest(CLASS_GROUP_NOTE_DELETE, classGroupNoteDelete);
  yield takeLatest(CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT, classGroupNoteAttachementArtifact);
  yield takeLatest(CLASS_GROUP_NOTE_ARTIFACT, classGroupNoteArtifact);
}

// ---------- Get Class Note-----
function* getClassNoteRequest(action) {
  const data = JSON.parse(sessionStorage.getItem("UserData"));
  let URL = "";
  if (action.data.limit) {
    URL = `${GetRosterWithoutClass_API}?user_id=${data.cc_user_id}&class_id=${
      action.data.classId > 0 ? action.data.classId : null
    }&page_no=${action.data.pageNo}&limit=${action.data.limit}&filter_by=${
      action.data.filterBy
    }&academic_year_id=${action.data.academicYearId}&sort_by=${
      action.data.sortBy
    }&tag_id=${Number(action.data.tagId) || ""}&search_text=${
      action.data.search_text
    }&platform=web`;
  } else {
    URL = `${GetRosterWithoutClass_API}?user_id=${data.cc_user_id}&class_id=${
      action.data.classId > 0 ? action.data.classId : null
    }&page_no=${action.data.pageNo}&filter_by=${
      action.data.filterBy
    }&academic_year_id=${action.data.academicYearId}&sort_by=${
      action.data.sortBy
    }&tag_id=${Number(action.data.tagId) || ""}&search_text=${
      action.data.search_text
    }&platform=web`;
  }
  let response;

  try {
    response = yield call(request, URL, {
      method: "GET",
      // body: { "data": btoa(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status == 200) {
      // if (MainDecode?.data?.student_data?.length < 1) {
      //   createNotification("error", "Data not Found.");
      // }
      for (let rosterItem of MainDecode.data.student_data) {
        if (rosterItem.cc_student_pic_url) {
          if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
            rosterItem.cc_student_pic_url = yield getRosterImage(
              rosterItem.cc_student_pic_url
            );
          }
        }
      }
      yield put(
        UserClassNoteReducer.getClassNoteSuccess(MainDecode, action.data.pageNo)
      );
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(UserClassNoteReducer.getClassNoteFailure(MainDecode));
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassNoteReducer.getClassNoteFailure(false));
  }
}
// ---------- Get Student Note-----

//----------- Get Class Group Notes--------
function* getClassGroupNotesData(action) {
  const data = JSON.parse(sessionStorage.getItem("UserData"));

  let URL = `${getClassGroupNotesData_API}?academic_id=${action?.data?.academicYearId}&student_id=${action?.data?.studentId}&user_id=${data?.cc_user_id}&class_id=${action?.data?.classId}&group_id=${action?.data?.groupId}&page_no=${action?.data?.pageNo}&filter_by=${action?.data?.filter}&limit=${action?.data?.limit}&platform=web`;

  let response;
  try {
    response = yield call(request, URL, {
      method: "GET",
      // body: { "data": btoa(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status == 200) {
      if (MainDecode?.data?.student_list?.length < 1) {
        createNotification("error", "Data not Found.");
      }
      for (let rosterItem of MainDecode?.data?.student_list) {
        if (rosterItem?.cc_student_pic_url) {
          if (!(rosterItem?.cc_student_pic_url.includes("https") === true)) {
            rosterItem.cc_student_pic_url = yield getRosterImage(
              rosterItem?.cc_student_pic_url
            );
          }
        }
      }
      yield put(
        UserClassNoteReducer.getClassGroupNotesDataSuccess(
          MainDecode,
          action.data.pageNo
        )
      );
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(UserClassNoteReducer.getClassGroupNotesDataError(MainDecode));
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassNoteReducer.getClassGroupNotesDataError(false));
  }
}

//------------ Class Group Note Delete-------
function* classGroupNoteDelete(action) {
  const data = JSON.parse(sessionStorage.getItem("UserData"));

  let URL = `${classGroupNoteDelete_API}`;

  const BODY = {
    user_id: `${data.cc_user_id}`,
    note_ids: action.data.note_ids,
    platform: "web",
  };

  let response;
  try {
    response = yield call(request, URL, {
      method: "POST",
      body: { data: encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status == 200) {
      yield put(UserClassNoteReducer.classGroupNoteDeleteSuccess(MainDecode));
      let data = {
        academicYearId: action?.data?.academicYearId,
        studentId: action?.data?.studentId,
        classId: action?.data?.classId,
        groupId: action?.data?.groupId,
        pageNo: action?.data?.pageNo,
        filter: action?.data?.filter,
        limit: 100,
      };
      yield put(UserClassNoteReducer.getClassGroupNotesData(data));
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(UserClassNoteReducer.classGroupNoteDeleteError(MainDecode));
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassNoteReducer.classGroupNoteDeleteError(false));
  }
}

function* classGroupNoteAttachementArtifact(action) {
  console.log(action)
  let data = JSON.parse(sessionStorage.getItem('UserData'));
  const URL = SetMediaAttachmentArtifact_API;
  const BODY = {
      // "chroniclecloud_code": chroniclecloud_code,
      // "user_id": data.cc_user_id,
      // "device_udid": sessionStorage.getItem('UDID'),
      "attachment_marked_artified": action.data.cc_media_mark_artified ,
      "cc_attachment_id": `${action.data.cc_attachment_id}`,
      "platform": "web"
  }
  let response;
  try {
      response = yield call(request, URL, {
          method: 'POST',
          body: { "data": encryptData(BODY) },
      });
      let MainDecode = decryptData(response.res);
      console.log(217, MainDecode)
      if (MainDecode.status == 200) {
          yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactSuccess(MainDecode));
          createNotification('success', MainDecode.message);
          let data = {
        academicYearId: action?.data?.academicYearId,
        studentId: action?.data?.studentId,
        classId: action?.data?.classId,
        groupId: action?.data?.groupId,
        pageNo: action?.data?.pageNo > 1 ? action?.data?.pageNo : 1,
        filter: action?.data?.filter,
        limit: 100,
        loader : false
      };
      console.log(data)
      yield put(UserClassNoteReducer.getClassGroupNotesData(data));

      } else {
          if (MainDecode.message === "Your session has been expired. Please login again") {
              logOut('error', MainDecode.message)
          }
          createNotification('error', MainDecode.message);
          yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactError(MainDecode));



      }
  } catch (error) {
    console.log(error)
      createNotification('warning', 'Something went wrong');
      yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactError(false));

  }
}

function* classGroupNoteArtifact(action) {
  let data = JSON.parse(sessionStorage.getItem('UserData'));
  const URL = SetClassgroupNoteArtifact_API;
  const BODY = {
      // "chroniclecloud_code": chroniclecloud_code,
      "user_id": data.cc_user_id,
      // "device_udid": sessionStorage.getItem('UDID'),
      "class_id" : action?.data?.class_id,
      "student_details_array": action?.data?.student_details_array,
      "platform": "web"
  }
  let response;
  try {
      response = yield call(request, URL, {
          method: 'POST',
          body: { "data": encryptData(BODY) },
      });
      let MainDecode = decryptData(response.res);
      if (MainDecode.status == 200) {
          yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactSuccess(MainDecode));
          createNotification('success', MainDecode.message);
          let data = {
        academicYearId: action?.data?.academicYearId,
        studentId: action?.data?.studentId,
        classId: action?.data?.class_id,
        groupId: action?.data?.groupId,
        pageNo: action?.data?.pageNo > 1 ? action?.data?.pageNo : 1,
        filter: action?.data?.filter,
        limit: action?.data?.limit,
        loader : false
      };
      yield put(UserClassNoteReducer.getClassGroupNotesData(data));

      } else {
          if (MainDecode.message === "Your session has been expired. Please login again") {
              logOut('error', MainDecode.message)
          }
          createNotification('error', MainDecode.message);
          yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactError(MainDecode));



      }
  } catch (error) {
      createNotification('warning', 'Something went wrong');
      yield put(UserClassNoteReducer.classGroupNoteAttachementArtifactError(false));

  }
}