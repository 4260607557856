import { useState, useEffect, useCallback } from "react";

export function useSelection(readerRef, tooltipRef, closeButtonRef) {
    const [selection, setSelection] = useState('');

    const resetSelection = useCallback(() => {
        setSelection('');
        if (tooltipRef) {
            tooltipRef.style.display = 'none';
        }
    }, [tooltipRef]);

    const handleSelection = useCallback((event) => {
        const selectionObject = document.getSelection();
        if (selectionObject && selectionObject.toString().length > 0 && event?.target?.id === 'newFormId') {
            const range = selectionObject.getRangeAt(0);
            const boundedRect = range.getBoundingClientRect();
            setSelection(selectionObject.toString());

            if (tooltipRef) {
                tooltipRef.style.display = 'flex';
                const tooltipDimensions = tooltipRef.getBoundingClientRect();
                tooltipRef.style.top = `${boundedRect.bottom + window.scrollY + 10}px`;
                tooltipRef.style.left = `${boundedRect.left + ((boundedRect.width - tooltipDimensions.width) / 2) + window.scrollX}px`;
            }
        } else {
            // Handle when selection is cleared by clicking outside
            resetSelection();
        }
    }, [tooltipRef, resetSelection]);

    const handleDoubleClick = useCallback((event) => {
        if (event.target.id === 'newFormId') {
            const textArea = event.target;
            textArea.select();
            handleSelection(event);
        }
    }, [handleSelection]);

    const handleClickOutside = useCallback((event) => {
        if (
            tooltipRef &&
            tooltipRef.style.display === 'flex' &&
            !tooltipRef?.contains(event.target) &&
            !readerRef?.contains(event.target) &&
            !closeButtonRef?.contains(event.target)
        ) {
            resetSelection();
        }
    }, [tooltipRef, readerRef, closeButtonRef, resetSelection]);

    const handleCloseButtonClick = useCallback((event) => {
        if (closeButtonRef && closeButtonRef?.contains(event.target)) {
            resetSelection();
        }
    }, [closeButtonRef, resetSelection]);

    useEffect(() => {
        const currentReaderRef = readerRef;
        const currentCloseButtonRef = closeButtonRef;

        if (currentReaderRef) {
            currentReaderRef.addEventListener('mouseup', handleSelection);
            currentReaderRef.addEventListener('dblclick', handleDoubleClick);
        }

        if (currentCloseButtonRef) {
            currentCloseButtonRef.addEventListener('click', handleCloseButtonClick);
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            if (currentReaderRef) {
                currentReaderRef.removeEventListener('mouseup', handleSelection);
                currentReaderRef.removeEventListener('dblclick', handleDoubleClick);
            }
            if (currentCloseButtonRef) {
                currentCloseButtonRef.removeEventListener('click', handleCloseButtonClick);
            }
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [readerRef, tooltipRef, closeButtonRef, handleSelection, handleDoubleClick, handleClickOutside, handleCloseButtonClick]);

    useEffect(() => {
        const onSelectionChange = () => {
            const selectionObject = document.getSelection();
            if (!selectionObject || selectionObject.toString().length === 0) {
                resetSelection();
            }
        };

        document.addEventListener('selectionchange', onSelectionChange);

        return () => {
            document.removeEventListener('selectionchange', onSelectionChange);
        };
    }, [resetSelection]);

    return { selection };
}
