import { call, takeLatest, put } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import userSubscriptionPlanReducer from 'Redux/Reducers/SubscriptionPlanReducer/subscriptionPlanReducer';
import { SubscriptionPlan_API, changeUserSubscription_API, getStripeProducts_API } from "Config/Api";
import { CHANGE_USER_SUBSCRIPTION_REQUEST, GET_STRIPE_PRODUCTS, GET_SUBSCRIPTION_PLAN_REQUEST } from 'Actions/constants';
import {
    app_version,
    device_version
  } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';


export function* userSubscriptionPlanSaga() {
    yield takeLatest(GET_SUBSCRIPTION_PLAN_REQUEST, getSubscriptionPlanRequest);
    yield takeLatest(GET_STRIPE_PRODUCTS, getStripeProducts);
    yield takeLatest(CHANGE_USER_SUBSCRIPTION_REQUEST, changeUserSubscriptionRequest);
}

// ---------- Get Blog -----
function* getSubscriptionPlanRequest() {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${SubscriptionPlan_API}?user_id=${data.cc_user_id}&app_version=${app_version}&device_version=${device_version.replace(/"|_| /g, '')}&platform=web`;
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        const MainDecode = decryptData(response.res);
        console.log(30, MainDecode)
        if (MainDecode.status === 200) {
            yield put(userSubscriptionPlanReducer.getSubscriptionPlanSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userSubscriptionPlanReducer.getSubscriptionPlanFailure(MainDecode));

        }
    } catch (error) {
        yield put(userSubscriptionPlanReducer.getSubscriptionPlanFailure());
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Get Stripe Products------
function* getStripeProducts() {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${getStripeProducts_API}?user_id=${data.cc_user_id}&app_version=${app_version}&device_version=${device_version.replace(/"|_| /g, '')}&platform=web`;
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        const MainDecode = decryptData(response.res);
        console.log(57, MainDecode);
        if (MainDecode.status === 200) {
            yield put(userSubscriptionPlanReducer.getStripeProductsSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userSubscriptionPlanReducer.getStripeProductsError(MainDecode));

        }
    } catch (error) {
        console.log(error)
        yield put(userSubscriptionPlanReducer.getStripeProductsError());
        createNotification('warning', 'Something went wrong');

    }
}


function* changeUserSubscriptionRequest(action) {
    console.log(action);
    let data = JSON.parse(sessionStorage.getItem("UserData"));
    // yield put(UserLoadingReducer.loading(true));
    const URL = changeUserSubscription_API;
    const BODY = {
      customer_id:action?.data?.customerId,
      price_id:action?.data?.priceId,
      platform: "web",
    };
  
    let response;
    try {
      response = yield call(request, URL, {
        method: "POST",
        body: { data: encryptData(BODY) },
      });
      const MainDecode = decryptData(response.res);
      console.log(MainDecode)
      if (MainDecode.status === 200) {
        yield put(userSubscriptionPlanReducer.changeUserSubscriptionSuccess(MainDecode.data));
        window.open(MainDecode?.data?.url, "_self")
      } else {
        if (
          MainDecode.message ===
          "Your session has been expired. Please login again"
        ) {
          logOut("error", MainDecode.message);
        }
        createNotification("error", MainDecode.message);
        yield put(userSubscriptionPlanReducer.changeUserSubscriptionFailure(MainDecode));
      }
    } catch (error) {
      createNotification("warning", "Something went wrong");
      yield put(userSubscriptionPlanReducer.changeUserSubscriptionFailure(error));
    }
  }