import { viewLessonPlanDetails } from 'Actions/ClassAction/ClassLessonPlanAction';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Execution.scss';

const Execution = (props) => {
    const {
        viewLessonPlanDetailsState,
        apiCallPlanning,
        setSaveData,
        ClassDetails,
        selectionType,
        lessonPlanId,
        selectedTab,
        setApiDatasExecution,
        lesson_plan_id,
        questionsList
        // viewLessonPlanDetails
    } = props;
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [defaultLessonDetails, setDefaultLessonDetails] = useState([]);
    const [lpCreatedAt, setLpCreatedAt] = useState("");
    const [lpPlannedAt, setLpPlannedAt] = useState("");
    const [lpTitle, setLpTitle] = useState("");
    const [lpTopic, setLpTopic] = useState("");
    const apiData = {};

    useEffect(() => {
        props?.sendtitle(lpTitle)
        props?.sendtopic(lpTopic)
        props?.sendquestionanswerobject(viewLessonPlanDetailsState?.data?.question_answer_array)
        props?.sendplannedat(viewLessonPlanDetailsState?.data?.cc_lp_planned_at)
        if (selectedTab === "Execution") {
            
            setSaveData("execution")
        }
       
    })
    const dispatch = useDispatch();
   useEffect(() => {


    if (selectedTab == "Execution") {
        const apiData = {
            "class_id": ClassDetails?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
            "lesson_plan_id": lessonPlanId || lesson_plan_id,
        }

        // dispatch(viewLessonPlanDetails(apiData))
        
    }

   }
   , [])
   const statess = useSelector((state) => state)

    useEffect(() => {
        setDefaultLessonDetails(viewLessonPlanDetailsState);
        if (viewLessonPlanDetailsState && viewLessonPlanDetailsState.data) {
            const { cc_lp_created_at, cc_lp_planned_at, cc_lp_title, cc_lp_topic } = viewLessonPlanDetailsState.data;
            setLpCreatedAt(cc_lp_created_at);
            setLpPlannedAt(cc_lp_planned_at);
            setLpTitle(cc_lp_title);
            setLpTopic(cc_lp_topic);
        }
    }, [viewLessonPlanDetailsState])

    useEffect(() => {
        apiData['question_answer_array'] = questionAnswers;
        apiData["class_id"] = ClassDetails?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP");
        apiData['lesson_plan_id'] = lessonPlanId;
        setApiDatasExecution(apiData);
    }, [questionAnswers])

    useEffect(() => {
        
        if (selectionType === "edit" || selectionType == "addNew" ) {
       

            viewLessonPlanDetailsState && viewLessonPlanDetailsState?.data?.question_answer_array?.map(val => {
                const valObj = {};
                if (val.cc_lp_questionnaire_view_tab === "execution") {
                    valObj["id"] = val.cc_lp_questionnaire_id;
                    valObj["question_id"] = JSON.stringify(val.cc_question_id);
                    valObj["question_view_tab"] = val.cc_lp_questionnaire_view_tab;
                    valObj["type"] = val.cc_lp_questionnaire_type;
                    valObj["answer"] = val.cc_lp_questionnaire_response;
                    valObj["question_text"] = val.cc_lp_questionnaire_question_text;
                    questionAnswers.push(valObj)
                }
            })
            setQuestionAnswers(questionAnswers);
        }
        // if(selectionType == "addNew") {
        //     console.log('viewLessonquestionsList', viewLessonPlanDetailsState && viewLessonPlanDetailsState?.data?.question_answer_array);
        //     viewLessonPlanDetailsState && viewLessonPlanDetailsState?.data?.question_answer_array.map(val => {
        //         const valObj = {};
        //         if (val.cc_lp_questionnaire_view_tab === "execution") {
        //             valObj["id"] = val.cc_lp_id;
        //             valObj["question_id"] = JSON.stringify(val.cc_question_id);
        //             valObj["question_view_tab"] = val.cc_question_view_tab;
        //             valObj["type"] = val.cc_lp_questionnaire_type;
        //             valObj["answer"] = val.cc_lp_questionnaire_response;
        //             valObj["question_text"] = val.cc_question_text;
        //             questionAnswers.push(valObj)
        //         }
        //     })
        //     setQuestionAnswers(questionAnswers);
        // }
    }, [viewLessonPlanDetailsState])

    useEffect(() => {
        if (apiCallPlanning === false) {
     
            const quesAnsArr = [];
            defaultLessonDetails && defaultLessonDetails.data.question_answer_array.map(val => {
                const valObj = {};
                if (val.cc_lp_questionnaire_view_tab === "execution") {
                    valObj["id"] = JSON.stringify(val.cc_lp_questionnaire_id);
                    valObj["question_id"] = JSON.stringify(val.cc_question_id);
                    valObj["question_view_tab"] = val.cc_lp_questionnaire_view_tab;
                    valObj["type"] = val.cc_lp_questionnaire_type;
                    valObj["answer"] = val.cc_lp_questionnaire_response;
                    valObj["question_text"] = val.cc_lp_questionnaire_question_text;
                    quesAnsArr.push(valObj)
                }
            })
            setQuestionAnswers(quesAnsArr);
        }
    }, [apiCallPlanning])

    const addQuestionsAns = (val, data) => {
        
        let answersdata = questionAnswers && questionAnswers.map(value => {
            let answerValue = { ...value }
            if (answerValue.question_id === data.question_id) {
                answerValue = { ...answerValue, answer: val }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setQuestionAnswers(answersdata);
    }

    return (
        <div data-testid = "maindivmock" className="executionContainer">
            <div className="planTitleDiv">
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Plan Title: </p>
                        <p className="headTitles">{lpTitle}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned on: </p>
                        <p className="headTitles">{lpCreatedAt}</p>
                    </div>
                </div>
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Topic: </p>
                        <p className="headTitles">{lpTopic}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned for: </p>
                        <p className="headTitles">{lpPlannedAt}</p>
                    </div>
                </div>
            </div>
            <div data-testid = "quesansmockdiv" className="allSubDivs">
                {questionAnswers && questionAnswers.map((val, k) => {
                    return (
                        <div className="rationalDiv" key={k}>
                            <div className="rationalTitleBox">
                                <p className="rationalTitle backendQuestionCaps">{val.question_text}</p>
                            </div>
                            <div className="rationalInputBox">
                                <textarea name="message" className="rationalInputText" id="message" rows="4" value={val.answer} onChange={e => addQuestionsAns(e.target.value, val)}
                                    placeholder="Enter description here.."></textarea>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
export default Execution
