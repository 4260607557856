import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getSubscriptionPlanRequest: ["data"],
  getSubscriptionPlanSuccess: ["data"],
  getSubscriptionPlanFailure: ["error"],

  getStripeProducts: ["data"],
  getStripeProductsError: ["error"],
  getStripeProductsSuccess: ["data"],

  changeUserSubscriptionRequest: ["data"],
  changeUserSubscriptionFailure: ["error"],
  changeUserSubscriptionSuccess: ["data"],
});

export const userSubscriptionPlanReducer = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  plans: [],
  error: null,
  stripeProducts: null,
  exists: null,
  loader: null,
  action: null,
  newSubscriptionPlan: null,
  newSubscriptionPlanError: null,
  newSubscriptionPlanSuccess: null,
  newSubscriptionPlanLoader: false,
});

/* ------------- Reducers ------------- */

/* Get Blog Request */
export const getSubscriptionPlanRequest = (state) => {
  return { ...state, fetching: true, error: null, loader: true };
};
export const getSubscriptionPlanSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    loader: false,
    plans: data.data,
  };
};
export const getSubscriptionPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "GET_SUBSCRIPTION_PLAN_FAILURE",
    loader: false,
  };
};

export const getStripeProducts = (state, action) => {
  return {
    ...state,
    loader: true,
    stripeProducts: null,
    error: null,
  };
};

export const getStripeProductsError = (state, { error }) => {
  return {
    ...state,
    laoder: false,
    stripeProducts: null,
    action: "GET_STRIPE_PRODUCTS_ERROR",
    error,
  };
};

export const getStripeProductsSuccess = (state, action) => {
  console.log(75, state)
  console.log(action)
  return {
    ...state,
    loader: false,
    stripeProducts: action.data,
    action: "GET_STRIPE_PRODUCTS_SUCCESS",
    error: null,
  };
};

export const changeUserSubscriptionRequest = (state, action) => {
  console.log(action)
return {
  ...state,
  newSubscriptionPlan: action?.data,
  newSubscriptionPlanError: null,
  newSubscriptionPlanSuccess: null,
  newSubscriptionPlanLoader: true,
};
};

export const changeUserSubscriptionFailure = (state, { error }) => {
return {
  ...state,
  newSubscriptionPlan: null,
  newSubscriptionPlanError: error,
  newSubscriptionPlanSuccess: null,
  newSubscriptionPlanLoader: false,
};
};

export const changeUserSubscriptionSuccess = (state, action) => {
return {
  ...state,
  newSubscriptionPlan: null,
  newSubscriptionPlanError: null,
  newSubscriptionPlanSuccess: action.data,
  newSubscriptionPlanLoader: false,
};
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SUBSCRIPTION_PLAN_REQUEST]: getSubscriptionPlanRequest,
  [Types.GET_SUBSCRIPTION_PLAN_SUCCESS]: getSubscriptionPlanSuccess,
  [Types.GET_SUBSCRIPTION_PLAN_FAILURE]: getSubscriptionPlanFailure,

  [Types.GET_STRIPE_PRODUCTS]: getStripeProducts,
  [Types.GET_STRIPE_PRODUCTS_ERROR]: getStripeProductsError,
  [Types.GET_STRIPE_PRODUCTS_SUCCESS]: getStripeProductsSuccess,

  [Types.CHANGE_USER_SUBSCRIPTION_REQUEST]: changeUserSubscriptionRequest,
  [Types.CHANGE_USER_SUBSCRIPTION_FAILURE]: changeUserSubscriptionFailure,
  [Types.CHANGE_USER_SUBSCRIPTION_SUCCESS]: changeUserSubscriptionSuccess,
});
