import * as moment from 'moment';
import { createNotification } from 'Config/notificationtoast';
import * as routes from "Router/RoutesURL";
var CryptoJS = require("crypto-js");

function getRandomName() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
}

function getDateFormat(date) {
    // const dateFormat = sessionStorage.getItem('dateFormat').replace(/\//g, "-");
    const dateFormat = "DD-MMM-YYYY";
    return moment(date, sessionStorage.getItem('dateFormat')).format(dateFormat);
    // let Year = new Date(date).getFullYear()
    // let Month = new Date(date).getMonth()
    // let Day = new Date(date).getDate()
    // console.log(Year, Month, Day)
    // console.log(new Date(date))
    // try {
    //     // format - 27-Oct-2020
    //     let FormatDate = ''
    //     const monthName = [
    //         "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ]
    //     let Year = new Date(date).getFullYear()
    //     let Month = new Date(date).getMonth()
    //     let Day = new Date(date).getDate()
    //     FormatDate = Day + '-' + monthName[Month] + '-' + Year
    //     return FormatDate;
    // } catch (e) {
    //     return date
    // }

}

function getDaysdiff(date) {
    try {
        const Today = moment(new Date());
        const upcomingDate = moment(date);
        let value = 0;
        if (upcomingDate > Today) {
            value = upcomingDate.diff(Today, 'days')
        }
        else {
            value = Today.diff(upcomingDate, 'days')

        }
        // const value = Today.diff(upcomingDate, 'days')
        return value;
    } catch (e) {
        return null
    }

}

function logOut(type, msg) {
    // sessionStorage.clear();
    // sessionStorage.setItem('authToken', {})
    // sessionStorage.setItem("UserData", {})
    // sessionStorage.setItem("IsLogin", false)
    // sessionStorage.setItem("keepMeSinged", false)
    // sessionStorage.setItem("FirstTimeLogin", 1)
    window.location.href = routes.LOGIN;
    createNotification(type, msg);
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const getStudentList = (getGroupDetails) => {
    let studentsDetails = [];
    getGroupDetails?.length > 0 && getGroupDetails?.[0]?.map(list => {
        return (studentsDetails.push(list.student_list));
    })
    return studentsDetails;
}

const getGroupLists = (getGroupDetails) => {
    let groupList = [];
    getGroupDetails?.length > 0 && getGroupDetails?.[0]?.map((StdGroupList, k) => {
        if (k !== 0 && StdGroupList.cc_group_name !== "All Students") {
            groupList.push(StdGroupList)
        }
        return groupList
    })
    return groupList
}


const getFormattedDate = (value, dateFormat, separator) => {
    const separatorSign = separator?.slice(-3, -2);
    const dateFormatter = dateFormat?.slice(1, -1);

    try {
        let FormatDate = ''
        const monthName = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ]
        let Year = new Date(value).getFullYear()
        let Month = new Date(value).getMonth() + 1
        let Day = new Date(value).getDate()
        if (dateFormatter === 'DD/MM/YYYY') {
            FormatDate = Day + separatorSign + Month + separatorSign + Year
        } else if (dateFormatter === 'MM/DD/YYYY') {
            FormatDate = Month + separatorSign + Day + separatorSign + Year
        }
        if (dateFormatter === 'YYYY/MM/DD') {
            FormatDate = Year + separatorSign + Month + separatorSign + Day
        } else if (dateFormatter === 'MMM DD/ YYYY') {
            FormatDate = monthName[Month - 1] + ' ' + Day + separatorSign + Year
        }

        return FormatDate;
    } catch (e) {
        return value
    }
}

const getStandardIds = (data) => {
    let stdIds = [];
    data && data.map(value => {
        value.arr.map(core => {
            return (stdIds.push(core.standard_id))
        })
        return stdIds;
    })
    return stdIds;
}

const getStandardIdsFourth = (data) => {
    let stdIds = [];
    data && data.map(value => {
        stdIds.push(value.sub_standard_id)
        return stdIds;
    })
    return stdIds;
}

const getClassCoreStandardIdsFourth = (data) => {
    let stdIds = [];
    data && data.map(value => {
        stdIds.push(value.sub_standard_id)
        return stdIds;
    })
    return stdIds;
}
const getClassCoreStandardIds = (data) => {
    let stdIds = [];
    data && data.map(value => {
        stdIds.push(value.standard_id)
        return stdIds;
    })
    return stdIds;
}

const addDaysGetSpan = (originalDate, days) => {
    const cloneDate = new Date(originalDate.valueOf());
    cloneDate.setDate(cloneDate.getDate() + days);
    return cloneDate;
}


const getRelativeDayInWeek = (d, dy) => {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : dy);
    return new Date(d.setDate(diff));
}

export const encryptData = (values) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(values),
      "chronicle_encrypt_key"
    ).toString();
  };

  export const decryptData = (values) => {
    return JSON.parse(
      CryptoJS.AES.decrypt(values, "chronicle_encrypt_key").toString(
        CryptoJS.enc.Utf8
      )
    );
  };

  export const encryptPasswordHandler = (value) => {
    return CryptoJS.AES.encrypt(
      value,
      "chronicle_encrypt_key"
    ).toString();
  }

  export const decryptPasswordHandler = (value) => {
    return CryptoJS.AES.decrypt(
      value,
      "chronicle_encrypt_key"
    ).toString(CryptoJS.enc.Utf8);
  }

  export const getFlooredFixedTwoDigit = (v, d) => {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}

export const checkArrayForChars = (array) => {
    for (let i = 0; i < array.length; i++) {
      const string = array[i];
      if (/[a-zA-Z]/.test(string)) {
        return true;
      }
    }
    return false;
  }

  export const getSharingCondition = (rosterData, classData) => {
    let isShared = false
    let sharedClassData = classData && classData.length > 0 && classData.filter((item) => {
        return item?.cc_class_share_permission == 'read' || item?.cc_class_share_permission == "collaborate"
    })
    let sharedRosterData = rosterData && rosterData.length > 0 && rosterData.filter((item) => {
        return item?.cc_student_share_permission == 'read' || item?.cc_student_share_permission == "collaborate"
    })
    if (sharedClassData?.length > 0 || sharedRosterData?.length > 0) {
        isShared = true;
      }
    return isShared
  }

  export function formatAudioDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    
    // Pad single-digit seconds with a leading zero
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${minutes}:${formattedSeconds}`;
  }

  export function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue;
  }
  
  export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  export function deleteCookie(userEmail, userPassword, rememberMe) {
    document.cookie =
      userEmail + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie =
      userPassword + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      rememberMe + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

export {
    getRandomName,
    getDateFormat,
    getDaysdiff,
    logOut,
    getStudentList,
    getRandomColor,
    getGroupLists,
    getFormattedDate,
    getStandardIds,
    getClassCoreStandardIds,
    getStandardIdsFourth,
    getClassCoreStandardIdsFourth,
    addDaysGetSpan,
    getRelativeDayInWeek
};
