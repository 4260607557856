import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import './NoteSelectClass.scss'
import { Button } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import eye from 'Assets/AttendanceModule/eye.svg'
import arrow from 'Assets/AttendanceModule/arrow.png';
import back from 'Assets/CommonComponent/back.png'
import search from 'Assets/AttendanceModule/search.png'
import SelectSearch from 'react-select-search';
import { getClassList } from 'Actions/ClassAction/classAction';
import * as routes from "Router/RoutesURL";
import { getGroupDetailsRequest } from 'Actions/NoteAction/groupDetailsAction';

const NoteSelectClass = (props) => {

    const {
        history,
        classData,
    } = props;
    console.log('hereprops',props)
    const classID = props.location.state.class_Data.cc_class_id
    const academicYearId = props.location.state.data.academic_year_id;
    const academicYear = props.location.state.data.academic_year
    const [openUserId, setOpenUserId] = useState();
    const [showParticularAtt, setShowParticularAtt] = useState(false);
    const [showRangeAtt, setShowRangeAtt] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [pageNo, setPageCheck] = useState(1);

    const classAtteContainer = ['classAttendanceContainer', openUserId ? 'eventNone' : ''];
    const link_type = props.location.state.linkType
    const currentYear = props.location.state.data.academic_year;
    useEffect(() => {
        sessionStorage.setItem("backpage2" , "smthelse")
        }, [])
    useEffect(() => {
        if (link_type === 'Group Notes') {
            let apiData = {
                // "class_id": getClassId,
                "pageNo": pageNo
            }
            getGroupDetailsRequest(apiData);
        }
    }, [classID])

    useEffect(() => {
        const data = props.location.state.data
        
        const value = {
            filter_by: 'both',
            // page_no: 'full',
            class_academic_year: data.year_ids
        }
        props.getClassList(value)
    }, [classID])
    const searchInput = useRef();
    const classDataList = classData.class?.class_Data?.data?.[0]
    const newClassDataList = classDataList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id,
            details: classDataList[i]
        }

    ));
    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ['details']: newClassDataList[i] }));

    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "" },],

        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };


    {/* ---------------function to redirect on Note Dashboard------------ */ }
    const handleChange = (value, ...args) => {
        
        sessionStorage.setItem('classIDForNotes',args[0].value)
        sessionStorage.setItem('classNameForNotes',args[0].name)
        {
            link_type === 'Group Notes' ?
                history.push(routes.CLASSADDGROUPNOTE, {
                    // class_id: classDetails.cc_class_id,
                    class_id: value,
                    pageNo: pageNo,
                    // academicYear: AcademicDetails.academic_year,
                    academicYear: currentYear,
                    classDetails: args[0],
                    ClassData: args.details,
                    selectedClass: args,
                    classListData: newArr,
                    academicYearId : props?.location?.state?.academicYearId
                })
                :
                history.push(routes.CLASSNOTE, {
                    Class_id: (value > 0 || value.length > 0) ? value : classID,
                    academicYearId: academicYearId,
                    academicYear: academicYear,
                    BackPage: 'Note Select Class',
                    ClassData: props.location.state.class_Data,
                    classDetail: args[0].value,
                    selectedClass: args,
                    classListData: newArr,
                })
        }
    };
    {/* ---------------function to redirect on Note Dashboard------------ */ }

    const BackTOHome = () => {
        history.goBack()
        setShowParticularAtt(false);
        setShowRangeAtt(false);
    }
    const BackToDash = () => {
        history.replace("/home/academic")
    }


    return (
        <div className='NoteSelectClassSCSS-NSC'>
            <div className={classAtteContainer.join(' ')}>
                {!showPreview && <div>
                    {!showParticularAtt && !showRangeAtt && (
                        <div className='ClassAttendanceMainDiv-NSC'>
                            <div className='classAttendaceDetailsMain-NSC'>
                                <div className='attClassBackDiv-NSC'>
                                    <img className='calIcon-NSC' src={back} alt='' width='60' height='40' onClick={BackTOHome} />
                                </div>
                                {link_type === 'Group Notes' ?
                                    <div className='attClassDetailTextDiv-NSC'>
                                        <p className='attClassName-NSC'></p>
                                        <div className='displayFlexClassRoster-NSC'>
                                            <p className='attClassGrade-NSC' onClick={() => { BackToDash() }}><b>{academicYear}</b></p>
                                            <i className="classForIcon-NSC material-icons">chevron_right</i>
                                            <p className='attClassGrade2-NSC'> Group Note</p>
                                        </div>
                                    </div> :
                                    <div className='attClassDetailTextDiv-NSC'>
                                        <p className='attClassName-NSC'></p>
                                        <div className='displayFlexClassRoster-NSC'>
                                            <p className='attClassGrade-NSC' onClick={() => { BackToDash() }}><b>{academicYear}</b></p>
                                            <i className="classForIcon-NSC material-icons">chevron_right</i>
                                            <p className='attClassGrade2-NSC'> Notes</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="note-select-dropdown-main-container-NSC">
                                <img src={search} alt='' className='searchImage' />
                                <SelectSearch
                                    ref={searchInput}
                                    options={options}
                                    filterOptions={handleFilter}
                                    value=""
                                    name="Workshop"
                                    placeholder="Search class*"
                                    search
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='buttonMarginTop-NSC'>
                                <Button className='viewNoteDiv-NSC' disabled={true}>
                                    <img className='viewAttIcon-NSC' src={eye} alt='' />
                                    <span className='viewAttText-NSC'> Report </span>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>}
                <div className='containerDiv-NSC'>
                    <img src={arrow} alt='' className='arrowImage-NSC' />
                    {link_type === 'Group Notes' ?
                        <div className='noteContainerTextDiv-NSC'>
                            <span className='containerText-NSC'>Please select class to view or create group notes</span>
                        </div> :
                        <div className='noteContainerTextDiv-NSC'>
                            <span className='containerText-NSC'>Please select class to view or create notes</span>
                        </div>}
                </div>
            </div >
        </div>
    )
}
const mapStateToProps = state => ({
    groupsData: state.addGroup.groupsData,
    state: state,
    classData: state,
    loader: state.class.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getGroupDetailsRequest: (data) => dispatch(getGroupDetailsRequest(data)),
        getClassList: (data) => dispatch(getClassList(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    NoteSelectClass
);