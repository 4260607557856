import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Range from '../RangeComponent/Range';
import Format from '../FormatComponent/Format';
import PropTypes from 'prop-types';
import { getFormattedDate } from "Utils/Helper";
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './SettingsDashboard.scss';
import tick from 'Assets/NoteModule/tick.svg';
import closeIcon from 'Assets/NoteModule/close.svg';


const SettingsDashboard = (props) => {
    const { getUserSettingRequest, updateUserSettingRequest, getUserSetting, updateUserSetting,  state } = props;
    const [defaultData, setdefaultData] = useState({});
    const [greenCheck, setGreenCheck] = useState(false);
    const [volume, setBVolumeCheck] = useState(7);
    const [volumeYellow, setBVolumeYellowCheck] = useState(14);
    const [showButton, setShowButton] = useState(false);
    const [filter] = useState(false)

    const handleOnChangeGreen = (value) => {
        setBVolumeCheck(value);
        setGreenCheck(true);
        setShowButton(true);
    }
    const handleOnChangeYellow = (value) => {
        setBVolumeYellowCheck(value);
        setGreenCheck(false);
        setShowButton(true);
    }
    const onSave = () => {
        let apiData = {
            "user_green_note_days":volume,
            "user_yellow_note_days":volumeYellow, 
            "user_date_format":defaultData.cc_user_date_format,
             "user_seperator":defaultData.cc_user_seperator,
              "user_clock_format":defaultData.cc_user_clock_format
            }
            // console.log(apiData)
            updateUserSettingRequest(apiData);
            setShowButton(false);
      
    }

    const onCancel = () => {
        
        setdefaultData(getUserSetting.data);
        setBVolumeCheck(Number(getUserSetting.data.cc_user_green_note_days));
        setBVolumeYellowCheck(Number(getUserSetting.data.cc_user_yellow_note_days));
        setShowButton(false);
    }
  
    useEffect(() => {
            if(getUserSetting && getUserSetting.status === 200 ){
                const defaultsData = getUserSetting.data;
                setdefaultData(defaultsData);
                setBVolumeCheck(Number(defaultsData.cc_user_green_note_days));
                setBVolumeYellowCheck(Number(defaultsData.cc_user_yellow_note_days));
    }
    },[getUserSetting])

    useEffect(() => {
            getUserSettingRequest();
    },[])

    useEffect(() => {
        if(updateUserSetting && updateUserSetting.status === 200){
        getUserSettingRequest();
    }
    },[updateUserSetting])
    

    const onChangeformat = (value) => {
        const updatedData = {...defaultData,cc_user_date_format: value};
        setShowButton(true);
        return( setdefaultData(updatedData) )
    }

    const onChangeSeparators = (value) => {
        const updatedData = {...defaultData,cc_user_seperator: value};
        setShowButton(true);
        return(
             setdefaultData(updatedData) )
    }

    const onChangeClock = (value) => {
        const updatedData = {...defaultData,cc_user_clock_format: value};
        setShowButton(true);
        return( setdefaultData(updatedData) )
    }

return (
        defaultData && <div className='settingsContainer'>
            {/* ------- Range for color ------- */}
            {state?.Setting?.loader ? <div> <DivLoader /> </div> : ""}
            <Card className='cardOfRange'>
                {showButton && <div>
                 {/* <Button onClick={onSave} variant="default" className="saveCloseBtn">
                    <img src={saveIcon} alt='' />
                </Button> */}
                <Button style={{background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform : 'capitalize'} } variant="default" className="action-btn saveCloseBtn" onClick={onSave}>
                                <img src={tick} alt='' />&nbsp;&nbsp; Save
                                {/* <img src={saveBtnIcon} alt='' /> */}
                            </Button>
                {/* <Button onClick={onCancel} variant="default" className="saveCloseBtn">
                    <img src={cancelIcon} alt='' />
                </Button> */}
                 <Button variant="default" className="action-btn saveCloseBtn"  onClick={onCancel}
                            style={{background : "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform : 'capitalize' }}
                            // disabled={quality_points == 0 ? true : false}
                            >
                                <img src={closeIcon} alt='' /> Cancel
                            </Button>
                </div>}
                <p className='cardHeading'>Note Status Settings</p>
                <Range
                    volume={volume}
                    volumeYellow={volumeYellow}
                    handleOnChangeYellow={handleOnChangeYellow}
                    handleOnChangeGreen={handleOnChangeGreen}
                    greenCheck={greenCheck}

                   
                />
            </Card>
            {/* ------- Range for color ------- */}

            {/* ------- Format for Date ------- */}
            {defaultData.cc_user_seperator &&  <Card className='cardOfDate'>
                <p className='cardHeading'>Select Date & Time Format</p>
                <Format
                 filter={filter} 
               defaultData={defaultData}
               getUserSetting={getUserSetting}
               onChangeformat={onChangeformat}
               onChangeSeparators={onChangeSeparators}
               onChangeClock={onChangeClock}
                 />
            </Card>}
            {/* ------- Format for Date ------- */}

        </div>
    )
}
SettingsDashboard.propTypes = {
    state: PropTypes.object,
    loader: PropTypes.bool,
    updateUserSettingRequest: PropTypes.func,
    getUserSettingRequest: PropTypes.func,
    getUserSetting: PropTypes.func,
    updateUserSetting: PropTypes.func
};
export default SettingsDashboard
