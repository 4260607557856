import { call, takeLatest, put } from 'redux-saga/effects';
import UserStudentNoteReducer from 'Redux/Reducers/NoteReducer/studentNoteReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    ADD_STUDENT_NOTE,
    EDIT_STUDENT_NOTE,
    GET_STUDENT_NOTE,
    DELETE_STUDENT_NOTE,
    GET_STUDENT_CLASSES,
    SET_STUDENT_ARTIFACT,
    SET_MEDIA_ATTACHMENT_ARTIFACT,
    ADD_STUDENT_MISCELLANEOUS,
    EDIT_STUDENT_MISCELLANEOUS,
    DELETE_STUDENT_MISCELLANEOUS,
    GET_GROUP_NOTE_STUDENTS,
    DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT,
    GET_AUDIO_FROM_VERTEX,
    GET_AUDIO_SUMMARY_FROM_VERTEX,
} from 'Actions/constants';
import {
    AddStudentNote_API,
    EditStudentNote_API,
    GetStudentNote_API,
    DeleteStudentNote_API,
    GetRosterEnrolled_API,
    SetStudentArtifact_API,
    SetMediaAttachmentArtifact_API,
    AddStudentMiscellaneous_API,
    EditStudentMiscellaneous_API,
    DeleteStudentMiscellaneous_API,
    GetStudentsForNote_API,
    DeleteGroupNoteFromSingleStudent_API,
    getAudioFromVertex_API,
    getAudioSummaryVertex_API
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';


export function* userStudentNoteModuleSaga() {
    yield takeLatest(ADD_STUDENT_NOTE, addStudentNote);
    yield takeLatest(EDIT_STUDENT_NOTE, editStudentNote);
    yield takeLatest(GET_STUDENT_NOTE, getStudentNote);
    yield takeLatest(DELETE_STUDENT_NOTE, deleteStudentNote);
    yield takeLatest(DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT, deleteGroupNoteFromSingleStudent);
    yield takeLatest(GET_STUDENT_CLASSES, getStudentClasses);
    yield takeLatest(SET_STUDENT_ARTIFACT, setStudentArtifact);
    yield takeLatest(SET_MEDIA_ATTACHMENT_ARTIFACT, setMediaAttachmentArtifact);
    yield takeLatest(ADD_STUDENT_MISCELLANEOUS, addStudentMiscellaneous);
    yield takeLatest(EDIT_STUDENT_MISCELLANEOUS, editStudentMiscellaneous);
    yield takeLatest(DELETE_STUDENT_MISCELLANEOUS, deleteStudentMiscellaneous);
    yield takeLatest(GET_GROUP_NOTE_STUDENTS, getGroupNoteStudents);
    yield takeLatest(GET_AUDIO_FROM_VERTEX, getAudioFromVertex);
    yield takeLatest(GET_AUDIO_SUMMARY_FROM_VERTEX, getAudioSummaryFromVertex);
}
// ---------- Add Student Note-----
function* addStudentNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = AddStudentNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": JSON.stringify(data.cc_user_id),
        // // "device_udid": sessionStorage.getItem('UDID'),
        // "class_id": JSON.stringify(action.data.class_id),
        // "attachment_details_array": action.data.attachment_details_array,
        // "customized_tag_array": action.data.customized_tag_array,
        // "customized_tag_list": JSON.stringify(action.data.customized_tag_list),
        // "linked_flag": action.data.linked_flag,
        // "note_reminder": action.data.note_reminder,
        // "note_reminder_date": action.data.note_reminder_date,
        // "note_student_level": action.data.note_student_level,
        // "note_student_marked": action.data.note_student_marked,
        // "note_student_score": action.data.note_student_score,
        // "student_list": action.data.student_list,
        // "work_assignment": action.data.work_assignment,
        // "platform" : "web"
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id || sessionStorage.getItem("classIDForNotes"),
        "attachment_details_array": action.data.attachment_details_array,
        "customized_tag_array": action.data.customized_tag_array,
        "customized_tag_list": `${action.data.customized_tag_list}`,
        "linked_flag": action.data.linked_flag,
        "note_reminder": action.data.note_reminder,
        "note_reminder_date": action.data.note_reminder_date,
        "note_student_level": action.data.note_student_level,
        "note_student_marked": action.data.note_student_marked,
        "note_student_score": action.data.note_student_score,
        "student_list": `${action.data.student_list}`,
        "work_assignment": action.data.work_assignment,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        console.log(MainDecode)
        if (MainDecode.status == 200) {
            yield put(UserStudentNoteReducer.addStudentNoteSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(UserStudentNoteReducer.resetStudentNotes());
            action.data.history.goBack()
            // action.data.history.push('/home/studentnote')
            // Window.location.href = '/home/studentnote'
        } else if (MainDecode.status == 400) {
            yield put(UserStudentNoteReducer.addStudentNoteFailure(MainDecode));
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(UserStudentNoteReducer.addStudentNoteFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.addStudentNoteFailure(false));

    }
}
// ---------- Add Student Note-----


// ---------- Edit Student Note-----
function* editStudentNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = EditStudentNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": `${action.data.class_id}`,
        "note_id": action.data.note_id,
        "attachment_details_array": action.data.attachment_details_array,
        "customized_tag_array": action.data.customized_tag_array,
        "customized_tag_list": `${action.data.customized_tag_list}`,
        "linked_flag": action.data.linked_flag,
        "note_reminder": `${action.data.note_reminder}`,
        "note_reminder_date": action.data.note_reminder_date,
        "note_student_level": action.data.note_student_level,
        "note_student_marked": action.data.note_student_marked,
        "note_student_score": action.data.note_student_score,
        "student_list": `${action.data.student_list}`,
        "work_assignment": action.data.work_assignment,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserStudentNoteReducer.editStudentNoteSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            action.data.history.goBack()


        } else if (MainDecode.status == 400 && MainDecode.data) {
            yield put(UserStudentNoteReducer.addStudentNoteFailure(MainDecode));
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false))
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(UserStudentNoteReducer.editStudentNoteFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.editStudentNoteFailure(false));

    }
}
// ---------- Edit Student Note-----

// ---------- Get Student Note-----
function* getStudentNote(action) {
    // Get class_id from action data or session storage
    const classID = action.data.class_id || Number(sessionStorage.getItem("classIDForNotes"));

    // Check if class_id is valid (not 0 or empty)
    if (!classID || classID === 0) {
        console.log("class_id is either 0 or empty, not making API call.");
        return; // Exit early if class_id is not valid
    }
    else {
        console.log('class_id is ei :>> ');
    }

    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = `${GetStudentNote_API}?user_id=${data.cc_user_id}&class_id=${classID}&academic_id=${action?.data?.academic_id}&student_id=${action.data.student_id}&page_no=${action.data.page_no}&limit=${action.data.limit || 10}&filter_by=${action.data.filter_by}&platform=web`;

    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "filter_by": action.data.filter_by,
        "page_no": action.data.page_no,
        "student_id": action.data.student_id,
        "class_id": action.data.class_id
    }

    console.log('called action payload apiStudentNotes :>> ', action.data.class_id);

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });

        let MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            yield put(UserStudentNoteReducer.getStudentNoteSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message);
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.getStudentNoteFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.getStudentNoteFailure(false));
    }
}

// ---------- Get Student Note-----



// ---------- Delete Student Note-----
function* deleteStudentNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = DeleteStudentNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "note_id": action.data.note_id,
        "class_id": JSON.stringify(action.data.class_id),
        "platform": "web"

    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.deleteStudentNoteSuccess(MainDecode));
            // createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.deleteStudentNoteFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.deleteStudentNoteFailure(false));

    }
}
// ---------- Delete Student Note-----

// -----------Delete Grroup Note single Studne------------
function* deleteGroupNoteFromSingleStudent(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = DeleteGroupNoteFromSingleStudent_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "note_id": action.data.note_id,
        // "class_id" : JSON.stringify(action.data.class_id),
        "platform": "web"

    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.deleteGroupNoteFromSingleStudentSuccess(MainDecode));
            // createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.deleteGroupNoteFromSingleStudentError(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.deleteGroupNoteFromSingleStudentError(false));

    }
}
// -----------Delete Group Note Single Student-------


// ---------- Get Student Classes if From Roster----
function* getStudentClasses(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    yield put(UserLoadingReducer.loading(true));
    // const URL = GetRosterEnrolled_API;
    const URL = `${GetRosterEnrolled_API}?user_id=${data.cc_user_id}&student_id=${action.data.student_id}&academic_id=${action?.data?.academic_id}&page_no=${action.data.page_no}&sort_by=sort_by_none&platform=web`;
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "page_no": action.data.page_no,
        "sort_by": "sort_by_none"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        let MainDecode = decryptData(response.res)
        yield put(UserLoadingReducer.loadingSuccess(false));
        if (MainDecode.status === 200) {
            if (MainDecode.data.length > 0) {
                let data = {
                    "filter_by": action.data.filter_by,
                    "page_no": action.data.page_no,
                    "student_id": action.data.student_id,
                    "academic_id": action?.data?.academic_id
                }
                let setId = '';
                if (action.data.pageBYClass) {
                    setId = action.data.rosterClassId
                } else {
                    setId = sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : MainDecode.data[0].cc_class_id
                }
                data.class_id = setId
                console.log('called from here :>> ', data.class_id);
                yield put(UserStudentNoteReducer.getStudentNote(data));
            } else {
                createNotification('error', "The student is not enrolled in any of the Classes.");
            }
            yield put(UserStudentNoteReducer.getStudentClassesSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.getStudentClassesFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLoadingReducer.loadingSuccess(false));
        yield put(UserStudentNoteReducer.getStudentClassesFailure(false));

    }
}
// ---------- Get Student Classes if From Roster----



// ---------- Set Check/Uncheck Artified-----
function* setStudentArtifact(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = SetStudentArtifact_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_details_array": action.data.student_details_array,
        "class_id": action.data.class_id,
        // "student_marked" : action.data.student_details_array[0].student_marked,
        // "student_attachment_id": `${action.data.student_attachment_id}`,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.setStudentArtifactSuccess(MainDecode));
            createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.setStudentArtifactFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.setStudentArtifactFailure(false));

    }
}
// ---------- Set Check/Uncheck Artified-----


// ---------- Set Check/Uncheck Media Artified -----------
function* setMediaAttachmentArtifact(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = SetMediaAttachmentArtifact_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "attachment_marked_artified": action.data.cc_media_mark_artified,
        "cc_attachment_id": `${action.data.cc_attachment_id}`,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            console.log('Reducer :>> ' , data);
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.setMediaAttachmentArtifactSuccess(MainDecode));
            createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.setMediaAttachmentArtifactFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.setMediaAttachmentArtifactFailure(false));

    }
}
// ---------- Set Check/Uncheck Media Artified-----

// ---------- Add Miscellaneous-----
function* addStudentMiscellaneous(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = AddStudentMiscellaneous_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": JSON.stringify(data.cc_user_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": `${action.data.class_id}`,
        "misc_note": action.data.misc_note,
        "student_id": JSON.stringify(action.data.student_id),
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.addStudentMiscellaneousSuccess(MainDecode));
            // createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.addStudentMiscellaneousFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.addStudentMiscellaneousFailure(false));

    }
}
// ---------- Set Miscellaneous-----


// ---------- Edit Miscellaneous-----
function* editStudentMiscellaneous(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = EditStudentMiscellaneous_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "misc_note": action.data.misc_note,
        "misc_note_id": `${action.data.misc_note_id}`,
        "class_id": `${action.data.class_id}`,

        "student_id": `${action.data.student_id}`,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.editStudentMiscellaneousSuccess(MainDecode));
            createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.editStudentMiscellaneousFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.editStudentMiscellaneousFailure(false));

    }
}
// ---------- Edit Miscellaneous-----


// ---------- Delete Miscellaneous-----
function* deleteStudentMiscellaneous(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = DeleteStudentMiscellaneous_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": JSON.stringify(data.cc_user_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": `${action.data.class_id}`,
        "student_id": `${action.data.student_id}`,
        "misc_note_id": `${action.data.misc_note_id}`,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id,
                "academic_id": action?.data?.academic_id
            }
            yield put(UserStudentNoteReducer.getStudentNote(data));
            yield put(UserStudentNoteReducer.deleteStudentMiscellaneousSuccess(MainDecode));
            // createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.deleteStudentMiscellaneousFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.deleteStudentMiscellaneousFailure(false));

    }
}
// ---------- Delete Miscellaneous-----


// ---------- get Group Note Students-----
function* getGroupNoteStudents(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = GetStudentsForNote_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "note_id": action.data.note_id,
        "platform": "web"

    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        let MainDecode = JSON.parse(atob(response));
        if (MainDecode.status === 'Success') {

            yield put(UserStudentNoteReducer.getGroupNoteStudentsSuccess(MainDecode));


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.getGroupNoteStudentsFailure(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.getGroupNoteStudentsFailure(false));

    }
}
// ---------- get Group Note Students-----

// vertext audio
function* getAudioFromVertex(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = getAudioFromVertex_API;
    const BODY = {
        "class_id": `${action?.data?.class_id}`,
        "audio_url": action?.data?.audio_url,
        "user_id": `${data.cc_user_id}`,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {

            yield put(UserStudentNoteReducer.getAudioFromVertexSuccess(MainDecode));


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.getAudioFromVertexError(MainDecode));



        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.getAudioFromVertexError(false));

    }
}


function* getAudioSummaryFromVertex(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = getAudioSummaryVertex_API;
    const BODY = {
        "text_to_be_summerized": action?.data?.text_to_be_summerized,
        // "text_to_be_summerized": 'the process by which green plants and some other organisms use sunlight to synthesize nutrients from carbon dioxide and water. Photosynthesis in plants generally involves the green pigment chlorophyll and generates oxygen as a by-product.',
        "user_id": `${data.cc_user_id}`,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res)
        
        if (MainDecode.status === 200) {

            yield put(UserStudentNoteReducer.getAudioSummaryFromVertexSuccess(MainDecode));


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentNoteReducer.getAudioSummaryFromVertexError(MainDecode));



        }
    } catch (error) {
        
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentNoteReducer.getAudioSummaryFromVertexError(false));

    }
}






