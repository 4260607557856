import React, { Component } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import StudentNoteCommentCardComponent from '../StudentNoteCommentCardComponent/StudentNoteCommentCard';
import playIcon from 'Assets/NoteModule/play.svg';
import note_pdf from 'Assets/NoteModule/note_pdf.png';
import note_doc from 'Assets/NoteModule/note_doc.png';
import note_csv from 'Assets/NoteModule/note_csv.png';
import note_ppt from 'Assets/NoteModule/note_ppt.png';
import note_xls from 'Assets/NoteModule/note_xls.png';
import usersGroupIcon from 'Assets/NoteModule/usersGroup.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import pencilIcon from 'Assets/NoteModule/pencil-alt.png';
import trashIcon from 'Assets/NoteModule/trash-alt.png';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import * as routes from "Router/RoutesURL";
import { getFormattedDate } from "Utils/Helper";
import { getGroupNoteStudents } from 'Actions/NoteAction/studentNoteAction';
import warningIcon from "Assets/NoteModule/warning-icon.png";
import "./StudentNoteExpandedTimeline.scss";
import addNoteIcon from 'Assets/NoteModule/plusNew.svg';
import styled from 'styled-components';
import starYellow from 'Assets/NoteModule/starYellow.svg';
import starFilled from 'Assets/NoteModule/starFilled.svg';
import remiderIconPink from 'Assets/NoteModule/remiderIconPink.svg';
import RemiderIconGrey from 'Assets/NoteModule/RemiderIconGrey.svg';
import GroupNotes from 'Assets/NoteModule/GroupNotes.svg';
import IconButton from '@mui/material/IconButton';
import {

  setMediaAttachmentArtifact
} from 'Actions/NoteAction/studentNoteAction';

class StudentNoteExpandedTimeline extends Component {
  constructor(props) {
    super(props);
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    this.state = {
      show: false,
      MoreNotesloader: false,
      popoverOpen: false,
      selectedIndex: '',
      isWorkingForGroup: false,
      studentGroup: [],
      formatDate: formatDate,
      signDate: signDate,
      showEditModalHandler: false,
      studentData : null
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);

  }
  // ---------componentWillReceiveProps-------
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { NoteData } = this.props
    if (nextProps.studentData) {
      let AllData = NoteData;
      AllData[this.state.selectedIndex].groupArray = nextProps.studentData;
      this.props.GropuStudentNotes(AllData)
      this.setState({
        studentGroup: nextProps.studentData,
        isWorkingForGroup: false,
      })
    }

  }
  // ---------componentWillReceiveProps-------

  // ---------hide Misellane --------
  handleClose = () => {
    this.setState({ show: false })
  }
  // ---------hide Misellane --------

  // ---------loadMore Note --------
  loadMore = () => {
    this.setState({
      MoreNotesloader: true
    })
    this.props.loadMore()
  }
  // ---------show Misellane --------

  // ---------show Misellane --------
  handleShow = () => {
    this.setState({ show: true })
  }
  // ---------show Misellane --------

  // ---------Edit Misellane --------
  editMiscellaneous = (data, index) => {
    const value = {
      data: data,
      index: index
    }
    this.props.editMiscellaneous(value)
  }
  // ---------Edit Misellane --------


  // ---------Delete Misellane --------
  deleteMiscellaneous = (data, index) => {
    const value = {
      data: data,
      index: index
    }
    this.props.deleteMiscellaneous(value)
  }
  // ---------Delete Misellane --------

  // ---------Delete Student Note --------
  deleteStudentNote = (data, index) => {
    const value = {
      data: data,
      index: index
    }
    this.props.deleteStudentNote(value)
  }
  // ---------Delete Student Note --------

  // ---------Set Artifact Check/Uncheck --------
  setArtifact = (item, index) => {
    const data = {
      index,
      student_Note_details: item
    }
    this.props.setArtifact(data)
  }
  // ---------Set Artifact Check/Uncheck --------


  // ---------OpenAttachementModal --------
  OpenAttachementModal = (value) => {
    this.props.OpenAttachementModal(value)
  }
  // ---------OpenAttachementModal --------
  setMediaArtifact = (subItem, noteIndex) => {
    console.log('Setting media artifact for: ', subItem);
    console.log('this.props here reducer :>> ', this.props.studentInfo);
    const { location = {} } = this.props;
    const { state = {} } = location;
    const { studentInfo = '' } = state;
    const { class_id, filter_by } = this.state;
  
    // Retrieve current NoteData from state (or props)
    let updatedNoteData = [...this.props.NoteData];
  
    // Find the specific note based on the index
    const noteItem = updatedNoteData[noteIndex];
  
    if (noteItem) {
      // Map through note_attachment to update the specific media item
      const updatedAttachments = noteItem.note_attachment.map((attachment) => {
        if (attachment.cc_attachment_id === subItem.cc_attachment_id) {
          return { ...attachment, cc_media_mark_artified: attachment.cc_media_mark_artified === 'yes' ? 'no' : 'yes' };
        } else {
          return attachment;
        }
      });
  
      // Update the note with the modified attachments
      updatedNoteData[noteIndex].note_attachment = updatedAttachments;
  
      // Set the new NoteData in state
      this.setState({
        NoteData: updatedNoteData
      });
     
      // Prepare the API call data
      const apiData = {
        "cc_attachment_id": subItem.cc_attachment_id,
        "cc_media_mark_artified": subItem.cc_media_mark_artified === 'yes' ? 'no' : 'yes',
        "class_id": this.props.class_id,
        "student_id": this.props.studentInfo?.cc_student_id,
        "filter_by": "both",
        "page_no": 1,
        "academic_id": sessionStorage.getItem("selectedAcademicYear")
      };
  
      // Call the API to update media artifact
      this.props.setMediaAttachmentArtifact(apiData);
    }
  };
  

  // ---------OpenAttachementModal --------
  OpenDocAttachementModal = (value) => {
    this.props.OpenDocAttachementModal(value)
  }
  // ---------OpenAttachementModal --------


  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen })
  }

  // ------Students of Note-----
  studentsOfNote = (value, i) => {
    if (value.cc_group_arr.length > 0) {
      this.setState({
        studentGroup: value.cc_group_arr,
        selectedIndex: i
      })

    }
    else {
      this.setState({
        studentGroup: [],
        selectedIndex: i,
        isWorkingForGroup: true
      })
      const data = {
        "note_id": value.cc_note_id
      }
      this.props.getGroupNoteStudents(data)
    }
  }
  // ------Students of Note-----

  editStudentTimelineHandler = (item) => {
    console.log(item)
    console.log(this.props)
    if(item?.cc_note_batch_ids?.length > 1 && item?.cc_note_batch_ids?.includes(',')) {
      this.setState({
        showEditModalHandler: true,
        studentData : item
      })
    } else {
      this.props.props.history.push(routes.STUDENTADDNOTE, {
        pageType: "Edit",
        data: item,
        studentInfo: this.props.studentInfo,
        academicYear: this.props.academicYear,
        student_enrolled_classes:
          this.props.student_enrolled_classes,
        NotesData: this.props.NoteData,
        StudentAllNoteDetails: this.props.StudentAllNoteDetails,
        class_id: this.props.class_id,
        directAdd: "directAdd",
        classList: this.state.newClassListData,
        studentIdsList: item?.cc_student_arr,
        noteBatchIds: item?.cc_note_batch_ids
      });
    }
  }

  studentnoteaddHandler = () => {
    console.log(this.props)
    this.props.RedirectAddNoteHandler(this.state.studentData)
  }

  // --------------------Custom Button ------------------
  RedButton = styled(Button)({
    color: "#FF6A6A",
    backgroundColor: "#FF6A6A1A",
    borderColor: "#FF6A6A",
    borderRadius: "8px",
    marginLeft: "10px",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#FF6A6A1A",
      borderColor: "#FF6A6A",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
      startIcon: <img src={starYellow} alt="" width="14" height="14" />
    }
  })

  PinkButton = styled(Button)({
    color: "#BD47E8",
    backgroundColor: "#BD47E81A",
    borderColor: "#BD47E8",
    borderRadius: "8px",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#BD47E81A",
      borderColor: "#BD47E8",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
    },
    textTransform : 'capitalize',
  })
  GreyButton = styled(Button)({
    color: "#AAAAAA",
    backgroundColor: "#AAAAAA1A",
    borderColor: "#AAAAAA",
    borderRadius: "8px",
    marginLeft: "10px",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#AAAAAA1A",
      borderColor: "#AAAAAA",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
      startIcon: <img src={starYellow} alt="" width="14" height="14" />
    },
    textTransform : 'capitalize',
  })
  // --------------------Custom Button ------------------

  render() {
    const { NoteData,
      TotalCount,
      props,
      studentInfo,
      academicYear,
      student_enrolled_classes,
      StudentAllNoteDetails,
      class_id,
      GroupNotesLoader,
      classPermission } = this.props;
    const RedButton = this.RedButton
    const PinkButton = this.PinkButton
    const GreyButton = this.GreyButton
    let { MoreNotesloader, studentGroup } = this.state;
    

    const popover = (
      <Popover id="assign-popover-box">
        {GroupNotesLoader ?
          <img src={loaderImag} alt='' className='loaderGroupIcon' />
          :
          <Popover.Content className="p-3">
            {studentGroup && studentGroup.length > 0 && studentGroup.map((item, index) => <div key={index}>{item} <br /> </div>)}
          </Popover.Content>
        }
      </Popover>
    );

    return (
      <>
        <div className="miscellaneous-div">
          {classPermission !== null && classPermission === 'read' ? <Button className="disabledAdd-mili-note-btn" style={{textTransform : 'capitalize'}}><img src={addNoteIcon} alt='' className='add-note-img'  />Miscellaneous Note</Button> :
            <Button className="add-mili-note-btn add-mili-note-btn-font-expand" onClick={this.props.handleShow} style={{textTransform : 'capitalize'}}><img src={addNoteIcon} alt='' className='add-note-img'/>Miscellaneous Note</Button>}
          {classPermission !== null && classPermission === 'read' ? <div className="btn-element-content-disabledArrow"></div> :
            <div className="btn-element-content-arrow"></div>}
          <div className="btn-element-icon"></div>
        </div>
        <VerticalTimeline layout="1-column" className="expanded-timeline-section">
          {NoteData && NoteData.map((item, i) => {
            console.log('NoteData :>> ', NoteData);
            return (
              <div className="verticalTimelineExpanded" key={i}>
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  date={item.cc_note_show_type === "note" ? (item.cc_linked_flag === 1 ? getFormattedDate(item.cc_note_add_on, this.state.formatDate, this.state.signDate) + " Shared" : getFormattedDate(item.cc_note_add_on, this.state.formatDate, this.state.signDate)) : getFormattedDate(item.cc_misc_note_add_on, this.state.formatDate, this.state.signDate) + ", Miscellaneous Note"}
                >
                  {item.cc_note_show_type === "note" ?
                    /* -----------Norma/ Note-------- */
                    <div>

                      {/* -----------Header of View-------- */}
                      <Row className="timeline-section">
                        <Col xs="3" md="4" className="text-left pr-0">
                          {classPermission !== null && classPermission === 'read' ? <i className={item.cc_note_mark_artified === 'no' ? "fa fa-star-o disabledStarUnSelected" : "fa fa-star disabledStarSelected"} aria-hidden="true"></i> :
                            <i className={item.cc_note_mark_artified === 'no' ? "fa fa-star starUnSelected" : "fa fa-star starSelected"} aria-hidden="true" onClick={() => { this.setArtifact(item, i) }}></i>}
                          {item.cc_note_show_type !== "note" &&
                              <RedButton variant="outlined" size="small" className='redButton'>
                                  <img src={GroupNotes} alt='' className='historyIcon' />
                                  <span className='GroupText'>Group</span>
                              </RedButton>
                          }

                          {item.cc_note_batch_ids  && item.cc_note_batch_ids.length > 0 && <>
                            {item.cc_note_batch_ids.includes(",") && <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                              <img onClick={() => { this.studentsOfNote(item, i) }} className="usersGroupIcon" src={usersGroupIcon} alt='' width="25" />
                            </OverlayTrigger>}
                          </>}
                        </Col>
                        <Col xs="9" md="4" className="text-center pl-0 pr-0">
                          {item.cc_note_reminder === null || item.cc_note_reminder === ''  ?
                            <GreyButton variant="outlined" size="small" className='greyButton'>
                              <img src={RemiderIconGrey} alt='' className='historyIcon' />
                              <span className="no-reminder">No Reminder</span>
                            </GreyButton> :
                            <PinkButton variant="outlined" size="small" className='pinkButton'>
                              <img src={remiderIconPink} alt='' className='historyIcon' />
                              <span className="timeline-time">{getFormattedDate(item.cc_note_reminder_date, this.state.formatDate, this.state.signDate)}{" "}{item.cc_note_reminder === '' || !item.cc_note_reminder || item.cc_note_reminder == 'null' ? '' : item.cc_note_reminder}</span>
                            </PinkButton>}
                        </Col>
                        <Col xs="12" md="4" className="text-right attachment-text pl-0">
                          <p className="power-points"><span className="powerPoint">Power Points:</span>{" "}{item.cc_note_quality_points > 10 ? 10 : item.cc_note_quality_points}
                            {classPermission !== null && classPermission === 'read' ? <IconButton variant="primary"
                              className="disabledEdit-btn" 
                              // sx={{ width: "15px" }}
                              >
                              <img src={pencilIcon} alt='' className='disabledEditTime-btn-img' />
                              <span className="edit-btn-text-SNETL">Edit</span>
                            </IconButton> :
                              <IconButton variant="primary"
                                // sx={{ width: "15px" }}
                                onClick={() => {
                                  this.editStudentTimelineHandler(item)
                                }} className="edit-btn-SNETL">
                                <img src={pencilIcon} alt='' className='timeline-btn-img' />
                                <span className="edit-btn-text-SNETL">Edit</span>
                              </IconButton>
                            }
                            {classPermission !== null && classPermission === 'read' ?
                              <IconButton variant="primary" size="small" className="disabledTrash-btn-SNETL br-8" 
                              sx={{ width: "15px" }}
                              >
                                <img src={trashIcon} alt='' className='timeline-btn-img' />
                                <span className="edit-btn-text-SNETL">Delete</span>
                              </IconButton> :
                              <IconButton onClick={() => { this.deleteStudentNote(item, i) }} variant="primary" size="small" className="trash-btn-SNETL br-8"
                              //  sx={{ width: "15px" }}
                               >
                                <img src={trashIcon} alt='' className='timeline-btn-img' />
                                <span className="edit-btn-text-SNETL">Delete</span>
                              </IconButton>}
                          </p>
                        </Col>
                      </Row>
                      {/* -----------Header of View-------- */}

                      <Row className="divider">
                        <Col xs="12">
                          <hr></hr>
                        </Col>
                      </Row>

                      {/* -----------Video/Score Notes View-------- */}
                      <Row className="timeline-section">
                        <Col xs="6" className="text-left attachment-text">
                          {item.note_attachment.length === 0 && "No "}Attachments
                        </Col>
                        <Col xs="6" className="text-right">
                          <span>
                            <span className="level-text-b">Level:</span>
                            <span className="level-text-n"> {item.cc_note_level === '' ? "-" : item.cc_note_level}</span>
                          </span>
                          <span className="vertical-divider"></span>
                          <span>
                            <span className="level-text-b">Score:</span>
                            <span className="level-text-n"> {item.cc_note_score === '' ? "-" : item.cc_note_score}</span>
                          </span>
                        </Col>
                        <Col xs="12" className="text-left mt-2">
                          {item.note_attachment && item.note_attachment.length > 0 && <div className="row timeline-attchements">
                            {item.note_attachment.map((subItem, index) => {
                              
                              return (
                                subItem.cc_attachment_type !== "other" ? <div key={index} onClick={() => { this.OpenAttachementModal(subItem) }} style={{ cursor: "pointer", marginRight: 8, marginBottom: 8 }}>
                                  {subItem.cc_attachment_type !== "audio" &&
                                    <div className="col-xs-3 note" style={{ backgroundImage: `url(${subItem.cc_attachment_fullurl})`, position : 'relative' }}>
                                       {subItem.cc_attachment_type == "image" && subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                      {subItem.cc_attachment_type !== "image" && (
                                        <div className="img-div">
                                          <img src={playIcon} className="playIcon" alt='' width="18" height="18" />
                                          {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                        </div>
                                      )}
                                    </div>}
                                </div> :
                                  <div key={index} onClick={() => { this.OpenDocAttachementModal(subItem) }} style={{ cursor: "pointer", marginRight: 8, marginBottom: 8 }}>
                                    {subItem.cc_attachment_url.split(".").pop() === "pdf" && <div className="imageArti">
                                      <img src={note_pdf} alt="" width="60" height="60" />
                                      {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                    </div>}
                                    {subItem.cc_attachment_url.split(".").pop() === "doc" || subItem.cc_attachment_url.split(".").pop() === "docx" ? <div className="imageArti">
                                      <img src={note_doc} alt="" width="60" height="60" />
                                      {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                    </div> : ""}
                                    {subItem.cc_attachment_url.split(".").pop() === "ppt" || subItem.cc_attachment_url.split(".").pop() === "pptx" ? <div className="imageArti">
                                      <img src={note_ppt} alt="" width="60" height="60" />
                                      {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                    </div> : ""}
                                    {subItem.cc_attachment_url.split(".").pop() === "xls" || subItem.cc_attachment_url.split(".").pop() === "xlsx" ? <div className="imageArti">
                                      <img src={note_xls} alt="" width="60" height="60" />
                                      {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                    </div> : ""}
                                    {subItem.cc_attachment_url.split(".").pop() === "csv" && <div className="imageArti">
                                      <img src={note_csv} alt="" width="60" height="60" />
                                      {subItem.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                    </div>}
                                  </div>
                              )
                            })}
                          </div>}
                        </Col>
                      </Row>
                      {/* -----------Video/Score Notes View--------- */}

                      <Row className="divider">
                        <Col xs="12">
                          <hr></hr>
                        </Col>
                      </Row>

                      {/* -----------Audio Notes View--------- */}
                      <Row className="timeline-section audio-section">
                        <Col xs="6" className="text-left attachment-text">
                          {item.note_attachment.length === 0 && "No "}Audio Notes
                        </Col>
                        {item.note_attachment && item.note_attachment.length > 0 && <Col xs="12" className="text-left mt-2">
                          {item.note_attachment.map((subItem, index) => (
                            <div key={index}>
                              {subItem.cc_attachment_type === "audio" &&
                                <div key={index} className="audio-style">
                                  
                                  <p style={{marginLeft : "-3px"}} className="audio-title d-flex">
                                  <i style={{display : "flex" , justifyContent : "center" , alignItems : "center" , marginRight : "6px"}} className={subItem.cc_attachment_type === "audio" &&
            subItem.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} aria-hidden="true"  onClick={() => { this.setMediaArtifact(subItem, i) }}></i>
          {/* {subItem.cc_attachment_type === "audio" &&
            subItem.cc_media_mark_artified === "yes" ? (
              <img
              role="button"
                onClick={() => { this.setMediaArtifact(subItem, i) }}
                style={{ marginRight: "5px" }}
                src={starFilled}
                alt=""
              />
          ) :  <img
          role="button"
          onClick={() => { this.setMediaArtifact(subItem, i) }}
          style={{ marginRight: "5px" }}
          src={starYellow}
          alt=""
        /> } */}
          {(subItem.cc_attachment_name === '' || subItem.cc_attachment_name ==  null) ? 'Audio Note' : subItem.cc_attachment_name}
        </p>
                                  <audio controls className="player" preload="false" controlsList="nodownload" src={subItem.cc_attachment_fullurl}></audio>
                                </div>
                              }
                            </div>
                          ))}
                        </Col>}
                      </Row>
                      {/* -----------Audio Notes View-------- */}

                      <Row className="divider">
                        <Col xs="12">
                          <hr></hr>
                        </Col>
                      </Row>

                      {/* -----------Student Note CommentCard Component View-------- */}
                      {item.note_tag_comments && item.note_tag_comments.length > 0 &&
                      //  item.note_tag_comments.map((subItem, idx) => {
                      //   console.log('subItem',subItem)
                      //   if(subItem.tag_comment_text.length > 0) {
                      //   return (
                      //     <>
                      //      <StudentNoteCommentCardComponent
                      //     type={'view'}
                      //     allData={subItem}
                      //     studentInfo={studentInfo}
                      //     // CommentData={newArr}
                      //     CommentData={item.note_tag_comments}
                      //     StudentAllNoteDetails={StudentAllNoteDetails}
                      //     width="6" />
                      //     </>
                      //   )
                      //   }
                      // })
                        <StudentNoteCommentCardComponent
                          type={'view'}
                          allData={item}
                          studentInfo={studentInfo}
                          // CommentData={item.note_tag_comments}
                          CommentData={(() => {
                            // Retrieve comments from sessionStorage or state
                            const comments = item?.note_tag_comments;
                            
                            // Filter and prioritize "Audio Summarization" and "Audio Transcription"
                            const audioComments = comments?.filter(comment => 
                              comment?.cc_tag_title === "Audio Summarization" || comment?.cc_tag_title === "Audio Transcription"
                            );
                            const otherComments = comments?.filter(comment => 
                              comment?.cc_tag_title !== "Audio Summarization" && comment?.cc_tag_title !== "Audio Transcription"
                            );
                            
                            // Combine prioritized comments with the rest
                            return [...audioComments, ...otherComments];
                          })()}
                          StudentAllNoteDetails={StudentAllNoteDetails}
                          width="6" />
                      }
                      {/* -----------Student Note CommentCard Component View-------- */}
                    </div> :
                    /* -----------Norma/ Note-------- */

                    /* -----------Miscellaneous Note-------- */
                    <div >
                      <Row className="timeline-section">
                        <Col xs="6" className="text-left attachment-text">
                          Miscellaneous Note
                        </Col>
                        <Col xs="6" className="text-right attachment-text">
                          <Button onClick={() => { this.editMiscellaneous(item, i) }} variant="primary" className="edit-btn br-8"
                           disabled={classPermission !== null && classPermission === "read" ? true : false}
                           style={{opacity : classPermission !== null && classPermission === "read" && "0.65"}}
                          >
                            <img src={pencilIcon} alt='' className='timeline-btn-img' />
                            <span className="edit-btn-text-SNETL">Edit</span>
                          </Button>
                          <Button onClick={() => { this.deleteMiscellaneous(item, i) }} variant="primary" className="trash-btn br-8"
                           disabled={classPermission !== null && classPermission === "read" ? true : false}
                           style={{opacity : classPermission !== null && classPermission === "read" && "0.65"}}
                          >
                            <img src={trashIcon} alt='' className='timeline-btn-img' />
                            <span className="edit-btn-text-SNETL">Delete</span>
                          </Button>
                        </Col>
                      </Row>

                      <Row className="divider">
                        <Col xs="12">
                          <hr></hr>
                        </Col>
                      </Row>

                      <Row className="timeline-section">
                        <Col xs="12" className="text-left attachment-text attachment-text-misc">
                          {item.cc_misc_note_text}
                        </Col>
                      </Row>

                    </div>
                    /* -----------Miscellaneous Note-------- */
                  }
                </VerticalTimelineElement>
              </div>
            );
          })}
        </VerticalTimeline>
        <p style={{textAlign : "center"}} className="note-header-total-std-title"> You have {this.props?.StudentAllNoteDetails?.data?.total_note} {this.props?.StudentAllNoteDetails?.data?.total_note > 1 ? "Notes" : "Note"} and <br />
            {this.props?.StudentAllNoteDetails?.data?.total_misc} Misc {this.props?.StudentAllNoteDetails?.data?.total_misc > 1 ?"Notes" : "Note"} -- Keep Going </p>
        {TotalCount > NoteData.length &&
          <div className="sort-miscellaneous-div load-more">
            {MoreNotesloader ?
              <img src={loaderImag} alt='' className='loaderIconCss' />

              :
              <Button className="add-mili-note-btn add-load-more" onClick={() => { this.loadMore() }} >
                <i className="material-icons">autorenew</i>
                <span>Load More</span>
              </Button>}
          </div>}



        {/* -----------Modal For View Attachement-------- */}
        <Modal className="play-video-modal br-8" show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <video width="750" height="400" style={{ border: "12px solid #e9e9e9" }} controls src="https://www.w3schools.com/html/movie.mp4"></video>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              <img src={closeIcon} alt='' />
            </Button>
          </Modal.Footer>
        </Modal>
        {/* -----------Modal For View Attachement-------- */}


        <Modal centered className="delete-modal-warning br-8 studentnotetimelinemodalContent" show={this.state.showEditModalHandler}>
                    <Modal.Header>
                        <Modal.Title>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  WAIT!
                </span>
              </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {<p className="confirm-text font-weight-bold">Any changes you make to this note will affect all of the students in this group, Unless you create a new note particularly for this student.</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn createNewNoteBtnGotIt" onClick={() => {
                            this.setState({
                                // ConfirmationType: '',
                                showEditModalHandler: false,
                                // noteContainMedia: false
                            })
                            console.log(this.state)
                            this.props.props.history.push(routes.STUDENTADDNOTE, {
                              pageType: "Edit",
                              data: this.state.studentData,
                              studentInfo: this.props.studentInfo,
                              academicYear: this.props.academicYear,
                              student_enrolled_classes:
                                this.props.student_enrolled_classes,
                              NotesData: this.props.NoteData,
                              StudentAllNoteDetails: this.props.StudentAllNoteDetails,
                              class_id: this.props.class_id,
                              directAdd: "directAdd",
                              classList: this.state.newClassListData,
                              studentIdsList: this?.state?.studentData?.cc_student_arr,
                              noteBatchIds: this?.state?.studentData?.cc_note_batch_ids
                            });
                        }}
                        style={{width : 215}}
                        >
                            Ok, Got it
                        </Button>
                        <Button variant="default" className="delete-btn BR-8 createNewNoteBtn" onClick={() => { this.studentnoteaddHandler() }} style={{width : 215}}>
                            Create A New Note
                        </Button>
                    </Modal.Footer>
                </Modal>       



      </>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
  studentData: state.studentNote.students_List_Note,
  GroupNotesLoader: state.studentNote.GroupNotesLoader
});
const mapDispatchToProps = (dispatch) => {
  return {

    getGroupNoteStudents: (data) => dispatch(getGroupNoteStudents(data)),
    setMediaAttachmentArtifact: (data) => dispatch(setMediaAttachmentArtifact(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentNoteExpandedTimeline);