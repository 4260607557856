import React, { Component } from "react";

class TranscribeNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedText: null,
      showPopover: false,
      popoverPosition: { top: 0, left: 0 },
    };

    this.handleSelection = this.handleSelection.bind(this);
    this.handleHighlight = this.handleHighlight.bind(this);
  }

  componentDidMount() {
    document.addEventListener("selectionchange", this.handleSelection);
  }

  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.handleSelection);
  }

  handleSelection() {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
        console.log(30, rect)
        console.log(31, rect.top + window.scrollY, window.scrollX,window.scrollY)
      this.setState({
        selectedText: selection.toString(),
        showPopover: true,
        popoverPosition: {
          top: window.scrollY,
          left: window.scrollX
        //   left: rect.left,
        },
      });
    } else {
      this.setState({
        selectedText: null,
        showPopover: false,
      });
    }
  }

  handleHighlight() {
    console.log("Selected Text:", this.state.selectedText);
    this.setState({
      showPopover: false,
    });
  }

  render() {
    const { showPopover, popoverPosition } = this.state;
    const {audioTranscribe} = this.props
    console.log(audioTranscribe)

    return (
      <div style={{position : 'relative'}}>
        {showPopover && (
          <div
            style={{
              position: "absolute",
              top: popoverPosition.top,
              left: popoverPosition.left,
              padding: "10px",
              background: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              zIndex: 999,
            }}
          >
            <button onClick={this.handleHighlight}>Highlight</button>
          </div>
        )}

        <div style={{ maxWidth: "800px", margin: "80px auto" }}>
          <p>
            All engineers are good writers… of code. But I believe that in
            order to become a better engineer, you should improve your writing skills.
          </p>
          <p>
            From the dawn of times, people were writing. We have written using symbols,
            like in Ancient Egypt. And we have written using letters, like in Renaissance times.
            And all of us got at least one writing assignment in school, without the “Why?”
            And yet, today writing is so underrated that most people want to avoid it.
            But the truth is–you will have to write. Comments, documentation, design documents,
            presentations. Whether you like it or not. So why not become better at it?
          </p>
        </div>
      </div>
    );
  }
}

export default TranscribeNote;
