import { storage } from "../firebase/firebase";
import { FirebseStroageFolder } from '../Config/Constant';
import firebase from 'firebase/app'
import 'firebase/analytics'
// 
export const getRosterImage = async (name) => {
  const dummyProfile = '';
  const data = JSON.parse(sessionStorage.getItem('UserData'))
  const FinfFolderNameArray = data?.cc_user_email_id?.split("@")
  const FolderName = FinfFolderNameArray?.[0]
  try {
    const automationRef = storage.ref(name);  
    // change ${FirebseStroageFolder}/${FolderName}/roster/ to (name)
    return new Promise(async (resolve, reject) => {

      try {
        await automationRef.getDownloadURL().then(fireBaseUrl => {
          resolve(fireBaseUrl);
// remove .child(name)
        })
      } catch (e) {
        resolve('');
      }
    });
  } catch (e) {
    return dummyProfile
  }

}

export const getProfileImage = async (name) => {
  const dummyProfile = '';
  const data = JSON.parse(sessionStorage.getItem('UserData'))
  const FinfFolderNameArray = data.cc_user_email_id.split("@")
  const FolderName = FinfFolderNameArray[0]
  try {
    const automationRef = storage.ref(name);
    return new Promise(async (resolve, reject) => {
      try {
        await automationRef.getDownloadURL().then(fireBaseUrl => {
          resolve(fireBaseUrl);

        })
      } catch (e) {
        resolve('');
      }
    });
  } catch (e) {
    return dummyProfile
  }
}

export const getNotesImage = async (name, id) => {
  console.log('namee', name)
  const dummyProfile = '';
  const data = JSON.parse(sessionStorage.getItem('UserData'))
  const FinfFolderNameArray = data.cc_user_email_id.split("@")
  const FolderName = FinfFolderNameArray[0]
  try {
    const automationRef = storage.ref(name);
    return new Promise(async (resolve, reject) => {

      try {
        await automationRef.getDownloadURL().then(fireBaseUrl => {
          resolve(fireBaseUrl);

        })
      } catch (e) {
        resolve('');
      }
    });
  } catch (e) {
    return dummyProfile
  }

}

export const getImage = async (name) => {
  const dummyProfile = '';
  const data = JSON.parse(sessionStorage.getItem('UserData'))
  const FinfFolderNameArray = data.cc_user_email_id.split("@")
  const FolderName = FinfFolderNameArray[0]
  try {
    const automationRef = storage.ref(name);
    return new Promise(async (resolve, reject) => {

      try {
        await automationRef.getDownloadURL().then(fireBaseUrl => {
          resolve(fireBaseUrl);

        })
      } catch (e) {
        resolve('');
      }
    });
  } catch (e) {
    return dummyProfile
  }

}

export const getLessonPlanImage = async (name, id) => {
  console.log('nameeeee', name)
  const dummyProfile = '';
  const data = JSON.parse(sessionStorage.getItem('UserData'))
  const FinfFolderNameArray = data.cc_user_email_id.split("@")
  const FolderName = FinfFolderNameArray[0]
  try {
    const automationRef = storage.ref(name);
    return new Promise(async (resolve, reject) => {

      try {
        await automationRef.getDownloadURL().then(fireBaseUrl => {
          resolve(fireBaseUrl);

        })
      } catch (e) {
        resolve('');
      }
    });
  } catch (e) {
    return dummyProfile
  }

}

export const setUserIdOnFirebase = async (id) => {
  try {

    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const analytics = firebase.analytics();
    analytics.setUserId(data.cc_user_id);
    analytics.setUserProperties({ prop_user_id: data.cc_user_id });

    return true
  } catch (e) {
    return false
  }

}