import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Card from 'react-bootstrap/Card';
import './ClassNoteAddGroupPanel.scss';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import addStudentIcon from '../../../../Assets/NoteModule/addStudent.svg';
import listIcon from '../../../../Assets/NoteModule/list.svg';
import addGroupNoteIcon from '../../../../Assets/NoteModule/addGroupNoteIcon.svg';
import trashAltIcon from '../../../../Assets/NoteModule/trash-alt.png';
import pencilAltIcon from '../../../../Assets/NoteModule/pencil-alt.png';
import closeMediaIcon from '../../../../Assets/NoteModule/closeMedia.svg';
import addGroupIcon from "../../../../Assets/NoteModule/plus.svg";
import closeIcon from 'Assets/FormativeAssessmentModule/close.svg';
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as routes from "../../../../Router/RoutesURL";
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import { useSelector } from 'react-redux';
import { createNotification } from 'Config/notificationtoast';

const ClassNoteAddGroupPanel = forwardRef((props, ref) => {
    const { groupLists, classDetails, academicYear, newStudentlist, cancelled, deleteGroupRequest, setCancelled, disableCheck, isAllowExpand,
        getSelectedGroup, setShowtick, showtick, enableCheck, setNewStudentsList, handleRemoveStudent, setEditGroup, setEnabledCheck,
            onChangeName, isGroupName, showAddNewGroup, setShowAddNewGroup, addNewGroupInList, setGroupName, setAddedStudents, enabledCheck, history, enabledGroupEditActionButton, disabledGroupEditActionButton,
            setRemoveStudentArr, addNewGroupBtnShow, showActionBtnOnSave, editGroupHandler, changeClassData,disableAddGroupBtnFunction
        } = props;
    const [nameEdit, setNameEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [enabledEdit, setEnabledEdit] = useState(false);
    const [expandPanel, setExpandPanel] = useState(-1);
    const [groupList, setGroupLists] = useState([]);
    const [selected, setSelected] = useState("");
    const [deleteGroupName, setDeleteGroupName] = useState("");
    const [students, setStudents] = useState([]);
    const [count, setCount] = useState(0);
    const [showActionBtn, setShowActionBtn] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showBtn, setShowBtn] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [newStudent, setNewStudent] = useState(false)
  const [changeGroupName, setChangeGroupName] = useState(false)
  const [editGRoupHere, setEditGroupHere] = useState(false)
  const [expandIndex, setExpandIndex] = useState(0)
  const [editGroupItemId, setEditGroupItemId] = useState(0)
//   const [customProps, setCustomProps] = useState({})
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  
  
  useEffect(() => {
    // if(addNewGroupBtnShow) {
    //     setCustomProps({ ...customProps, expanded: true });
    // }
    forceUpdate()
  },[props])

  useEffect(() => {

    setShowActionBtn(showAddNewGroup)
    // if(!showActionBtnOnSave) {
    //     setShowActionBtn(showActionBtnOnSave)
    // }
  },[showActionBtnOnSave, showAddNewGroup])

  useEffect(() => {
    
  },[showBtn, showActionBtn])

    const _ = require('lodash');
    // const { classDetails } = history.location.state;
    let student = [];

    useEffect(() => {
        setGroupLists(groupLists);
    }, [props, groupLists])

    useEffect(() => {
        if (showAddNewGroup === true) {
            setNameEdit(true)
        }
    }, [showAddNewGroup])

    // useEffect(() => {
    //     newStudentlist && newStudentlist.map(val => {
    //         if (!student.includes(val.cc_student_id)) {
    //             student.push(val.cc_student_id);
    //         }
    //         return student;
    //     })
    //     return setStudents(student);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [newStudentlist])

    useEffect(() => {

    }, [groupLists])

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = () => {
        let newArray = groupList.filter(e => e.cc_group_id !== selected)
        setGroupLists(newArray);
        setShow(false);
        let apiData = {
            "group_id": selected
        }
        deleteGroupRequest(apiData);
    }

    const handleShow = (item) => {
        
        let newArray = groupList.filter(e => e.cc_group_id !== selected)
        setGroupLists(newArray);
        setSelected(item.cc_group_id)
        setDeleteGroupName(item.cc_group_name);
        setShow(true);
        setIsDeleted(false)
        setShow(false);
        let apiData = {
            "group_id": item.cc_group_id
        }
        
        deleteGroupRequest(apiData);
        

    }

    const handleEdit = (index, value) => {
        
        setChangeGroupName(true)
        setShowActionBtn(true)
        setSelectedIndex(index)
        setExpandPanel(index);
        setNameEdit(true);
        setCancelled(false);
        setRemoveStudentArr([])

        setEnabledEdit(true);
        getSelectedGroup(value);
        disableCheck();
        setEnabledCheck(true)
    }

    const handleAddNewStudent = (index, value) => {
        setNewStudent(true)
        setShowActionBtn(true)
        setSelectedIndex(index)
        setEditGroup(true)
        enableCheck();
        // if (showAddNewGroup === true) {
        //     setNameEdit(true)
        //     setByBtn('')
        // } else {
        //     setNameEdit(false);
        //     setByBtn('')
        // }
        setNameEdit(false);
        setExpandPanel(index);
        setCancelled(false);
        getSelectedGroup(value);
        setEnabledEdit(true);
        setEnabledCheck(true);
        setGroupName("");
    }

    const onCancelAddNewGroup = (index) => {
        setShowActionBtn(false);
        setShowAddNewGroup(false);
        setNameEdit(false);
        setShowtick(false);
        setEditGroup(false);
        setCancelled(true);
        setEnabledEdit(false);
        setEnabledCheck(false)
        setGroupName("");
        setAddedStudents([]);
        let newGroupList = groupLists.shift();
        var newGroupsList = groupLists.filter(e => e !== newGroupList)
        newStudentlist.map(val => {
            return (val.associated_group.pop())
        })
        setGroupLists(newGroupsList);
    }

    const addnewGroup = () => {
        setCount(count)
        setShowAddNewGroup(true);
        enableCheck();
        setNameEdit(true);
        setEnabledEdit(true);
        setCancelled(false);
        setGroupName("");
        getSelectedGroup(count);
        setShowtick(true);
        setShowActionBtn(false)
        handleAddNewStudent(0, count)
        setExpandPanel(0);
        newStudentlist.map(val => {
            return (
                val.associated_group.push({ cc_group_id: count, cc_group_name: "New Group", is_associated: 1 })
            )
        })
        groupLists.unshift({ "cc_group_name": "New Group", "cc_group_id": count, "student_list": students })
        setGroupLists(groupLists);
        setNewStudentsList(newStudentlist)
    }

    useImperativeHandle(ref, () => ({
        addnewGroup() {
            setCount(count)
            setShowAddNewGroup(true);
            enableCheck();
            setNameEdit(true);
            setEnabledEdit(true);
            setCancelled(false);
            setGroupName("");
            getSelectedGroup(count);
            setShowtick(true);
            setShowActionBtn(false);
            handleAddNewStudent(0, count)
            setExpandPanel(0);
            newStudentlist.map(val => {
                return (
                    val.associated_group.push({ cc_group_id: count, cc_group_name: "New Group", is_associated: 0 })
                )
            })
            groupLists.unshift({ "cc_group_name": "New Group", "cc_group_id": count, "student_list": students })
            
            setGroupLists(groupLists);
            setNewStudentsList(newStudentlist)
        },

        onCancelAddNewGroup (index) {
            setShowActionBtn(false);
            setShowAddNewGroup(false);
            setNameEdit(false);
            setShowtick(false);
            setEditGroup(false);
            setCancelled(true);
            setEnabledEdit(false);
            setEnabledCheck(false)
            setGroupName("");
            setAddedStudents([]);
            let newGroupList = groupLists.shift();
            var newGroupsList = groupLists.filter(e => e !== newGroupList)
            newStudentlist.map(val => {
                return (val.associated_group.pop())
            })
            setGroupLists(newGroupsList);
        }
    }));

    useEffect(() => {
        if (cancelled === true) {
            setNameEdit(false);
        }
    }, [props, cancelled])

    if (!enabledEdit && showAddNewGroup && expandPanel !== 0) {
        setEnabledCheck(false);
        groupLists.shift();
        newStudentlist.map(val => {
            return (val.associated_group.pop());
        })
        setNewStudentsList(newStudentlist);
    }

    useEffect(() => {
        
    },[enabledEdit, showActionBtn])


    const accordionProps = {
        sx: {
          pointerEvents: "none"
        },
        expandIcon: (
          <ExpandMoreIcon
            sx={{
              pointerEvents: "auto"
            }}
          />
        )
      };

    //   const accordParentProps = {
    //     expanded
    //   }

    //   useEffect(() => {
    //     console.log(expandIndex, expandPanel, addNewGroupBtnShow)
    //     if(addNewGroupBtnShow) {
    //         setCustomProps({
    //             ...customProps,
    //             expanded : false
    //         })
    //     }
    //   },[addNewGroupBtnShow])

   

    // console.log(customProps)

    

    return (
        <div data-testid = "classNoteAGPMock"  className='ClassNoteAddGroupPanelSCSS'>
            <div className='CNAGP-panel-div'>
                {/* {groupList && newStudentlist &&
                    <Col md="12" lg="12" className="CNAGP-addGroupPanel">
                        <hr></hr>
                        {showAddNewGroup ?
                            <div className="mt--28">
                                <img className="CNAG-addGroupIcon" src={addGroupIcon} alt="" width="14" height="14" />
                                <label>Add New Group</label>
                            </div>
                            : <div className="mt--28" onClick={addnewGroup} >
                                <img className="CNAG-addGroupIcon" src={addGroupIcon} alt="" width="14" height="14" />
                                <label>Add New Group</label>
                            </div>}
                    </Col>
                } */}
                <Row>
                    <Col data-testid = "CNAG-groupPanel" md="12" lg="12" className="CNAG-groupPanel">
                        {groupList?.length > 0 && groupList.map((item, index) => {
                            const titleName = ['title', enabledEdit && newStudent ?  '' : enabledEdit && enabledCheck && expandPanel === index ? 'w-166' : '']
                            const actionCircle = ['actionCircle', 'mt--32']
                            const action = ['action', 'mt--32'];
                            let some = addNewGroupBtnShow && (index == 0)
                            return (
                                <Accordion 
                                // expanded={item.cc_group_id == 0 ? true : ''}
                                // {
                                //     ...(item.cc_group_id == 0) && {['expanded'] : true}
                                // }
                                // expanded={some == true ? true : undefined}
                                expanded={(index == 0) ? true : undefined}
                                // {...props.customProps?.expanded}
                                // expanded={false}
                                 key={index}
                                //  {...accordParentProps}
                                 >
                                    <AccordionSummary 
                                    expandIcon={<ExpandMoreIcon
                                         onClick={(e) => {
                                          if(!addNewGroupBtnShow) {
                                        setExpandPanel(expandPanel === index ? -1 : index);
                                        // setExpandPanel(true)
                                        setEnabledEdit(false);
                                        setShowAddNewGroup(false);
                                        setShowActionBtn(false);
                                        isAllowExpand(item);
                                          } else {
                                            e.stopPropagation()
                                          }
                                    }} 
                                    
                                    />}
                                    // aria-controls={`panel${index}a-content`}
                                    // id={`panel${index}a-header`}
                                    // {...accordionProps}
                                    
                                    >
                                        <div className="MuiTypography-root MuiTypography-body1">
                                            <div className={`${titleName.join(' ')} ${item.cc_group_name.length > 15 ? 'titleTextEllipse' : ''}`}
                                             style={{position : ((addNewGroupBtnShow && item.cc_group_id == 0) || (showActionBtn && !newStudent && enabledEdit && expandPanel === index )) && 'relative', bottom :((addNewGroupBtnShow && item.cc_group_id == 0) || (showActionBtn && !newStudent && enabledEdit && expandPanel === index)) && 12}}>
                                                {!(enabledEdit && nameEdit && expandPanel === index) ? item.cc_group_name.replace(/(.{13})..+/, "$1…") : (
                                                    <FormControl
                                                        className="CNAG-groupName"
                                                        placeholder="Group Name"
                                                        aria-label="groupName"
                                                        value={!addNewGroupBtnShow ? item.cc_group_name : isGroupName}
                                                        onChange={(e) => onChangeName(e.target.value)}
                                                        onClick={(e) => {
                                                            // setCustomProps({...customProps, expanded  :true});
                                                            e.stopPropagation()
                                                        }
                                                        }
                                                    />
                                                )}

                                            </div>
                                            {showAddNewGroup === true  && index === selectedIndex && showActionBtn ? null
                                            : index === selectedIndex && showActionBtn && showBtn ?
                                                <div className={actionCircle.join(' ')} 
                                                style={{marginTop : newStudent ? '-22px' : changeGroupName ? '-22px' : ''}}
                                                >
                                                            
                                                    <Button onClick={(e) => { disabledGroupEditActionButton(); setShowActionBtn(false); setNewStudent(false);setChangeGroupName(false);setEditGroupHere(false); disableAddGroupBtnFunction(false); e.stopPropagation()}} variant="danger" className="CNAG-actionBtn cancel">
                                                        <img src={closeIcon} alt='' />
                                                    </Button>
                                                    <Button data-testid = "btnsavemock" onClick={(e) => { 
                                                        if(editGRoupHere) {
                                                            if(item?.cc_group_name.trim().length <= 0) {
                                                                createNotification('error', 'Please enter valid group name');
                                                            } else {
                                                            if(!/^[a-zA-Z ]*$/.test(item?.cc_group_name)) {
                                                                createNotification('error', 'Please enter valid group name');
                                                                } else {
                                                                enabledGroupEditActionButton(); setShowActionBtn(false); setNewStudent(false);setChangeGroupName(false);disableAddGroupBtnFunction(false); e.stopPropagation()
                                                                }
                                                            }
                                                        } else {
                                                        enabledGroupEditActionButton(); setShowActionBtn(false); setNewStudent(false);setChangeGroupName(false);disableAddGroupBtnFunction(false); e.stopPropagation()
                                                        }    
                                                    }} variant="success" className="CNAG-actionBtn save">
                                                        <img src={tickIcon} alt='' />
                                                    </Button>
                                                </div>
                                                : <div className={action.join(' ')}>
                                                    <img data-testid = "btnaddnotemock" src={addGroupNoteIcon} alt="" onClick={(e) => {
                                                        if(addNewGroupBtnShow || showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') { 
                                                            e.stopPropagation()
                                                            return
                                                        
                                                        } else {
                                                            // if(item?.student_list.length > 0) {
                                                            history?.push(routes.CLASSGROUPNOTEVIEW, {
                                                            studentsInfo: newStudentlist,
                                                            selectedStudentsForGroupNote: item.student_list,
                                                            classDetails: classDetails,
                                                            pageType: 'New',
                                                            data: '',
                                                            academicYear: academicYear,
                                                            student_enrolled_classes: [],
                                                            NotesData: "",
                                                            StudentAllNoteDetails: [],
                                                            class_id: classDetails.value,
                                                            groupDetail : item,
                                                            academicYearId : props?.history?.location?.state?.academicYearId
                                                            })
                                                        // } else {
                                                        //     createNotification('error', 'Please add atleast one student to the group.');
                                                        // }
                                                            // history.push(routes.CLASSADDNOTE, {
                                                            // studentsInfo: newStudentlist,
                                                            // selectedStudentsForGroupNote: item.student_list,
                                                            // classDetails: classDetails,
                                                            // pageType: 'New',
                                                            // data: '',
                                                            // academicYear: academicYear,
                                                            // student_enrolled_classes: [],
                                                            // NotesData: "",
                                                            // StudentAllNoteDetails: [],
                                                            // class_id: classDetails.value,
                                                            // academicYearId : props?.history?.location?.state?.academicYearId,
                                                            // groupDetail : item,
                                                            //------------------------------

                                                            // history.push(routes.STUDENTADDNOTE,
                                                            //         {
                                                            //             pageType: 'New',
                                                            //             data: '',
                                                            //             studentInfo: studentInfo,
                                                            //             academicYear: academicYear,
                                                            //             student_enrolled_classes: student_enrolled_classes,
                                                            //             NotesData: NotesData,
                                                            //             StudentAllNoteDetails: StudentAllNoteDetails,
                                                            //             class_id: class_id,
                                                            //             directAdd: "directAdd",
                                                            // })
                                                        // });
                                                        }
                                                    //       if(!addNewGroupBtnShow) {
                                                    //     history.push(routes.CLASSADDNOTE, {
                                                    //         studentsInfo: newStudentlist,
                                                    //         selectedStudentsForGroupNote: item.student_list,
                                                    //         classDetails: classDetails,
                                                    //         pageType: 'New',
                                                    //         data: '',
                                                    //         academicYear: academicYear,
                                                    //         student_enrolled_classes: [],
                                                    //         NotesData: "",
                                                    //         StudentAllNoteDetails: [],
                                                    //         class_id: classDetails.value
                                                    //         //------------------------------

                                                    //         // history.push(routes.STUDENTADDNOTE,
                                                    //         //         {
                                                    //         //             pageType: 'New',
                                                    //         //             data: '',
                                                    //         //             studentInfo: studentInfo,
                                                    //         //             academicYear: academicYear,
                                                    //         //             student_enrolled_classes: student_enrolled_classes,
                                                    //         //             NotesData: NotesData,
                                                    //         //             StudentAllNoteDetails: StudentAllNoteDetails,
                                                    //         //             class_id: class_id,
                                                    //         //             directAdd: "directAdd",
                                                    //         // })
                                                    //     });
                                                    // }
                                                    }}
                                                    style={{opacity : (showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') && '0.5'}}
                                                    />
                                                    <img data-testid = "addstudentmock" src={addStudentIcon} alt="" onClick={(e) => {
                                                        if(addNewGroupBtnShow || showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') { 
                                                            e.stopPropagation()   
                                                            // return
                                                        
                                                        } else {
                                                            // e.stopPropagation()
                                                            // e.preventDefault()
                                                            handleAddNewStudent(index, item.cc_group_id)
                                                            editGroupHandler(true)
                                                            setEditGroupHere(true)
                                                            disableAddGroupBtnFunction(true)
                                                            setExpandPanel(expandPanel === index ? -1 : index);
                                                            setEditGroupItemId(item.cc_group_id)
                                                            e.stopPropagation()   
                                                           
                                                            setExpandIndex(index)
                                                          
                                                        }
                                                          }} 
                                                          style={{opacity : (showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') && '0.5'}}
                                                        //   style={{opacity : addNewGroupBtnShow || showActionBtn || props?.classDetails?.details?.cc_class_share_permission == 'read' && '0.5'}}
                                                          />
                                                    <img data-testid = "btneditmock"  src={pencilAltIcon} alt="" onClick={(e) => {
                                                    //       if(!addNewGroupBtnShow) {
                                                    //     handleEdit(index, item.cc_group_id)
                                                    //  } }} 
                                                    if(addNewGroupBtnShow || showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') { 
                                                        e.stopPropagation()
                                                            return
                                                        
                                                        } else {
                                                            handleEdit(index, item.cc_group_id);
                                                            editGroupHandler(true)   
                                                            setEditGroupHere(true)
                                                            disableAddGroupBtnFunction(true)
                                                        }

                                                    }}
                                                    style={{opacity : (showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') && '0.5'}}
                                                     />
                                                    <img data-testid = "btndeletemock" src={trashAltIcon} alt="" onClick={(e) => { 
                                                         if(addNewGroupBtnShow || showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') { 
                                                            e.stopPropagation()
                                                            return
                                                        
                                                        } else {
                                                            setIsDeleted(true)
                                        setDeleteItem(item)
                                                        }
                                        //                   if(!addNewGroupBtnShow) {
                                        //                 setIsDeleted(true)
                                        // setDeleteItem(item) }
                                        }} 
                                        style={{opacity : (showActionBtn || changeClassData?.[0]?.cc_class_share_permission =='read' || props?.classDetails?.details?.cc_class_share_permission == 'read') && '0.5'}}
                                        />
                                                </div>
                                            }
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails className="CNAG-detail-section">
                                        <Row className="m-auto">
                                            {newStudentlist && item && item.student_list && item.student_list.map((value, j) => {
                                                
                                                for (let studentDetail of newStudentlist) {
                                                    const details = ['CNAGP-details', studentDetail.cc_student_email_id.length < 25 ? "CNAGP-marginGroup" : ""]
                                                    if (value === studentDetail.cc_student_id) {
                                                        return (
                                                            <Col /*xs="12" sm="6" md="4" lg="3"*/ className="CNAGP-StudentProfiles" key={j}>
                                                                <Card className="br-8 CNAGP-StudentProfilesCardDiv">
                                                                    <Card.Body className="br-8 CNAGP-StudentProfilesCardBodyDiv">
                                                                    {(enabledEdit && enabledCheck && showtick) &&
                                                                                <img className="closeMediaIcon" src={closeMediaIcon} alt=''
                                                                                    onClick={() => { handleRemoveStudent(groupList, item, studentDetail.cc_student_id) }}
                                                                                    width="18" height="18" 
                                                                                    style={{display : ((addNewGroupBtnShow && item.cc_group_id != 0) || (showActionBtn && (item.cc_group_id != editGroupItemId))) && 'none'}}
                                                                                    />
                                                                            }
                                                                        {/* {
                                                                            (addNewGroupBtnShow && item.cc_group_id == 0) ? 
                                                                            <>
                                                                            {(enabledEdit && enabledCheck && showtick) &&
                                                                                <img className="closeMediaIcon" src={closeMediaIcon} alt=''
                                                                                    onClick={() => { handleRemoveStudent(groupList, item, studentDetail.cc_student_id) }}
                                                                                    width="18" height="18" />
                                                                            } </> : 

                                                                            <>
                                                                            {
                                                                                !addNewGroupBtnShow && item.cc_group_id != 0 && 
                                                                                <>
                                                                                {(enabledEdit && enabledCheck && showtick) &&
                                                                                <img className="closeMediaIcon" src={closeMediaIcon} alt=''
                                                                                    onClick={() => { handleRemoveStudent(groupList, item, studentDetail.cc_student_id) }}
                                                                                    width="18" height="18" />
                                                                            }
                                                                                </>
                                                                            }
                                                                            </>
                                                                        } */}
                                                                        
                                                                        <Row className="CNAGP-profile">
                                                                            <Col xs="12">
                                                                                {studentDetail.cc_student_pic_url ?
                                                                                    <div className="CNAGP-img">
                                                                                        <img className="CNAGP-withImg" variant="top" alt='' src={studentDetail.cc_student_pic_url} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className="CNAGP-img">
                                                                                        <h5 className='CNAGP-rosterProfileImageText'>{studentDetail.cc_student_first_name.charAt(0)}{studentDetail.cc_student_last_name.charAt(0)}</h5>
                                                                                    </div>}
                                                                                <div className={details.join(' ')}>
                                                                                    {studentDetail.cc_student_last_name.length >= 10 || studentDetail.cc_student_first_name >= 10 ?
                                                                                        <span className="CNAGP-nameBigTxt">{studentDetail.cc_student_last_name}, {studentDetail.cc_student_first_name}</span> :
                                                                                        <span className="CNAGP-name">{studentDetail.cc_student_last_name}, {studentDetail.cc_student_first_name}</span>
                                                                                    }
                                                                                    <br></br>
                                                                                    {/* {studentDetail.cc_student_email_id.length > 30 ?
                                                                                        <p data-title={studentDetail.cc_student_email_id}>
                                                                                            {_.truncate(studentDetail.cc_student_email_id, {
                                                                                                'length': 30,
                                                                                                'omission': '...'
                                                                                            })}</p> :
                                                                                        <span className="mail">{studentDetail.cc_student_email_id}</span>} */}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    }
                                                }
                                            })
                                            }
                                        </Row>

                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </Col>
                </Row>
{/* 
                <Modal className="delete-modal-warning br-8" show={show} onHide={handleClose} centered={true}>
                    <Modal.Header>
                        <Modal.Title>Delete Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="confirm-text">Are you sure you want to delete <br></br> {deleteGroupName} ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="default" className="delete-btn" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal> */}



               {isDeleted === true &&
                <Modal centered className="delete-modal-warning br-8" show={isDeleted} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure you want to delete <span className='font-weight-bold'>{`${deleteItem?.cc_group_name}`}</span> Group?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => { 
                              // editStudent(selectIndex);
                                // this.setState({ isDelete: false })
                                setIsDeleted(false)
                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            onClick={() => { 
                              // deleteStudentHandler(selectStudentId, selectIndex)
                              handleShow(deleteItem);
                             }}
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal> }
            </div>
            {props?.loader || groupList?.length >= 1 ? "" :
            <p className='noGrpanelbookDataText'>Get your groups started by clicking "+ Group" above.</p>
          }
        </div>
    )
});
export default ClassNoteAddGroupPanel
