import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import viewOnlyIcon from 'Assets/RosterModule/viewOnly.png'
import createCopyIcon from 'Assets/RosterModule/createCopy.png'
import collaborateIcon from 'Assets/RosterModule/collaborate.png'
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import DivLoader from 'Components/LoadingComponent/DivLoader';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import './ClassShare.scss';

const ClassShareDashboard = (props) => {
    const { getSchoolTeacher, shareClass, state, history } = props;
    const { classShare } = props.state;
    const { class_share, loader } = state.classShare;
    const { classDetail } = history.location.state
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectShareOption, setSelectShareOption] = useState('');
    const [showPopUp, setShowPopUp] = useState(false)
    const [teachersList, setTeachersList] = useState([]);
    const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
    const [loadMoreCount, setLoadMoreCount] = useState(1);
    const [classOverAllCount, setClassOverAllCount] = useState([]);
    const [hideCollaborate, setHideCollaborate] = useState(false)
    const [numBoxes, setNumBoxes] = useState(100);
    let data = JSON.parse(sessionStorage.getItem('UserData'));

    const noDataRef = useRef(null)
    const dataRef = useRef(null)

    

    useEffect(() => {
        
        if(props?.location?.state?.classDetail?.created_by == data?.cc_user_id) {
            //Owner
            if(props?.location?.state?.classDetail?.is_shared == 'yes') {
                if(props?.location?.state?.classDetail?.recent_shared_permission == 'read') {
                    setHideCollaborate(true)
                }
            }
        } else {
            if(props?.location?.state?.classDetail?.cc_class_share_permission == 'read') {
                setHideCollaborate(true)
            }
        }
    },[props])

    useEffect(() => {
        // const boxHeight = 80; // height of each box in pixels
        // const windowHeight = window.innerHeight;
        // const numBoxes = Math.floor(windowHeight / boxHeight);
        // console.log(windowHeight, numBoxes)
        // setNumBoxes(numBoxes);
        const boxWidth = 352; // width of each box in pixels
        const boxHeight = 80; // height of each box in pixels
        const divWidth = noDataRef?.current?.offsetWidth;
        // const divHeight = window.innerHeight
        const divHeight = noDataRef?.current?.offsetHeight;
        const numBoxesWidth = Math.floor(divWidth / boxWidth);
        // const numBoxesWidth = 403
        const numBoxesHeight = Math.floor(divHeight / boxHeight);
        // const numBoxes = numBoxesWidth * numBoxesHeight;
        // setNumBoxes(numBoxes);
      }, []);
    
      useEffect(() => {
        if(numBoxes != 'NaN' && numBoxes > 0) {
            const apiData = {
                "page_no": loadMoreCount,
                "limit": numBoxes
            }
            getSchoolTeacher(apiData);
      }
      },[numBoxes])


      const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = dataRef?.current;
        // console.log(StudentOverAllCount)
        // setStudentDivHeight(clientHeight)
        if(teachersList && teachersList.length != classOverAllCount) {
        if (parseInt(scrollTop) + clientHeight == scrollHeight) {
          let value = loadMoreCount
          value++
          setLoadMoreCountStatus(true);
          setLoadMoreCount(value);
          const apiData = {
              "page_no": value,
              "limit": numBoxes
          }
          getSchoolTeacher(apiData);
         
        }
      }
        //  else {
        //   setIsLoading(false)
        // }
      }


    // useEffect(() => {
    //     const apiData = {
    //         "page_no": loadMoreCount
    //     }
    //     getSchoolTeacher(apiData);
    // }, [])
    // console.log("classShare", classShare);
    useEffect(() => {
        if (classShare && classShare.class_share && classShare.class_share?.data.teacher_data && loadMoreCount === 1) {
            setTeachersList(classShare.class_share?.data.teacher_data);
            setClassOverAllCount(classShare.class_share?.data?.teacher_count);
            setLoadMoreCountStatus(false)
        }
        if (classShare && classShare.class_share && classShare.class_share?.data.teacher_data && loadMoreCount > 1) {
            classShare.class_share?.data.teacher_data.map(val => {
                teachersList.push(val)
            })
            setTeachersList(teachersList);
            setClassOverAllCount(classShare.class_share?.data?.teacher_count);
            setLoadMoreCountStatus(false)
        }
    }, [classShare])

    // useEffect(() => {
    //     if (classShare && classShare.action === 'SHARE_CLASS_FAILURE') {
    //         if (classShare.error && (classShare.error.message === 'Note limit has been exceeded. Please upgrade the plan'
    //         || classShare.error.message === "Media limit has been exceeded. Please upgrade the plan"
    //         || classShare.error.message === "No space available. Please update your subscription plan")) {
    //             setShowPopUp(true);
    //         }
    //     }
    // }, [classShare])


    const selectOption = (value) => {
        setSelectShareOption(value)
    }

    const onCancel = (value) => {
        setSelectedTeacher("")
    }

    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    const sendPermission = () => {
        history.goBack();
        let apiData = {
            "permisionType": selectShareOption,
            "classId": classDetail.cc_class_id,
            "sharedUserId": selectedTeacher
        }
        // console.log("classshareapiData",apiData , classDetail)
        shareClass(apiData);
        setTeachersList([]);
    }

    //  ---------Load More List-------
    const loadMoreTeachers = () => {
        let value = loadMoreCount
        value++
        setLoadMoreCountStatus(true);
        setLoadMoreCount(value);
        const apiData = {
            "page_no": value
        }
        getSchoolTeacher(apiData);
    }
    // ---------Load More List-------

    return (
        <div className='TeacherShareContainer'>
            {/* ------------Heading------------- */}
            {teachersList && <div className='mainHeading'>
                <div>
                    <p className='headingSchoolName'>{data.cc_user_school_name}</p>
                </div>
                <div>
                    <p className='headingSelectTeacher'>— Select Teacher to share student</p>
                </div>
            </div>}
            {/* ------------Heading------------- */}
            {/* {loader && <div>
                <DivLoader />
            </div>} */}
            {/* ------------Teacher Card------------- */}
            {
                teachersList && teachersList.length > 0 ?
                <>
                <div
                 ref={dataRef} 
                 // // style={{height : "calc(100vh - 110px)"}}
                 style={{
                   // height : StudentList.length > 0 ? `70vh` : "82vh", 
                   height : teachersList.length > 0 ? `calc(100vh - 200px)` : "82vh",
                   overflowY : 'scroll',
                   overflowX : 'hidden'
                   }}  
                  onScroll={handleScroll}
                >
                {teachersList && teachersList.map((item, index) => (<div key={index} className='teacherMainDiv teacherMainDiv-ShareRoster'>
                <div className='teacherProfileImageDiv'>
                    {item.cc_user_pic ? <img src={item.cc_student_pic_url} alt='' className='teacherShareProfileImage' /> :
                        <h5 className='teacherImageDummyText'>{item.cc_user_first_name.charAt(0)}{item.cc_user_last_name.charAt(0)}</h5>}
                </div>
                <div className='teacherShareTextInfoDiv' >
                    <p className='teacherShareName'>{item.cc_user_first_name.replace(/(.{13})..+/, "$1…")}</p>
                    <p className='teacherShareEmail'>{item.cc_user_email_id}</p>
                </div>
                <div className='teacherShareCheckBoxDiv'>
                    <i onClick={() => { setSelectedTeacher(item.cc_user_id) }} className="checkBoxColorTeacherShare material-icons" data-toggle="modal" data-target="#myModalPermission" >{selectedTeacher === item.cc_user_id ? 'check_circle' : 'radio_button_unchecked'}</i>
                </div>

            </div>))}
            </div>
                </>
                :
                <>
                <div
                  ref={noDataRef}
                  style={{height : '100vh'}}
                >
                     {loader && (
            <div>
            <DivLoader />
          </div>
                     )}

                </div>
                </>
            }
            
            {/* ------------Teacher Card------------- */}

            {/* ---------------Modal Upload------------ */}
            <div className="modal right fade" id="myModalPermission" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header headerDiv">
                            <div className='permissionHeaderDiv'>
                                <div>
                                    <p className='permissionText'>Select Permission</p>
                                </div>
                                <div>
                                    <button onClick={onCancel} type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconPermissionModal' /> Cancel</span></button>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body">
                            {/* --------------- Upload option------------ */}
                            <div className='selectMainDivforShareTeacher'>
                                <div className="subDivForSelecType">
                                    <div className='selectOptionInTeacherShareType'>
                                        <i onClick={() => { selectOption('read') }} className="checkBoxColorTeacherShare material-icons">{selectShareOption === 'read' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <img src={viewOnlyIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>View Only</p>
                                    </div>
                                    <div className={`selectOptionInTeacherShareType ${JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? 'd-none' : ''}`}>
                                        <i onClick={() => { selectOption('read_write') }} className="checkBoxColorTeacherShare material-icons">{selectShareOption === 'read_write' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <img src={createCopyIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>Create Copy</p>
                                    </div>
                                    <div className={`selectOptionInTeacherShareType ${hideCollaborate ? 'd-none' : ''}`}>
                                        <i onClick={() => { selectOption('collaborate') }} className="checkBoxColorTeacherShare material-icons">{selectShareOption === 'collaborate' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <img src={collaborateIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>Collaborate</p>
                                    </div>
                                </div>
                                <div>
                                    <Button disabled={selectShareOption ? false : true} className='sendPermissionButton'
                                        onClick={sendPermission} color="primary" data-dismiss="modal" aria-label="Close">
                                        <span className='sendPermissionText' > Send</span>
                                    </Button>
                                </div>
                            </div>
                            {/* ---------------Upload option------------ */}
                        </div>
                    </div>
                </div>
            </div>
            {/* {(teachersList.length > 0 && teachersList.length > 0) &&
                <div style={{ padding: 5 }}>
                    {loadMoreCountStatus ? <Button className='loadMoreClass' color="primary" >
                        <img src={loaderImag} alt='' className='loaderIconLoadMoreClass' />
                    </Button>
                        :
                        <div>
                            {classOverAllCount && classOverAllCount > teachersList.length &&
                                <Button onClick={loadMoreTeachers} className='loadMoreClass' color="primary" >
                                    <i className="material-icons">autorenew</i>
                                    <span className='LoadClassButtonText' > Load More</span>
                                </Button>}
                        </div>}
                </div>} */}
            {/* ---------------Modal Add Student------------ */}
            {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
        </div>
    )
}

ClassShareDashboard.propTypes = {
    shareClass: PropTypes.func,
    getSchoolTeacher: PropTypes.func,
    history: PropTypes.object,
    state: PropTypes.object,
}

export default ClassShareDashboard;
