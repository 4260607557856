import React, { useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import PaypalBtn from "react-paypal-checkout";
import professionalSmall from "Assets/SubscriptionsModule/professionalSmall.png";
import premiumSmall from "Assets/SubscriptionsModule/premiumSmall.png";
import personalPath from "Assets/SubscriptionsModule/personalPath.svg";
import premiumPath from "Assets/SubscriptionsModule/premiumPath.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DivLoader from "Components/LoadingComponent/DivLoader";
import { createNotification } from "Config/notificationtoast";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { PAYPALCLIENT, PAYPALENV, CURRENCY, LOCALE, BASE_MICROSERVICE_URL_PLAN } from "Config/Constant";
import { Button } from "reactstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { decryptData, encryptData } from "Utils/Helper";
const style = {
  label: "pay",
  tagline: false,
  size: "medium",
  shape: "rect",
  color: "blue",
  width: 300,
};



const PayPalPayment = (props) => {
  console.log(24, props);
  const {
    goBack,
    setStatusFailed,
    item,
    setPaymentDataRequest,
    loader,
    changeUserSubscriptionRequest,
    setStripePaymentSuccess,
  } = props;
  const dispatch = useDispatch()
  const [showPaypal, setShowPaypalCheck] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  let [message, setMessage] = React.useState("");
  let [success, setSuccess] = React.useState(false);
  let [sessionId, setSessionId] = React.useState("");

  const ButtonWrapper = ({ type }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();
  
    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          intent: "subscription",
        },
      });
    }, [type]);
  
    return (
      <PayPalButtons
        createSubscription={(data, actions) => {
          console.log(data);
          console.log(actions)
          return actions.subscription
            .create({
              plan_id: props?.item?.paypalObj?.id,
            })
            .then((orderId) => {
              console.log(orderId);
              // Your code here after create the order
              return orderId;
            })
            .catch((err) => {
              console.log(err);
            });
        }}
        onApprove={function (data, actions) {
          console.log(data)
          console.log(actions)
          setStripePaymentSuccess(true)
          // const Data = {
          //   agreement_id: data?.subscriptionID,
          //   plan_id: subscriptionId,
          //   transaction_type: "PayPal",
          //   // receipt_data: payment?.paymentToken,
          //   type: "web",
          //   // email: payment?.email,
          //   // payerID: payment?.payerID,
          //   // paymentToken: payment?.paymentToken,
          //   // subscription_json: payment?.address,
          // };
          // setPaymentDataRequest(Data);
        }}
        // onCancel={function (data, actions) {
        //   console.log(data)
        //   console.log(actions)
        // }}
        style={{
          label: "subscribe",
          tagline: false,
          size: "medium",
          shape: "rect",
          color: "blue",
          width: 365,
          height: 45
        }}
        className="newPaypalBtnClass"
      />
    );
  };

  // useLayoutEffect(() => {
  //   //PAYPAL JS SDK
  //   const script = document.createElement("script");
  //   // script.src = 'https://www.paypal.com/sdk/js?client-id=AfiAu4Pd1gaVDHaTYzKnAtkrQZMhfISX3eyWMwLed_AR61Smw7HLafngwvF_DnHbp3Rs-95zJOFpGX9h&vault=true&intent=subscription&disable-funding=credit,card';
  //   script.src =
  //     "https://www.paypal.com/sdk/js?client-id=AfiAu4Pd1gaVDHaTYzKnAtkrQZMhfISX3eyWMwLed_AR61Smw7HLafngwvF_DnHbp3Rs-95zJOFpGX9h&vault=true&intent=subscription&disable-funding=credit,card";
  //   // script.src="https://www.paypal.com/sdk/js?client-id=sb&vault=true&intent=subscription"
  //   script.async = true;
  //   script.onload = () => {
  //     initializePayPalButton();
  //   };

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    setTimeout(function () {
      setShowPaypalCheck(true);
    }, 2000);
  }, []);

  // const initializePayPalButton = () => {
  //   // axios.get('https://api-m.sandbox.paypal.com/v1/notifications/webhooks', {
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //     'Authorization': `Bearer ${sessionStorage.getItem("tokenHere")}`
  //   //   }
  //   // })
  //   //   .then(response => {
  //   //     // Handle success
  //   //     console.log(response.data);
  //   //   })
  //   //   .catch(error => {
  //   //     // Handle error
  //   //     console.error(error);
  //   //   });

  //   window.paypal
  //     .Buttons({
  //       style: {
  //         shape: "rect",
  //         color: "blue",
  //         layout: "vertical",
  //         label: "subscribe",
  //         height: 45,
  //         width: 365,
  //       },
  //       createSubscription: function (data, actions) {
  //         console.log(actions);
  //         console.log(data);
  //         return actions.subscription.create({
  //           plan_id: "P-6KR80334P92545453MWVCJQI", // Replace with your plan ID
  //         });
  //       },
  //       onApprove: async function (data, actions) {
  //         console.log(actions);
  //         console.log(data);
  //         console.log(
  //           "You have successfully subscribed to " + data.subscriptionID
  //         );

  //         // const resultHere = await axios.post('http://localhost:5000/webhook')
  //         // console.log(resultHere)
  //         // const result = await axios.post(
  //         //   "http://localhost:5000/cancelPreviousSubscription", {
  //         //     authToken
  //         //   }
  //         // );
  //         // console.log(result);
  //         // try {
  //         //   const resultHere = await axios.post(
  //         //     "https://api-m.sandbox.paypal.com/v1/billing/subscriptions/I-3BP0S32VRSFT/cancel",
  //         //     {
  //         //       "reason": "Not satisfied with the service"
  //         //     },
  //         //     {
  //         //       headers: {
  //         //         "Content-Type": "application/json",
  //         //         "Authorization": `Bearer ${sessionStorage.getItem('tokenHere')}`
  //         //       }
  //         //     }
  //         //   );

  //         //   console.log('deleteSubs', resultHere.data);
  //         // } catch (error) {
  //         //   console.error(error);
  //         // }
  //       },
  //     })
  //     .render("#paypal-button-containers");
  // };

  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
      sessionStorage.setItem("sessionIdSaved", query.get("session_id"));
      setStripePaymentSuccess(true);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    // if (item?.cc_subscription_title === "free") {
    //   setSubscriptionId("1");
    // }

    // if (item?.cc_subscription_title === "Premium") {
    //   if (item?.selectedSubType === "monthly") {
    //     setSubscriptionId("2");
    //   } else if (item?.selectedSubType === "annual") {
    //     setSubscriptionId("7");
    //   }
    // }

    // if (item?.cc_subscription_title === "Professional") {
    //   if (item?.selectedSubType === "monthly") {
    //     setSubscriptionId("3");
    //   } else if (item?.selectedSubType === "annual") {
    //     setSubscriptionId("8");
    //   }
    // }

    if (
      item?.selectedSubType == "annual" ||
      JSON.parse(sessionStorage.getItem("paymentObj"))?.selectedSubType ==
        "annual"
    ) {
      setSubscriptionId(
        item?.product_ids?.Web?.yearly ||
          JSON.parse(sessionStorage.getItem("paymentObj"))?.product_ids?.Web
            ?.yearly
      );
    } else {
      setSubscriptionId(
        item?.product_ids?.Web?.monthly ||
          JSON.parse(sessionStorage.getItem("paymentObj"))?.product_ids?.Web
            ?.monthly
      );
    }
  }, [item]);

  // -----On Payment Success------
  const onSuccess = (payment) => {
    const Data = {
      agreement_id: payment?.paymentID,
      plan_id: subscriptionId,
      transaction_type: "PayPal",
      receipt_data: payment?.paymentToken,
      type: "web",
      email: payment?.email,
      payerID: payment?.payerID,
      paymentToken: payment?.paymentToken,
      subscription_json: payment?.address,
    };
    setPaymentDataRequest(Data);
  };
  // -----On Payment Success------

  // -----On Payment Cancel------
  const onCancel = (data) => {
    createNotification("warning", "The payment is cancelled");
  };
  // -----On Payment Cancel------

  // -----On Payment Error------
  const onError = (err) => {
    createNotification("error", "Something went wrong");
    setStatusFailed(true);
  };
  // -----On Payment Error------

  const stripeCheckoutHandler = async (item) => {
    if(JSON.parse(sessionStorage.getItem("paymentObj"))?.userPaymentDetails?.[0]?.customer_id) {
    let obj = {
      priceId: item?.id,
      customerId: JSON.parse(sessionStorage.getItem("paymentObj"))?.userPaymentDetails?.[0]?.customer_id
    };
    console.log(obj)
    changeUserSubscriptionRequest(obj)
    } else {
      let data = {
        lookup_key : item?.id,
        customer_email : JSON.parse(sessionStorage.getItem('UserData'))?.cc_user_email_id,
        isPreviousFreePlan: 1
      }
      console.log(data)
      const result = await axios.post(`${BASE_MICROSERVICE_URL_PLAN}subscription/create-checkout-session`, {data : encryptData(data)})
      console.log(result)
      const decryptedDataRes = decryptData(result?.data?.res)
      console.log(decryptedDataRes)
      window.open(decryptedDataRes?.data?.url, "_self")
    }
    
  };

  let planContent =
    item?.cc_subscription_content?.split("\\n") ||
    JSON.parse(
      sessionStorage.getItem("paymentObj")
    )?.cc_subscription_content?.split("\\n");

  return (
    <div>
      {/* ------ Plan Details------ */}
      <div className="mainCardRow">
        <Row className="cardPlanRow pt-0 pb-0 mt-0 pl-0 pr-0 mr-0">
          <Col
            md={3}
            lg={3}
            className="planIconBg d-flex align-items-center justify-content-center flex-wrap"
          >
            <div className="planDetails flex-column d-inline">
              <img
                src={item?.plan_logo}
                alt={item?.cc_subscription_title}
                width={'50%'}
              />
              <p
                className={`mt-3 ${
                  item?.cc_subscription_title === "Premium"
                    ? "premiumPara"
                    : "professionalPara"
                }`}
                style={{color : item?.color_code}}
              >
                {item?.cc_subscription_title}
              </p>
            </div>
          </Col>
          {/* <Col md={3} lg={3} className='planTypeTextDiv'>
                        <div className='mTop30'>
                            <p className='planType'>{item?.cc_subscription_title}</p>
                            <p className='planPrice'>${item?.selectedSubType === "annual"? item?.cc_subscription_annual_price: item?.cc_subscription_price}</p>

                        </div>
                    </Col> */}
          <Col md={9} lg={9} className="benefitsDiv pr-0 p-relative" style={{padding: '15px'}}>
            <div className="planDetailsPoint d-flex flex-wrap justify-content-center h-100 flex-column">
              <p className="mBot5 dataPlans" dataPlans>
                *{" "}
                {item?.cc_subscription_description ||
                  JSON.parse(sessionStorage.getItem("paymentObj"))
                    ?.cc_subscription_description}
              </p>
              {planContent?.map((planContentItem, idx) => {
                return (
                  <p className="mBot5 dataPlans" key={idx}>
                    {planContentItem}
                  </p>
                );
              })}
              {/* <p className="mBot5 dataPlans">
                * Multimedia capabilities (Audio, Images, Videos)
              </p>
              <p className="mBot5 dataPlans">* Customisable note-taking</p>
              <p className="mBot5 dataPlans">
                * Collaborative teaching via student and class sharing
              </p>
              <p className="mBot5 dataPlans">
                * Integrated and multifaceted gradebook
              </p> */}
            </div>
            <img
              src={
                item?.cc_subscription_title === "Personal" ||
                JSON.parse(sessionStorage.getItem("paymentObj"))
                  ?.cc_subscription_title === "Personal"
                  ? personalPath
                  : premiumPath
              }
              alt="bgPath"
              className="img-fluid"
              style={{ position: "absolute", right: "-2px", bottom: 0 }}
            />
          </Col>
        </Row>
      </div>
      {/* ------ Plan Details------ */}
      {showPaypal ? (
        <div>
          {/* ------ Pay Button------ */}
          {loader ? (
            <div>
              {" "}
              <DivLoader />{" "}
            </div>
          ) : (
            <>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 30 }}
              >
                <div className="stripePaymentDiv d-flex mr-2" style={{flexDirection : 'column'}}>
                  {/* <form
                    action="http://localhost:5000/create-checkout-session"
                    method="POST"
                  > */}
                  {/* <form
                    action="https://stripe-server-beta.vercel.app/newsubscription"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="subscription_id"
                      // value={sessionStorage.getItem("selectedSubId")}
                      value="sub_1OgRcOSBYQWkn28156Oygfyx"
                    />
                    <input
                      type="hidden"
                      name="subscription_item_id"
                      // value={sessionStorage.getItem("selectedSubItemId")}
                      value="si_PVSXlaDEO0namD"
                    />
                    <input
                      type="hidden"
                      name="customerId"
                      // value={sessionStorage.getItem("customerId")}
                      value="cus_PTWXn1mcp87nX0"
                    />
                    <input
                      type="hidden"
                      name="lookup_key"
                      value={
                        props?.item?.stripeObj?.id ||
                        JSON.parse(sessionStorage.getItem("paymentObj"))
                          ?.stripeObj?.id
                      }
                    /> */}
                    <Button
                    onClick={() => {stripeCheckoutHandler(props?.item?.stripeObj || JSON.parse(sessionStorage.getItem('paymentObj'))?.stripeObj)}}
                    >
                      Pay with <strong>Stripe</strong>
                    </Button>
                    <small style={{opacity:  "0.7", fontSize : '12px'}}><em><strong>(30-day trial)</strong></em></small>
                  {/* </form> */}
                </div>
                <div className="paypalButtonDiv d-block">
                  {/* <Button className="goBackButton" onClick={goBack}>
                Back
              </Button> */}
                  <PaypalBtn
                    env={PAYPALENV}
                    client={PAYPALCLIENT}
                    currency={CURRENCY}
                    total={Number(
                      item?.selectedSubType === "annual"
                        ? item?.cc_subscription_annual_price
                        : item?.cc_subscription_price
                    )}
                    locale={LOCALE}
                    style={style}
                    className="paypal-button-container"
                    onError={onError}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                  />
                  <small style={{opacity:  "0.7"}}><em><strong>(One-Time Payment)</strong></em></small>
                  {/* <div id="paypal-button-containers"></div> */}
                  {/* <PayPalScriptProvider
                    options={{
                      clientId:
                        "AfiAu4Pd1gaVDHaTYzKnAtkrQZMhfISX3eyWMwLed_AR61Smw7HLafngwvF_DnHbp3Rs-95zJOFpGX9h",
                      components: "buttons",
                      intent: "subscription",
                      vault: true,
                      disableFunding: 'card'
                      // disableFunding:'true'
                    }}
                  >
                    <ButtonWrapper type="subscription" />
                  </PayPalScriptProvider> */}
                </div>
              </div>
            </>
          )}

          {/* ------ Pay Button------ */}
        </div>
      ) : (
        <div>
          {" "}
          <DivLoader />{" "}
        </div>
      )}
    </div>
  );
};
PayPalPayment.propTypes = {
  setPaymentDataRequest: PropTypes.func,
  goBack: PropTypes.func,
};

export default PayPalPayment;
