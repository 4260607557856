// import React, { Component } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { verify, resendVerify } from 'Actions/AuthAction/VerifyAction';
import { login } from 'Actions/AuthAction/loginAction';
import { Button } from 'reactstrap';
import Logo from 'Assets/LoginModule/mainLogo.png';
import OtpInput from 'react-otp-input';
import * as routes from "Router/RoutesURL";
import { createNotification } from 'Config/notificationtoast';
import './VerificationPage.scss';

const VerificationPage = (props) => {
    const { history, resendVerify, verify, userStatusData, email, login } = props;
    const [VerificationCode, setVerificationCode] = useState('');
    const [Email, setEmail] = useState(history?.location?.state?.email);
    const [otp, setOtp] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    // useEffect(() => {
    //     setEmail(sessionStorage.getItem("RegisterEmail"))
    // }, [])

    useEffect(() => {
        // ---------VERIFY_SUCCESS-------
        if (userStatusData) {
            
            if (userStatusData?.verify_Data?.status === 200) {
                if (userStatusData?.verify_Data?.message === "OTP verified successfully.") {
                    const userEmail = sessionStorage.getItem("RegisterEmail")
                    const userPassword = sessionStorage.getItem("Password")
                    const keepMeSinged = sessionStorage.getItem("keepMeSinged")
                    const sendRequest = {
                        user_email_id: userEmail,
                        user_password: userPassword,
                        keepMeSinged: keepMeSinged
                    };

                    // login(sendRequest);
                    history.push(routes.USERSUBSCRIPTION)
                    // window.location.href = routes.USERSUBSCRIPTION;

                //    if(sessionStorage.getItem(IsLogin) === false){
                //         window.location.href = routes.ACADEMICDASHBOARD;
                //    } else {
                //     sessionStorage.setItem("FirstTimeLogin", 0)
                //     sessionStorage.setItem("IsLogin", false)
                //     window.location.href = routes.LOGIN;
                //    }
                }
            }
        }
        // ---------VERIFY_SUCCESS----------

        // ---------RESEND_VERIFY_SUCCESS-------
        if (userStatusData?.resendVerify_Data) {
            if (userStatusData?.action === 'RESEND_VERIFY_SUCCESS') {
                setVerificationCode('')
            }
        }
        // ---------RESEND_VERIFY_SUCCESS-------
    }, [userStatusData])

    // useEffect(() => {
    //     const isLogin = sessionStorage.getItem("IsLogin")
    //     const FTL = sessionStorage.getItem("FirstTimeLogin")
    //     if(isLogin === true && FTL === 0) {
    //         console.log("IsLogin",sessionStorage.getItem("IsLogin"))
    //         console.log("FTL----",sessionStorage.getItem("FirstTimeLogin"))
    //         window.location.href = routes.ACADEMICDASHBOARD;
    //     }
    //     console.log("FTL----")
    // }, [userStatusData])

    const verifyUser = () => {
        setIsSubmit(true)
        if (!Email) {
            createNotification('error', 'You should come with legal Process');
            history.push(routes.LOGIN);
            return false;
        }
        if (!VerificationCode) {
            setErrorMessage('*Please Enter Verification code')
            return false;
        }
        if (VerificationCode.length !== 5) {
            setErrorMessage('*Please Enter 5 digit code')
            return false;
        }
        const sendRequest = {
            user_email_id: Email,
            otp_no: VerificationCode,
        };
        verify(sendRequest);
    }
    // ---------verify User-------

    // };
    const handleChangeMain = (VerificationCode) => {
        setErrorMessage('');
        setIsSubmit(false);
        setVerificationCode(VerificationCode);
    };
    // ---------Input handleChangeMain-------


    // ---------resendVerify OTP-------
    const resendCode = () => {
        if (!Email) {
            createNotification('error', 'You should come with legal Process');
            sessionStorage.clear();
            window.location.href = routes.LOGIN;
            return false;
        }
        const sendRequest = {
            user_email_id: Email,
        };
        resendVerify(sendRequest)
    }
    // ---------resendVerify OTP-------

    return (
        <div className='forgotMainContainer'>

            {/*  ==========verifyCard==========*/}
            <div className='verifyCard'>

                {/*  ==========Logo==========*/}
                <div>
                    <img alt="" src={Logo} className="mainIcon" />
                </div>
                {/*  ==========Logo==========*/}


                {/*  ==========Proceed Text==========*/}
                <div className="proceedTextDivVerify">
                    <p className='proceedTextVerify'> Proceed with your</p>
                    <p className="verifyText" > Account Verification</p>
                </div>
                {/*  ==========Proceed Text==========*/}


                {/*  ==========Input Div OtpInput==========*/}
                <div className="inputLableDiv">
                    <p className="labelTextForgot">Verification Code</p>
                </div>

                <div className="inputTypeDivlForgot" data-testid='parentOtp'>
                    <OtpInput
                        // isInputNum={true}
                        onChange={handleChangeMain}
                        numInputs={5}
                        // containerStyle={true}
                        inputStyle='inputStyle'
                        // focusStyle={true}
                        value={VerificationCode}
                        separator={<div style={{ width: 15 }}><span>-</span></div>}
                        errorStyle="error"
                        shouldAutoFocus
                        data-testid='otpInput'
                    />
                </div>
                <div className="errorDiv">
                    <p className="errormessage">{ErrorMessage}</p>
                </div>

                <div className='mBot20' >
                    <div className="fRight" onClick={() => resendCode()}>
                        <span className="textResend"> Resend Code</span>
                    </div>
                </div>
                {/*  ==========Input Div OtpInput==========*/}

                {/*  ==========Button==========*/}
                <div className="verifyBtn">
                    <Button
                        onClick={() => verifyUser()}
                        color="primary" size="lg" block>Verify Account</Button>

                </div>
                {/*  ==========Button==========*/}

                {/*  ==========Change==========*/}

                <div className='mTop20' onClick={() => {
                    // history.replace(routes.LOGIN);
                    sessionStorage.clear();
                    window.location.href = routes.LOGIN;
                }}>
                    <p className="backText">Change account? <span className="backTextColour">Login</span></p>
                </div>
                {/*  ==========Change==========*/}

            </div>
            {/*  ==========verifyCard==========*/}
        </div>
    );
    // }

};
const mapStateToProps = state => ({
    state: state,
    userStatusData: state.verify,
});
const mapDispatchToProps = dispatch => {
    return {
        verify: data => dispatch(verify(data)),
        resendVerify: data => dispatch(resendVerify(data)),
        login: data => dispatch(login(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    VerificationPage
);



