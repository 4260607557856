import { call, takeLatest, put } from 'redux-saga/effects';
import UserGroupNoteReducer from 'Redux/Reducers/NoteReducer/groupNoteReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';

import {
    ADD_GROUP_NOTE,
    EDIT_GROUP_NOTE,
    GET_GROUP_NOTE,
    DELETE_GROUP_NOTE,
} from 'Actions/constants';

import {
    AddGroupNote_API,
    EditGroupNote_API,
    GetGroupNote_API,
    DeleteGroupNote_API,
    EditClassGroupNote_API
} from 'Config/Api';

import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';
import UserClassNoteReducer from "Redux/Reducers/NoteReducer/classNoteReducer";


export function* userGroupNoteModuleSaga() {
    yield takeLatest(ADD_GROUP_NOTE, addGroupNote);
    yield takeLatest(EDIT_GROUP_NOTE, editGroupNote);
    yield takeLatest(GET_GROUP_NOTE, getGroupNote);
    yield takeLatest(DELETE_GROUP_NOTE, deleteGroupNote);
}


// ---------- Add group Note-----
function* addGroupNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = AddGroupNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "attachment_details_array": action.data.attachment_details_array,
        "customized_tag_array": action.data.customized_tag_array,
        "customized_tag_list": `${action.data.customized_tag_list}`,
        "linked_flag": action.data.linked_flag,
        "note_reminder": action.data.note_reminder,
        "note_reminder_date": action.data.note_reminder_date,
        "note_student_level": action.data.note_student_level,
        "note_student_marked": action.data.note_student_marked,
        "note_student_score": action.data.note_student_score,
        "student_list": action.data.student_list,
        "work_assignment": action.data.work_assignment,
        "group_id": action?.data?.group_id
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserGroupNoteReducer.addGroupNoteSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
            action.funObje.history.goBack();

            yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
            if (MainDecode.status == 400 && MainDecode.data) {
                yield put(UserGroupNoteReducer.upgradePopUpOpen(MainDecode));
            } else if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(UserGroupNoteReducer.addGroupNoteFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(UserGroupNoteReducer.addGroupNoteFailure(false));

    }
}
// ---------- Add group Note-----

// ---------- Edit group Note-----
function* editGroupNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = EditClassGroupNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "attachment_details_array": action.data.attachment_details_array,
        "customized_tag_array": action.data.customized_tag_array,
        "customized_tag_list": action.data.customized_tag_list,
        "linked_flag": action.data.linked_flag,
        "note_reminder": action.data.note_reminder,
        "note_reminder_date": action.data.note_reminder_date,
        "note_student_level": action.data.note_student_level,
        "note_student_marked": action.data.note_student_marked,
        "note_student_score": action.data.note_student_score,
        "student_list": action.data.student_list,
        "work_assignment": action.data.work_assignment,
        "note_ids_array": action?.data?.note_ids_array,
        "platform": "web"
    }
    let data2 = {
        academicYearId: action?.data?.academicYearId,
        studentId: action?.data?.studentId,
        classId: action?.data?.class_id,
        groupId: action?.data?.group_id,
        pageNo: action?.data?.pageNo > 1 ? action?.data?.pageNo : 1,
        filter: action?.data?.filter,
        limit: action?.data?.limit,
        loader : false,
      };
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        let MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserGroupNoteReducer.editGroupNoteSuccess(MainDecode));
            let data = {
                academicYearId: action?.data?.academicYearId,
                studentId: action?.data?.studentId,
                classId: action?.data?.class_id,
                groupId: action?.data?.group_id,
                pageNo: action?.data?.pageNo > 1 ? action?.data?.pageNo : 1,
                filter: action?.data?.filter,
                limit: action?.data?.limit,
                loader : false,
              };
              yield put(UserClassNoteReducer.getClassGroupNotesData(data));
            action.funObje.history.goBack();
            // createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(UserGroupNoteReducer.editGroupNoteFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(UserGroupNoteReducer.editGroupNoteFailure(false));

    }
}
// ---------- Edit group Note-----

// ---------- Get group Note-----
function* getGroupNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = GetGroupNote_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "filter_by": action.data.filter_by,
        "page_no": action.data.page_no,
        "student_id": action.data.student_id,
        "class_id": action.data.class_id
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        let MainDecode = JSON.parse(atob(response));
        if (MainDecode.status === 'Success') {
            yield put(UserGroupNoteReducer.getGroupNoteSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserGroupNoteReducer.getGroupNoteFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserGroupNoteReducer.getGroupNoteFailure(false));

    }
}
// ---------- Get Student group Note-----


// ---------- Delete Student group Note-----
function* deleteGroupNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'));
    const URL = DeleteGroupNote_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "note_id": action.data.note_id,

    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        let MainDecode = JSON.parse(atob(response));
        if (MainDecode.status === 'Success') {

            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "student_id": action.data.student_id,
                "class_id": action.data.class_id
            }
            yield put(UserGroupNoteReducer.getGroupNote(data));
            yield put(UserGroupNoteReducer.deleteGroupNoteSuccess(MainDecode));
            // createNotification('success', MainDecode.message);


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserGroupNoteReducer.deleteGroupNoteFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserGroupNoteReducer.deleteGroupNoteFailure(false));

    }
}
// ---------- Delete Student group Note-----